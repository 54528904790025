define("ember-intl/-private/formatters/-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
  // eslint-disable-next-line @typescript-eslint/ban-types
  const EMPTY_OBJECT = Object.create(null);
  /**
   * @private
   * @hide
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  class FormatterBase {
    constructor(config) {
      _defineProperty(this, "config", void 0);
      _defineProperty(this, "readFormatConfig", void 0);
      this.config = config;

      // NOTE: a fn since we lazily grab the formatter from the config
      // as it can change at runtime by calling intl.set('formats', {...});
      this.readFormatConfig = config.readFormatConfig;
    }
    get options() {
      return [];
    }

    /**
     * Filters out all of the whitelisted formatter options
     *
     * @method filterKnownOptions
     * @param {Object} Options object
     * @return {Object} Options object containing just whitelisted options
     * @private
     */
    filterKnownOptions(options) {
      if (!options) {
        return EMPTY_OBJECT;
      }
      const found = {};
      for (const key in options) {
        if (this.options.includes(key)) {
          found[key] = options[key];
        }
      }
      return found;
    }
    readOptions(formatOptions) {
      let formatterOptions = this.filterKnownOptions(formatOptions);
      if (formatOptions && 'format' in formatOptions) {
        const namedFormatsOptions = this.getNamedFormat(formatOptions.format);
        formatterOptions = {
          ...namedFormatsOptions,
          ...formatterOptions
        };
      }
      return formatterOptions;
    }
    validateFormatterOptions(locale, _formatterOptions) {
      if (!locale) {
        // TODO: config.onError instead?
        (true && Ember.warn(`[ember-intl] no locale has been set!  See: https://ember-intl.github.io/ember-intl/docs/quickstart#4-configure-ember-intl`, false, {
          id: 'ember-intl-no-locale-set'
        }));
      }
    }
    getNamedFormat(key) {
      const formats = this.readFormatConfig();
      const namedFormatsForType = formats[this.constructor.type];
      if (namedFormatsForType && namedFormatsForType[key]) {
        return namedFormatsForType[key];
      }
    }
  }
  _exports.default = FormatterBase;
  _defineProperty(FormatterBase, "type", void 0);
});