define("ember-sortable/components/sortable-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This component represents the handle of a `sortable-item`.
   */
  var _default = _exports.default = Ember.Component.extend({
    tabindex: 0,
    role: 'button',
    attributeBindings: ["dataSortableHandle:data-sortable-handle", "tabindex", "role"],
    dataSortableHandle: true,
    init() {
      this._super(...arguments);
      (true && !(false) && Ember.deprecate('The <SortableHandle> component is deprecated.  Please use the modifier version {{sortable-handle}}.', false, {
        id: 'ember-sortable:sortable-handle-component-deprecated',
        until: '3.0.0',
        for: 'ember-sortable',
        since: {
          available: '2.2.6',
          enabled: '2.2.6'
        }
      }));
    }
  });
});