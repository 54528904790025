define("@ember/-internals/glimmer/index", ["exports", "@ember/polyfills", "@glimmer/opcode-compiler", "@ember/-internals/metal", "@ember/-internals/owner", "@ember/-internals/runtime", "@ember/-internals/utils", "@ember/-internals/views", "@ember/debug", "@glimmer/reference", "@glimmer/runtime", "@glimmer/validator", "@ember/-internals/browser-environment", "@ember/instrumentation", "@ember/service", "@ember/runloop", "@ember/-internals/environment", "@glimmer/util", "@ember/deprecated-features", "@ember/string", "@ember/-internals/container", "@glimmer/node", "@ember/-internals/routing", "@ember/component/template-only", "@ember/error", "rsvp"], function (_exports, _polyfills, _opcodeCompiler, _metal, _owner, _runtime, _utils, _views, _debug, _reference, _runtime2, _validator, _browserEnvironment, _instrumentation, _service, _runloop, _environment2, _util, _deprecatedFeatures, _string, _container, _node, _routing, _templateOnly, _error, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = template;
  _exports.helper = helper;
  _exports.escapeExpression = escapeExpression;
  _exports.htmlSafe = htmlSafe;
  _exports.isHTMLSafe = isHTMLSafe;
  _exports._resetRenderers = _resetRenderers;
  _exports.renderSettled = renderSettled;
  _exports.getTemplate = getTemplate;
  _exports.setTemplate = setTemplate;
  _exports.hasTemplate = hasTemplate;
  _exports.getTemplates = getTemplates;
  _exports.setTemplates = setTemplates;
  _exports.setupEngineRegistry = setupEngineRegistry;
  _exports.setupApplicationRegistry = setupApplicationRegistry;
  _exports._registerMacros = registerMacros;
  _exports.capabilities = capabilities;
  _exports.setComponentManager = setComponentManager;
  _exports.getComponentManager = getComponentManager;
  _exports.setModifierManager = setModifierManager;
  _exports.getModifierManager = getModifierManager;
  _exports.modifierCapabilities = capabilities$1;
  _exports.setComponentTemplate = setComponentTemplate;
  _exports.getComponentTemplate = getComponentTemplate;
  Object.defineProperty(_exports, "DOMChanges", {
    enumerable: true,
    get: function () {
      return _runtime2.DOMChanges;
    }
  });
  Object.defineProperty(_exports, "DOMTreeConstruction", {
    enumerable: true,
    get: function () {
      return _runtime2.DOMTreeConstruction;
    }
  });
  Object.defineProperty(_exports, "isSerializationFirstNode", {
    enumerable: true,
    get: function () {
      return _runtime2.isSerializationFirstNode;
    }
  });
  Object.defineProperty(_exports, "NodeDOMTreeConstruction", {
    enumerable: true,
    get: function () {
      return _node.NodeDOMTreeConstruction;
    }
  });
  _exports.OutletView = _exports.INVOKE = _exports.AbstractComponentManager = _exports._experimentalMacros = _exports.InteractiveRenderer = _exports.InertRenderer = _exports.Renderer = _exports.SafeString = _exports.Helper = _exports.Component = _exports.LinkComponent = _exports.TextArea = _exports.TextField = _exports.Checkbox = _exports.templateCacheCounters = _exports.RootTemplate = void 0;

  function isTemplateFactory(template) {
    return typeof template === 'function';
  }

  var counters = {
    cacheHit: 0,
    cacheMiss: 0
  };
  _exports.templateCacheCounters = counters;

  function template(json) {
    var glimmerFactory = (0, _opcodeCompiler.templateFactory)(json);
    var cache = new WeakMap();
    var meta = glimmerFactory.meta;

    var factory = owner => {
      var result = cache.get(owner);

      if (result === undefined) {
        counters.cacheMiss++;
        result = glimmerFactory.create((0, _polyfills.assign)({
          owner
        }, meta));
        cache.set(owner, result);
      } else {
        counters.cacheHit++;
      }

      return result;
    };

    factory.__id = glimmerFactory.id;
    factory.__meta = meta;
    return factory;
  }

  var RootTemplate = template({
    "id": "s5o9bxSn",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0]],null]]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/root.hbs"
    }
  });
  _exports.RootTemplate = RootTemplate;
  var ARGS = (0, _utils.enumerableSymbol)('ARGS');
  var HAS_BLOCK = (0, _utils.enumerableSymbol)('HAS_BLOCK');
  var DIRTY_TAG = (0, _utils.symbol)('DIRTY_TAG');
  var IS_DISPATCHING_ATTRS = (0, _utils.symbol)('IS_DISPATCHING_ATTRS');
  var BOUNDS = (0, _utils.symbol)('BOUNDS');
  /**
  @module @ember/component
  */

  /**
    A component is an isolated piece of UI, represented by a template and an
    optional class. When a component has a class, its template's `this` value
    is an instance of the component class.
  
    ## Template-only Components
  
    The simplest way to create a component is to create a template file in
    `app/templates/components`. For example, if you name a template
    `app/templates/components/person-profile.hbs`:
  
    ```app/templates/components/person-profile.hbs
    <h1>{{@person.name}}</h1>
    <img src={{@person.avatar}}>
    <p class='signature'>{{@person.signature}}</p>
    ```
  
    You will be able to use `<PersonProfile />` to invoke this component elsewhere
    in your application:
  
    ```app/templates/application.hbs
    <PersonProfile @person={{this.currentUser}} />
    ```
  
    Note that component names are capitalized here in order to distinguish them
    from regular HTML elements, but they are dasherized in the file system.
  
    While the angle bracket invocation form is generally preferred, it is also
    possible to invoke the same component with the `{{person-profile}}` syntax:
  
    ```app/templates/application.hbs
    {{person-profile person=this.currentUser}}
    ```
  
    Note that with this syntax, you use dashes in the component name and
    arguments are passed without the `@` sign.
  
    In both cases, Ember will render the content of the component template we
    created above. The end result will be something like this:
  
    ```html
    <h1>Tomster</h1>
    <img src="https://emberjs.com/tomster.jpg">
    <p class='signature'>Out of office this week</p>
    ```
  
    ## File System Nesting
  
    Components can be nested inside sub-folders for logical groupping. For
    example, if we placed our template in
    `app/templates/components/person/short-profile.hbs`, we can invoke it as
    `<Person::ShortProfile />`:
  
    ```app/templates/application.hbs
    <Person::ShortProfile @person={{this.currentUser}} />
    ```
  
    Or equivalently, `{{person/short-profile}}`:
  
    ```app/templates/application.hbs
    {{person/short-profile person=this.currentUser}}
    ```
  
    ## Yielding Contents
  
    You can use `yield` inside a template to include the **contents** of any block
    attached to the component. The block will be executed in its original context:
  
    ```handlebars
    <PersonProfile @person={{this.currentUser}}>
      <p>Admin mode</p>
      {{! Executed in the current context. }}
    </PersonProfile>
    ```
  
    or
  
    ```handlebars
    {{#person-profile person=this.currentUser}}
      <p>Admin mode</p>
      {{! Executed in the current context. }}
    {{/person-profile}}
    ```
  
    ```app/templates/components/person-profile.hbs
    <h1>{{@person.name}}</h1>
    {{yield}}
    ```
  
    ## Customizing Components With JavaScript
  
    If you want to customize the component in order to handle events, transform
    arguments or maintain internal state, you implement a subclass of `Component`.
  
    One example is to add computed properties to your component:
  
    ```app/components/person-profile.js
    import Component from '@ember/component';
  
    export default Component.extend({
      displayName: computed('person.title', 'person.firstName', 'person.lastName', function() {
        let { title, firstName, lastName } = this;
  
        if (title) {
          return `${title} ${lastName}`;
        } else {
          return `${firstName} ${lastName}`;
        }
      })
    });
    ```
  
    And then use it in the component's template:
  
    ```app/templates/components/person-profile.hbs
    <h1>{{this.displayName}}</h1>
    {{yield}}
    ```
  
    ## Customizing a Component's HTML Element in JavaScript
  
    ### HTML Tag
  
    The default HTML tag name used for a component's HTML representation is `div`.
    This can be customized by setting the `tagName` property.
  
    Consider the following component class:
  
    ```app/components/emphasized-paragraph.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'em'
    });
    ```
  
    When invoked, this component would produce output that looks something like
    this:
  
    ```html
    <em id="ember1" class="ember-view"></em>
    ```
  
    ### HTML `class` Attribute
  
    The HTML `class` attribute of a component's tag can be set by providing a
    `classNames` property that is set to an array of strings:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      classNames: ['my-class', 'my-other-class']
    });
    ```
  
    Invoking this component will produce output that looks like this:
  
    ```html
    <div id="ember1" class="ember-view my-class my-other-class"></div>
    ```
  
    `class` attribute values can also be set by providing a `classNameBindings`
    property set to an array of properties names for the component. The return
    value of these properties will be added as part of the value for the
    components's `class` attribute. These properties can be computed properties:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
    import { computed } from '@ember/object';
  
    export default Component.extend({
      classNames: ['my-class', 'my-other-class'],
      classNameBindings: ['propertyA', 'propertyB'],
  
      propertyA: 'from-a',
      propertyB: computed(function() {
        if (someLogic) { return 'from-b'; }
      })
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view my-class my-other-class from-a from-b"></div>
    ```
  
    Note that `classNames` and `classNameBindings` is in addition to the `class`
    attribute passed with the angle bracket invocation syntax. Therefore, if this
    component was invoked like so:
  
    ```handlebars
    <MyWidget class="from-invocation" />
    ```
  
    The resulting HTML will look similar to this:
  
    ```html
    <div id="ember1" class="from-invocation ember-view my-class my-other-class from-a from-b"></div>
    ```
  
    If the value of a class name binding returns a boolean the property name
    itself will be used as the class name if the property is true. The class name
    will not be added if the value is `false` or `undefined`.
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      classNameBindings: ['hovered'],
  
      hovered: true
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view hovered"></div>
    ```
  
    ### Custom Class Names for Boolean Values
  
    When using boolean class name bindings you can supply a string value other
    than the property name for use as the `class` HTML attribute by appending the
    preferred value after a ":" character when defining the binding:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      classNameBindings: ['awesome:so-very-cool'],
  
      awesome: true
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view so-very-cool"></div>
    ```
  
    Boolean value class name bindings whose property names are in a
    camelCase-style format will be converted to a dasherized format:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      classNameBindings: ['isUrgent'],
  
      isUrgent: true
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view is-urgent"></div>
    ```
  
    Class name bindings can also refer to object values that are found by
    traversing a path relative to the component itself:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
    import EmberObject from '@ember/object';
  
    export default Component.extend({
      classNameBindings: ['messages.empty'],
  
      messages: EmberObject.create({
        empty: true
      })
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view empty"></div>
    ```
  
    If you want to add a class name for a property which evaluates to true and
    and a different class name if it evaluates to false, you can pass a binding
    like this:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      classNameBindings: ['isEnabled:enabled:disabled'],
      isEnabled: true
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view enabled"></div>
    ```
  
    When isEnabled is `false`, the resulting HTML representation looks like this:
  
    ```html
    <div id="ember1" class="ember-view disabled"></div>
    ```
  
    This syntax offers the convenience to add a class if a property is `false`:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    // Applies no class when isEnabled is true and class 'disabled' when isEnabled is false
    export default Component.extend({
      classNameBindings: ['isEnabled::disabled'],
      isEnabled: true
    });
    ```
  
    Invoking this component when the `isEnabled` property is true will produce
    HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view"></div>
    ```
  
    Invoking it when the `isEnabled` property on the component is `false` will
    produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view disabled"></div>
    ```
  
    Updates to the value of a class name binding will result in automatic update
    of the  HTML `class` attribute in the component's rendered HTML
    representation. If the value becomes `false` or `undefined` the class name
    will be removed.
  
    Both `classNames` and `classNameBindings` are concatenated properties. See
    [EmberObject](/ember/release/classes/EmberObject) documentation for more
    information about concatenated properties.
  
    ### Other HTML Attributes
  
    The HTML attribute section of a component's tag can be set by providing an
    `attributeBindings` property set to an array of property names on the component.
    The return value of these properties will be used as the value of the component's
    HTML associated attribute:
  
    ```app/components/my-anchor.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'a',
      attributeBindings: ['href'],
  
      href: 'http://google.com'
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <a id="ember1" class="ember-view" href="http://google.com"></a>
    ```
  
    One property can be mapped on to another by placing a ":" between
    the source property and the destination property:
  
    ```app/components/my-anchor.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'a',
      attributeBindings: ['url:href'],
  
      url: 'http://google.com'
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <a id="ember1" class="ember-view" href="http://google.com"></a>
    ```
  
    HTML attributes passed with angle bracket invocations will take precedence
    over those specified in `attributeBindings`. Therefore, if this component was
    invoked like so:
  
    ```handlebars
    <MyAnchor href="http://bing.com" @url="http://google.com" />
    ```
  
    The resulting HTML will looks like this:
  
    ```html
    <a id="ember1" class="ember-view" href="http://bing.com"></a>
    ```
  
    Note that the `href` attribute is ultimately set to `http://bing.com`,
    despite it having attribute binidng to the `url` property, which was
    set to `http://google.com`.
  
    Namespaced attributes (e.g. `xlink:href`) are supported, but have to be
    mapped, since `:` is not a valid character for properties in Javascript:
  
    ```app/components/my-use.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'use',
      attributeBindings: ['xlinkHref:xlink:href'],
  
      xlinkHref: '#triangle'
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <use xlink:href="#triangle"></use>
    ```
  
    If the value of a property monitored by `attributeBindings` is a boolean, the
    attribute will be present or absent depending on the value:
  
    ```app/components/my-text-input.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'input',
      attributeBindings: ['disabled'],
  
      disabled: false
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <input id="ember1" class="ember-view" />
    ```
  
    `attributeBindings` can refer to computed properties:
  
    ```app/components/my-text-input.js
    import Component from '@ember/component';
    import { computed } from '@ember/object';
  
    export default Component.extend({
      tagName: 'input',
      attributeBindings: ['disabled'],
  
      disabled: computed(function() {
        if (someLogic) {
          return true;
        } else {
          return false;
        }
      })
    });
    ```
  
    To prevent setting an attribute altogether, use `null` or `undefined` as the
    value of the property used in `attributeBindings`:
  
    ```app/components/my-text-input.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'form',
      attributeBindings: ['novalidate'],
      novalidate: null
    });
    ```
  
    Updates to the property of an attribute binding will result in automatic
    update of the  HTML attribute in the component's HTML output.
  
    `attributeBindings` is a concatenated property. See
    [EmberObject](/ember/release/classes/EmberObject) documentation for more
    information about concatenated properties.
  
    ## Layouts
  
    The `layout` property can be used to dynamically specify a template associated
    with a component class, instead of relying on Ember to link together a
    component class and a template based on file names.
  
    In general, applications should not use this feature, but it's commonly used
    in addons for historical reasons.
  
    The `layout` property should be set to the default export of a template
    module, which is the name of a template file without the `.hbs` extension.
  
    ```app/templates/components/person-profile.hbs
    <h1>Person's Title</h1>
    <div class='details'>{{yield}}</div>
    ```
  
    ```app/components/person-profile.js
      import Component from '@ember/component';
      import layout from '../templates/components/person-profile';
  
      export default Component.extend({
        layout
      });
    ```
  
    If you invoke the component:
  
    ```handlebars
    <PersonProfile>
      <h2>Chief Basket Weaver</h2>
      <h3>Fisherman Industries</h3>
    </PersonProfile>
    ```
  
    or
  
    ```handlebars
    {{#person-profile}}
      <h2>Chief Basket Weaver</h2>
      <h3>Fisherman Industries</h3>
    {{/person-profile}}
    ```
  
    It will result in the following HTML output:
  
    ```html
    <h1>Person's Title</h1>
      <div class="details">
      <h2>Chief Basket Weaver</h2>
      <h3>Fisherman Industries</h3>
    </div>
    ```
  
    ## Handling Browser Events
  
    Components can respond to user-initiated events in one of three ways: passing
    actions with angle bracket invocation, adding event handler methods to the
    component's class, or adding actions to the component's template.
  
    ### Passing Actions With Angle Bracket Invocation
  
    For one-off events specific to particular instance of a component, it is possible
    to pass actions to the component's element using angle bracket invocation syntax.
  
    ```handlebars
    <MyWidget {{action 'firstWidgetClicked'}} />
  
    <MyWidget {{action 'secondWidgetClicked'}} />
    ```
  
    In this case, when the first component is clicked on, Ember will invoke the
    `firstWidgetClicked` action. When the second component is clicked on, Ember
    will invoke the `secondWidgetClicked` action instead.
  
    Besides `{{action}}`, it is also possible to pass any arbitrary element modifiers
    using the angle bracket invocation syntax.
  
    ### Event Handler Methods
  
    Components can also respond to user-initiated events by implementing a method
    that matches the event name. This approach is appropriate when the same event
    should be handled by all instances of the same component.
  
    An event object will be passed as the argument to the event handler method.
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      click(event) {
        // `event.target` is either the component's element or one of its children
        let tag = event.target.tagName.toLowerCase();
        console.log('clicked on a `<${tag}>` HTML element!');
      }
    });
    ```
  
    In this example, whenever the user clicked anywhere inside the component, it
    will log a message to the console.
  
    It is possible to handle event types other than `click` by implementing the
    following event handler methods. In addition, custom events can be registered
    by using `Application.customEvents`.
  
    Touch events:
  
    * `touchStart`
    * `touchMove`
    * `touchEnd`
    * `touchCancel`
  
    Keyboard events:
  
    * `keyDown`
    * `keyUp`
    * `keyPress`
  
    Mouse events:
  
    * `mouseDown`
    * `mouseUp`
    * `contextMenu`
    * `click`
    * `doubleClick`
    * `focusIn`
    * `focusOut`
  
    Form events:
  
    * `submit`
    * `change`
    * `focusIn`
    * `focusOut`
    * `input`
  
    Drag and drop events:
  
    * `dragStart`
    * `drag`
    * `dragEnter`
    * `dragLeave`
    * `dragOver`
    * `dragEnd`
    * `drop`
  
    ### `{{action}}` Helper
  
    Instead of handling all events of a particular type anywhere inside the
    component's element, you may instead want to limit it to a particular
    element in the component's template. In this case, it would be more
    convenient to implement an action instead.
  
    For example, you could implement the action `hello` for the `person-profile`
    component:
  
    ```app/components/person-profile.js
    import Component from '@ember/component';
  
    export default Component.extend({
      actions: {
        hello(name) {
          console.log("Hello", name);
        }
      }
    });
    ```
  
    And then use it in the component's template:
  
    ```app/templates/components/person-profile.hbs
    <h1>{{@person.name}}</h1>
  
    <button {{action 'hello' @person.name}}>
      Say Hello to {{@person.name}}
    </button>
    ```
  
    When the user clicks the button, Ember will invoke the `hello` action,
    passing in the current value of `@person.name` as an argument.
  
    See [Ember.Templates.helpers.action](/ember/release/classes/Ember.Templates.helpers/methods/action?anchor=action).
  
    @class Component
    @extends Ember.CoreView
    @uses Ember.TargetActionSupport
    @uses Ember.ClassNamesSupport
    @uses Ember.ActionSupport
    @uses Ember.ViewMixin
    @uses Ember.ViewStateSupport
    @public
  */

  var Component = _views.CoreView.extend(_views.ChildViewsSupport, _views.ViewStateSupport, _views.ClassNamesSupport, _runtime.TargetActionSupport, _views.ActionSupport, _views.ViewMixin, {
    isComponent: true,

    init() {
      this._super(...arguments);

      this[IS_DISPATCHING_ATTRS] = false;
      this[DIRTY_TAG] = (0, _validator.createTag)();
      this[BOUNDS] = null;

      if (true
      /* DEBUG */
      && this.renderer._destinedForDOM && this.tagName === '') {
        var eventNames = [];
        var eventDispatcher = (0, _owner.getOwner)(this).lookup('event_dispatcher:main');
        var events = eventDispatcher && eventDispatcher._finalEvents || {}; // tslint:disable-next-line:forin

        for (var key in events) {
          var methodName = events[key];

          if (typeof this[methodName] === 'function') {
            eventNames.push(methodName);
          }
        } // If in a tagless component, assert that no event handlers are defined


        (true && !(!eventNames.length) && (0, _debug.assert)( // tslint:disable-next-line:max-line-length
        `You can not define \`${eventNames}\` function(s) to handle DOM event in the \`${this}\` tagless component since it doesn't have any DOM element.`, !eventNames.length));
      }

      (true && !(this.mouseEnter === undefined) && (0, _debug.deprecate)(`${this}: Using \`mouseEnter\` event handler methods in components has been deprecated.`, this.mouseEnter === undefined, {
        id: 'ember-views.event-dispatcher.mouseenter-leave-move',
        until: '4.0.0',
        url: 'https://emberjs.com/deprecations/v3.x#toc_component-mouseenter-leave-move'
      }));
      (true && !(this.mouseLeave === undefined) && (0, _debug.deprecate)(`${this}: Using \`mouseLeave\` event handler methods in components has been deprecated.`, this.mouseLeave === undefined, {
        id: 'ember-views.event-dispatcher.mouseenter-leave-move',
        until: '4.0.0',
        url: 'https://emberjs.com/deprecations/v3.x#toc_component-mouseenter-leave-move'
      }));
      (true && !(this.mouseMove === undefined) && (0, _debug.deprecate)(`${this}: Using \`mouseMove\` event handler methods in components has been deprecated.`, this.mouseMove === undefined, {
        id: 'ember-views.event-dispatcher.mouseenter-leave-move',
        until: '4.0.0',
        url: 'https://emberjs.com/deprecations/v3.x#toc_component-mouseenter-leave-move'
      }));
    },

    rerender() {
      (0, _validator.dirtyTag)(this[DIRTY_TAG]);

      this._super();
    },

    [_metal.PROPERTY_DID_CHANGE](key, value) {
      if (this[IS_DISPATCHING_ATTRS]) {
        return;
      }

      var args = this[ARGS];
      var reference = args !== undefined ? args[key] : undefined;

      if (reference !== undefined && reference[_reference.UPDATE_REFERENCED_VALUE] !== undefined) {
        reference[_reference.UPDATE_REFERENCED_VALUE](arguments.length === 2 ? value : (0, _metal.get)(this, key));
      }
    },

    getAttr(key) {
      // TODO Intimate API should be deprecated
      return this.get(key);
    },

    /**
      Normally, Ember's component model is "write-only". The component takes a
      bunch of attributes that it got passed in, and uses them to render its
      template.
       One nice thing about this model is that if you try to set a value to the
      same thing as last time, Ember (through HTMLBars) will avoid doing any
      work on the DOM.
       This is not just a performance optimization. If an attribute has not
      changed, it is important not to clobber the element's "hidden state".
      For example, if you set an input's `value` to the same value as before,
      it will clobber selection state and cursor position. In other words,
      setting an attribute is not **always** idempotent.
       This method provides a way to read an element's attribute and also
      update the last value Ember knows about at the same time. This makes
      setting an attribute idempotent.
       In particular, what this means is that if you get an `<input>` element's
      `value` attribute and then re-render the template with the same value,
      it will avoid clobbering the cursor and selection position.
      Since most attribute sets are idempotent in the browser, you typically
      can get away with reading attributes using jQuery, but the most reliable
      way to do so is through this method.
      @method readDOMAttr
       @param {String} name the name of the attribute
      @return String
      @public
     */
    readDOMAttr(name) {
      // TODO revisit this
      var _element = (0, _views.getViewElement)(this);

      (true && !(_element !== null) && (0, _debug.assert)(`Cannot call \`readDOMAttr\` on ${this} which does not have an element`, _element !== null));
      var element = _element;
      var isSVG = element.namespaceURI === "http://www.w3.org/2000/svg"
      /* SVG */
      ;
      var {
        type,
        normalized
      } = (0, _runtime2.normalizeProperty)(element, name);

      if (isSVG || type === 'attr') {
        return element.getAttribute(normalized);
      }

      return element[normalized];
    },

    /**
     The WAI-ARIA role of the control represented by this view. For example, a
     button may have a role of type 'button', or a pane may have a role of
     type 'alertdialog'. This property is used by assistive software to help
     visually challenged users navigate rich web applications.
      The full list of valid WAI-ARIA roles is available at:
     [https://www.w3.org/TR/wai-aria/#roles_categorization](https://www.w3.org/TR/wai-aria/#roles_categorization)
      @property ariaRole
     @type String
     @default null
     @public
     */

    /**
     Enables components to take a list of parameters as arguments.
     For example, a component that takes two parameters with the names
     `name` and `age`:
      ```app/components/my-component.js
     import Component from '@ember/component';
      let MyComponent = Component.extend();
      MyComponent.reopenClass({
       positionalParams: ['name', 'age']
     });
      export default MyComponent;
     ```
      It can then be invoked like this:
      ```hbs
     {{my-component "John" 38}}
     ```
      The parameters can be referred to just like named parameters:
      ```hbs
     Name: {{name}}, Age: {{age}}.
     ```
      Using a string instead of an array allows for an arbitrary number of
     parameters:
      ```app/components/my-component.js
     import Component from '@ember/component';
      let MyComponent = Component.extend();
      MyComponent.reopenClass({
       positionalParams: 'names'
     });
      export default MyComponent;
     ```
      It can then be invoked like this:
      ```hbs
     {{my-component "John" "Michael" "Scott"}}
     ```
     The parameters can then be referred to by enumerating over the list:
      ```hbs
     {{#each names as |name|}}{{name}}{{/each}}
     ```
      @static
     @public
     @property positionalParams
     @since 1.13.0
     */

    /**
     Called when the attributes passed into the component have been updated.
     Called both during the initial render of a container and during a rerender.
     Can be used in place of an observer; code placed here will be executed
     every time any attribute updates.
     @method didReceiveAttrs
     @public
     @since 1.13.0
     */
    didReceiveAttrs() {},

    /**
     Called when the attributes passed into the component have been updated.
     Called both during the initial render of a container and during a rerender.
     Can be used in place of an observer; code placed here will be executed
     every time any attribute updates.
     @event didReceiveAttrs
     @public
     @since 1.13.0
     */

    /**
     Called after a component has been rendered, both on initial render and
     in subsequent rerenders.
     @method didRender
     @public
     @since 1.13.0
     */
    didRender() {},

    /**
     Called after a component has been rendered, both on initial render and
     in subsequent rerenders.
     @event didRender
     @public
     @since 1.13.0
     */

    /**
     Called before a component has been rendered, both on initial render and
     in subsequent rerenders.
     @method willRender
     @public
     @since 1.13.0
     */
    willRender() {},

    /**
     Called before a component has been rendered, both on initial render and
     in subsequent rerenders.
     @event willRender
     @public
     @since 1.13.0
     */

    /**
     Called when the attributes passed into the component have been changed.
     Called only during a rerender, not during an initial render.
     @method didUpdateAttrs
     @public
     @since 1.13.0
     */
    didUpdateAttrs() {},

    /**
     Called when the attributes passed into the component have been changed.
     Called only during a rerender, not during an initial render.
     @event didUpdateAttrs
     @public
     @since 1.13.0
     */

    /**
     Called when the component is about to update and rerender itself.
     Called only during a rerender, not during an initial render.
     @method willUpdate
     @public
     @since 1.13.0
     */
    willUpdate() {},

    /**
     Called when the component is about to update and rerender itself.
     Called only during a rerender, not during an initial render.
     @event willUpdate
     @public
     @since 1.13.0
     */

    /**
     Called when the component has updated and rerendered itself.
     Called only during a rerender, not during an initial render.
     @method didUpdate
     @public
     @since 1.13.0
     */
    didUpdate() {}

  });

  _exports.Component = Component;

  Component.toString = () => '@ember/component';

  Component.reopenClass({
    isComponentFactory: true,
    positionalParams: []
  });
  var layout = template({
    "id": "SWbqsLhV",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/empty.hbs"
    }
  });
  /**
  @module @ember/component
  */

  /**
    The internal class used to create text inputs when the `{{input}}`
    helper is used with `type` of `checkbox`.
  
    See [Ember.Templates.helpers.input](/ember/release/classes/Ember.Templates.helpers/methods/input?anchor=input)  for usage details.
  
    ## Direct manipulation of `checked`
  
    The `checked` attribute of an `Checkbox` object should always be set
    through the Ember object or by interacting with its rendered element
    representation via the mouse, keyboard, or touch. Updating the value of the
    checkbox via jQuery will result in the checked value of the object and its
    element losing synchronization.
  
    ## Layout and LayoutName properties
  
    Because HTML `input` elements are self closing `layout` and `layoutName`
    properties will not be applied.
  
    @class Checkbox
    @extends Component
    @public
  */

  var Checkbox = Component.extend({
    layout,

    /**
      By default, this component will add the `ember-checkbox` class to the component's element.
         @property classNames
      @type Array | String
      @default ['ember-checkbox']
      @public
     */
    classNames: ['ember-checkbox'],
    tagName: 'input',

    /**
      By default this component will forward a number of arguments to attributes on the the
      component's element:
         * indeterminate
      * disabled
      * tabindex
      * name
      * autofocus
      * required
      * form
         When invoked with curly braces, this is the exhaustive list of HTML attributes you can
      customize (i.e. `{{input type="checkbox" disabled=true}}`).
         When invoked with angle bracket invocation, this list is irrelevant, because you can use HTML
      attribute syntax to customize the element (i.e.
      `<Input @type="checkbox" disabled data-custom="custom value" />`). However, `@type` and
      `@checked` must be passed as named arguments, not attributes.
         @property attributeBindings
      @type Array | String
      @default ['type', 'checked', 'indeterminate', 'disabled', 'tabindex', 'name', 'autofocus', 'required', 'form']
      @public
    */
    attributeBindings: ['type', 'checked', 'indeterminate', 'disabled', 'tabindex', 'name', 'autofocus', 'required', 'form'],

    /**
      Sets the `type` attribute of the `Checkbox`'s element
         @property disabled
      @default false
      @private
     */
    type: 'checkbox',

    /**
      Sets the `disabled` attribute of the `Checkbox`'s element
         @property disabled
      @default false
      @public
     */
    disabled: false,

    /**
      Corresponds to the `indeterminate` property of the `Checkbox`'s element
         @property disabled
      @default false
      @public
     */
    indeterminate: false,

    /**
      Whenever the checkbox is inserted into the DOM, perform initialization steps, which include
      setting the indeterminate property if needed.
         If this method is overridden, `super` must be called.
         @method
      @public
     */
    didInsertElement() {
      this._super(...arguments);

      this.element.indeterminate = Boolean(this.indeterminate);
    },

    /**
      Whenever the `change` event is fired on the checkbox, update its `checked` property to reflect
      whether the checkbox is checked.
         If this method is overridden, `super` must be called.
         @method
      @public
     */
    change() {
      (0, _metal.set)(this, 'checked', this.element.checked);
    }

  });
  _exports.Checkbox = Checkbox;

  if (true
  /* DEBUG */
  ) {
    var UNSET = {};
    Checkbox.reopen({
      value: UNSET,

      didReceiveAttrs() {
        this._super();

        (true && !(!(this.type === 'checkbox' && this.value !== UNSET)) && (0, _debug.assert)("`<Input @type='checkbox' @value={{...}} />` is not supported; " + "please use `<Input @type='checkbox' @checked={{...}} />` instead.", !(this.type === 'checkbox' && this.value !== UNSET)));
      }

    });
  }

  Checkbox.toString = () => '@ember/component/checkbox';
  /**
  @module @ember/component
  */


  var inputTypes = _browserEnvironment.hasDOM ? Object.create(null) : null;

  function canSetTypeOfInput(type) {
    // if running in outside of a browser always return
    // the original type
    if (!_browserEnvironment.hasDOM) {
      return Boolean(type);
    }

    if (type in inputTypes) {
      return inputTypes[type];
    }

    var inputTypeTestElement = document.createElement('input');

    try {
      inputTypeTestElement.type = type;
    } catch (e) {// ignored
    }

    return inputTypes[type] = inputTypeTestElement.type === type;
  }
  /**
    The internal class used to create text inputs when the `Input` component is used with `type` of `text`.
  
    See [Ember.Templates.components.Input](/ember/release/classes/Ember.Templates.components/methods/Input?anchor=Input) for usage details.
  
    ## Layout and LayoutName properties
  
    Because HTML `input` elements are self closing `layout` and `layoutName`
    properties will not be applied.
  
    @class TextField
    @extends Component
    @uses Ember.TextSupport
    @public
  */


  var TextField = Component.extend(_views.TextSupport, {
    layout,

    /**
      By default, this component will add the `ember-text-field` class to the component's element.
         @property classNames
      @type Array | String
      @default ['ember-text-field']
      @public
     */
    classNames: ['ember-text-field'],
    tagName: 'input',

    /**
      By default this component will forward a number of arguments to attributes on the the
      component's element:
         * accept
      * autocomplete
      * autosave
      * dir
      * formaction
      * formenctype
      * formmethod
      * formnovalidate
      * formtarget
      * height
      * inputmode
      * lang
      * list
      * type
      * max
      * min
      * multiple
      * name
      * pattern
      * size
      * step
      * value
      * width
         When invoked with `{{input type="text"}}`, you can only customize these attributes. When invoked
      with `<Input @type="text" />`, you can just use HTML attributes directly.
         @property attributeBindings
      @type Array | String
      @default ['accept', 'autocomplete', 'autosave', 'dir', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'type', 'max', 'min', 'multiple', 'name', 'pattern', 'size', 'step', 'value', 'width']
      @public
    */
    attributeBindings: ['accept', 'autocomplete', 'autosave', 'dir', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'type', 'max', 'min', 'multiple', 'name', 'pattern', 'size', 'step', 'value', 'width'],

    /**
      As the user inputs text, this property is updated to reflect the `value` property of the HTML
      element.
         @property value
      @type String
      @default ""
      @public
    */
    value: '',

    /**
      The `type` attribute of the input element.
         @property type
      @type String
      @default "text"
      @public
    */
    type: (0, _metal.computed)({
      get() {
        return 'text';
      },

      set(_key, value) {
        var type = 'text';

        if (canSetTypeOfInput(value)) {
          type = value;
        }

        return type;
      }

    }),

    /**
      The `size` of the text field in characters.
         @property size
      @type String
      @default null
      @public
    */
    size: null,

    /**
      The `pattern` attribute of input element.
         @property pattern
      @type String
      @default null
      @public
    */
    pattern: null,

    /**
      The `min` attribute of input element used with `type="number"` or `type="range"`.
         @property min
      @type String
      @default null
      @since 1.4.0
      @public
    */
    min: null,

    /**
      The `max` attribute of input element used with `type="number"` or `type="range"`.
         @property max
      @type String
      @default null
      @since 1.4.0
      @public
    */
    max: null
  });
  _exports.TextField = TextField;

  TextField.toString = () => '@ember/component/text-field';
  /**
  @module @ember/component
  */

  /**
    The `Textarea` component inserts a new instance of `<textarea>` tag into the template.
  
    The `@value` argument provides the content of the `<textarea>`.
  
    This template:
  
    ```handlebars
    <Textarea @value="A bunch of text" />
    ```
  
    Would result in the following HTML:
  
    ```html
    <textarea class="ember-text-area">
      A bunch of text
    </textarea>
    ```
  
    The `@value` argument is two-way bound. If the user types text into the textarea, the `@value`
    argument is updated. If the `@value` argument is updated, the text in the textarea is updated.
  
    In the following example, the `writtenWords` property on the component will be updated as the user
    types 'Lots of text' into the text area of their browser's window.
  
    ```app/components/word-editor.js
    import Component from '@glimmer/component';
    import { tracked } from '@glimmer/tracking';
  
    export default class WordEditorComponent extends Component {
      @tracked writtenWords = "Lots of text that IS bound";
    }
    ```
  
    ```handlebars
    <Textarea @value={{writtenWords}} />
    ```
  
    Would result in the following HTML:
  
    ```html
    <textarea class="ember-text-area">
      Lots of text that IS bound
    </textarea>
    ```
  
    If you wanted a one way binding, you could use the `<textarea>` element directly, and use the
    `value` DOM property and the `input` event.
  
    ### Actions
  
    The `Textarea` component takes a number of arguments with callbacks that are invoked in
    response to user events.
  
    * `enter`
    * `insert-newline`
    * `escape-press`
    * `focus-in`
    * `focus-out`
    * `key-press`
  
    These callbacks are passed to `Textarea` like this:
  
    ```handlebars
    <Textarea @value={{this.searchWord}} @enter={{this.query}} />
    ```
  
    ## Classic Invocation Syntax
  
    The `Textarea` component can also be invoked using curly braces, just like any other Ember
    component.
  
    For example, this is an invocation using angle-bracket notation:
  
    ```handlebars
    <Textarea @value={{this.searchWord}} @enter={{this.query}} />
    ```
  
    You could accomplish the same thing using classic invocation:
  
    ```handlebars
    {{textarea value=this.searchWord enter=this.query}}
    ```
  
    The main difference is that angle-bracket invocation supports any HTML attribute using HTML
    attribute syntax, because attributes and arguments have different syntax when using angle-bracket
    invocation. Curly brace invocation, on the other hand, only has a single syntax for arguments,
    and components must manually map attributes onto component arguments.
  
    When using classic invocation with `{{textarea}}`, only the following attributes are mapped onto
    arguments:
  
    * rows
    * cols
    * name
    * selectionEnd
    * selectionStart
    * autocomplete
    * wrap
    * lang
    * dir
    * value
  
    ## Classic `layout` and `layoutName` properties
  
    Because HTML `textarea` elements do not contain inner HTML the `layout` and
    `layoutName` properties will not be applied.
  
    @method Textarea
    @for Ember.Templates.components
    @see {TextArea}
    @public
  */

  /**
    See Ember.Templates.components.Textarea.
  
    @method textarea
    @for Ember.Templates.helpers
    @see {Ember.Templates.components.textarea}
    @public
  */

  /**
    The internal representation used for `Textarea` invocations.
  
    @class TextArea
    @extends Component
    @see {Ember.Templates.components.Textarea}
    @uses Ember.TextSupport
    @public
  */


  var TextArea = Component.extend(_views.TextSupport, {
    classNames: ['ember-text-area'],
    layout,
    tagName: 'textarea',
    attributeBindings: ['rows', 'cols', 'name', 'selectionEnd', 'selectionStart', 'autocomplete', 'wrap', 'lang', 'dir', 'value'],
    rows: null,
    cols: null
  });
  _exports.TextArea = TextArea;

  TextArea.toString = () => '@ember/component/text-area';

  var layout$1 = template({
    "id": "uDKxl8ne",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[18,1,null]],\"parameters\":[]},{\"statements\":[[1,[32,0,[\"linkTitle\"]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/link-to.hbs"
    }
  });
  /**
  @module ember
  */

  /**
    The `LinkTo` component renders a link to the supplied `routeName` passing an optionally
    supplied model to the route as its `model` context of the route. The block for `LinkTo`
    becomes the contents of the rendered element:
  
    ```handlebars
    <LinkTo @route='photoGallery'>
      Great Hamster Photos
    </LinkTo>
    ```
  
    This will result in:
  
    ```html
    <a href="/hamster-photos">
      Great Hamster Photos
    </a>
    ```
  
    ### Disabling the `LinkTo` component
  
    The `LinkTo` component can be disabled by using the `disabled` argument. A disabled link
    doesn't result in a transition when activated, and adds the `disabled` class to the `<a>`
    element.
  
    (The class name to apply to the element can be overridden by using the `disabledClass`
    argument)
  
    ```handlebars
    <LinkTo @route='photoGallery' @disabled={{true}}>
      Great Hamster Photos
    </LinkTo>
    ```
  
    ### Handling `href`
  
    `<LinkTo>` will use your application's Router to fill the element's `href` property with a URL
    that matches the path to the supplied `routeName`.
  
    ### Handling current route
  
    The `LinkTo` component will apply a CSS class name of 'active' when the application's current
    route matches the supplied routeName. For example, if the application's current route is
    'photoGallery.recent', then the following invocation of `LinkTo`:
  
    ```handlebars
    <LinkTo @route='photoGallery.recent'>
      Great Hamster Photos
    </LinkTo>
    ```
  
    will result in
  
    ```html
    <a href="/hamster-photos/this-week" class="active">
      Great Hamster Photos
    </a>
    ```
  
    The CSS class used for active classes can be customized by passing an `activeClass` argument:
  
    ```handlebars
    <LinkTo @route='photoGallery.recent' @activeClass="current-url">
      Great Hamster Photos
    </LinkTo>
    ```
  
    ```html
    <a href="/hamster-photos/this-week" class="current-url">
      Great Hamster Photos
    </a>
    ```
  
    ### Keeping a link active for other routes
  
    If you need a link to be 'active' even when it doesn't match the current route, you can use the
    `current-when` argument.
  
    ```handlebars
    <LinkTo @route='photoGallery' @current-when='photos'>
      Photo Gallery
    </LinkTo>
    ```
  
    This may be helpful for keeping links active for:
  
    * non-nested routes that are logically related
    * some secondary menu approaches
    * 'top navigation' with 'sub navigation' scenarios
  
    A link will be active if `current-when` is `true` or the current
    route is the route this link would transition to.
  
    To match multiple routes 'space-separate' the routes:
  
    ```handlebars
    <LinkTo @route='gallery' @current-when='photos drawings paintings'>
      Art Gallery
    </LinkTo>
    ```
  
    ### Supplying a model
  
    An optional `model` argument can be used for routes whose
    paths contain dynamic segments. This argument will become
    the model context of the linked route:
  
    ```javascript
    Router.map(function() {
      this.route("photoGallery", {path: "hamster-photos/:photo_id"});
    });
    ```
  
    ```handlebars
    <LinkTo @route='photoGallery' @model={{this.aPhoto}}>
      {{aPhoto.title}}
    </LinkTo>
    ```
  
    ```html
    <a href="/hamster-photos/42">
      Tomster
    </a>
    ```
  
    ### Supplying multiple models
  
    For deep-linking to route paths that contain multiple
    dynamic segments, the `models` argument can be used.
  
    As the router transitions through the route path, each
    supplied model argument will become the context for the
    route with the dynamic segments:
  
    ```javascript
    Router.map(function() {
      this.route("photoGallery", { path: "hamster-photos/:photo_id" }, function() {
        this.route("comment", {path: "comments/:comment_id"});
      });
    });
    ```
  
    This argument will become the model context of the linked route:
  
    ```handlebars
    <LinkTo @route='photoGallery.comment' @models={{array this.aPhoto this.comment}}>
      {{comment.body}}
    </LinkTo>
    ```
  
    ```html
    <a href="/hamster-photos/42/comments/718">
      A+++ would snuggle again.
    </a>
    ```
  
    ### Supplying an explicit dynamic segment value
  
    If you don't have a model object available to pass to `LinkTo`,
    an optional string or integer argument can be passed for routes whose
    paths contain dynamic segments. This argument will become the value
    of the dynamic segment:
  
    ```javascript
    Router.map(function() {
      this.route("photoGallery", { path: "hamster-photos/:photo_id" });
    });
    ```
  
    ```handlebars
    <LinkTo @route='photoGallery' @model={{aPhotoId}}>
      {{this.aPhoto.title}}
    </LinkTo>
    ```
  
    ```html
    <a href="/hamster-photos/42">
      Tomster
    </a>
    ```
  
    When transitioning into the linked route, the `model` hook will
    be triggered with parameters including this passed identifier.
  
    ### Allowing Default Action
  
    By default the `<LinkTo>` component prevents the default browser action by calling
    `preventDefault()` to avoid reloading the browser page.
  
    If you need to trigger a full browser reload pass `@preventDefault={{false}}`:
  
    ```handlebars
    <LinkTo @route='photoGallery' @model={{this.aPhotoId}} @preventDefault={{false}}>
      {{this.aPhotoId.title}}
    </LinkTo>
    ```
  
    ### Supplying a `tagName`
  
    By default `<LinkTo>` renders an `<a>` element. This can be overridden for a single use of
    `<LinkTo>` by supplying a `tagName` argument:
  
    ```handlebars
    <LinkTo @route='photoGallery' @tagName='li'>
      Great Hamster Photos
    </LinkTo>
    ```
  
    This produces:
  
    ```html
    <li>
      Great Hamster Photos
    </li>
    ```
  
    In general, this is not recommended. Instead, you can use the `transition-to` helper together
    with a click event handler on the HTML tag of your choosing.
  
    ### Supplying query parameters
  
    If you need to add optional key-value pairs that appear to the right of the ? in a URL,
    you can use the `query` argument.
  
    ```handlebars
    <LinkTo @route='photoGallery' @query={{hash page=1 per_page=20}}>
      Great Hamster Photos
    </LinkTo>
    ```
  
    This will result in:
  
    ```html
    <a href="/hamster-photos?page=1&per_page=20">
      Great Hamster Photos
    </a>
    ```
  
    @for Ember.Templates.components
    @method LinkTo
    @see {LinkComponent}
    @public
  */

  /**
    @module @ember/routing
  */

  /**
    See [Ember.Templates.components.LinkTo](/ember/release/classes/Ember.Templates.components/methods/input?anchor=LinkTo).
  
    @for Ember.Templates.helpers
    @method link-to
    @see {Ember.Templates.components.LinkTo}
    @public
  **/

  /**
    `LinkComponent` is the internal component invoked with `<LinkTo>` or `{{link-to}}`.
  
    @class LinkComponent
    @extends Component
    @see {Ember.Templates.components.LinkTo}
    @public
  **/

  var UNDEFINED = Object.freeze({
    toString() {
      return 'UNDEFINED';
    }

  });
  var EMPTY_QUERY_PARAMS = Object.freeze({});
  var LinkComponent = Component.extend({
    layout: layout$1,
    tagName: 'a',

    /**
      @property route
      @public
    */
    route: UNDEFINED,

    /**
      @property model
      @public
    */
    model: UNDEFINED,

    /**
      @property models
      @public
    */
    models: UNDEFINED,

    /**
      @property query
      @public
    */
    query: UNDEFINED,

    /**
      Used to determine when this `LinkComponent` is active.
         @property current-when
      @public
    */
    'current-when': null,

    /**
      Sets the `title` attribute of the `LinkComponent`'s HTML element.
         @property title
      @default null
      @public
    **/
    title: null,

    /**
      Sets the `rel` attribute of the `LinkComponent`'s HTML element.
         @property rel
      @default null
      @public
    **/
    rel: null,

    /**
      Sets the `tabindex` attribute of the `LinkComponent`'s HTML element.
         @property tabindex
      @default null
      @public
    **/
    tabindex: null,

    /**
      Sets the `target` attribute of the `LinkComponent`'s HTML element.
         @since 1.8.0
      @property target
      @default null
      @public
    **/
    target: null,

    /**
      The CSS class to apply to `LinkComponent`'s element when its `active`
      property is `true`.
         @property activeClass
      @type String
      @default active
      @public
    **/
    activeClass: 'active',

    /**
      The CSS class to apply to `LinkComponent`'s element when its `loading`
      property is `true`.
         @property loadingClass
      @type String
      @default loading
      @private
    **/
    loadingClass: 'loading',

    /**
      The CSS class to apply to a `LinkComponent`'s element when its `disabled`
      property is `true`.
         @property disabledClass
      @type String
      @default disabled
      @private
    **/
    disabledClass: 'disabled',

    /**
      Determines whether the `LinkComponent` will trigger routing via
      the `replaceWith` routing strategy.
         @property replace
      @type Boolean
      @default false
      @public
    **/
    replace: false,

    /**
      By default this component will forward `href`, `title`, `rel`, `tabindex`, and `target`
      arguments to attributes on the component's element. When invoked with `{{link-to}}`, you can
      only customize these attributes. When invoked with `<LinkTo>`, you can just use HTML
      attributes directly.
         @property attributeBindings
      @type Array | String
      @default ['title', 'rel', 'tabindex', 'target']
      @public
    */
    attributeBindings: ['href', 'title', 'rel', 'tabindex', 'target'],

    /**
      By default this component will set classes on its element when any of the following arguments
      are truthy:
         * active
      * loading
      * disabled
         When these arguments are truthy, a class with the same name will be set on the element. When
      falsy, the associated class will not be on the element.
         @property classNameBindings
      @type Array
      @default ['active', 'loading', 'disabled', 'ember-transitioning-in', 'ember-transitioning-out']
      @public
    */
    classNameBindings: ['active', 'loading', 'disabled', 'transitioningIn', 'transitioningOut'],

    /**
      By default this component responds to the `click` event. When the component element is an
      `<a>` element, activating the link in another way, such as using the keyboard, triggers the
      click event.
         @property eventName
      @type String
      @default click
      @private
    */
    eventName: 'click',

    // this is doc'ed here so it shows up in the events
    // section of the API documentation, which is where
    // people will likely go looking for it.

    /**
      Triggers the `LinkComponent`'s routing behavior. If
      `eventName` is changed to a value other than `click`
      the routing behavior will trigger on that custom event
      instead.
         @event click
      @private
    */

    /**
      An overridable method called when `LinkComponent` objects are instantiated.
         Example:
         ```app/components/my-link.js
      import LinkComponent from '@ember/routing/link-component';
         export default LinkComponent.extend({
        init() {
          this._super(...arguments);
          console.log('Event is ' + this.get('eventName'));
        }
      });
      ```
         NOTE: If you do override `init` for a framework class like `Component`,
      be sure to call `this._super(...arguments)` in your
      `init` declaration! If you don't, Ember may not have an opportunity to
      do important setup work, and you'll see strange behavior in your
      application.
         @method init
      @private
    */
    init() {
      this._super(...arguments); // Map desired event name to invoke function


      var {
        eventName
      } = this;
      this.on(eventName, this, this._invoke);
    },

    _routing: (0, _service.inject)('-routing'),
    _currentRoute: (0, _metal.alias)('_routing.currentRouteName'),
    _currentRouterState: (0, _metal.alias)('_routing.currentState'),
    _targetRouterState: (0, _metal.alias)('_routing.targetState'),
    _route: (0, _metal.computed)('route', '_currentRouterState', function computeLinkToComponentRoute() {
      var {
        route
      } = this;
      return route === UNDEFINED ? this._currentRoute : route;
    }),
    _models: (0, _metal.computed)('model', 'models', function computeLinkToComponentModels() {
      var {
        model,
        models
      } = this;
      (true && !(model === UNDEFINED || models === UNDEFINED) && (0, _debug.assert)('You cannot provide both the `@model` and `@models` arguments to the <LinkTo> component.', model === UNDEFINED || models === UNDEFINED));

      if (model !== UNDEFINED) {
        return [model];
      } else if (models !== UNDEFINED) {
        (true && !(Array.isArray(models)) && (0, _debug.assert)('The `@models` argument must be an array.', Array.isArray(models)));
        return models;
      } else {
        return [];
      }
    }),
    _query: (0, _metal.computed)('query', function computeLinkToComponentQuery() {
      var {
        query
      } = this;

      if (query === UNDEFINED) {
        return EMPTY_QUERY_PARAMS;
      } else {
        return (0, _polyfills.assign)({}, query);
      }
    }),

    /**
      Accessed as a classname binding to apply the component's `disabledClass`
      CSS `class` to the element when the link is disabled.
         When `true`, interactions with the element will not trigger route changes.
      @property disabled
      @private
    */
    disabled: (0, _metal.computed)({
      get(_key) {
        // always returns false for `get` because (due to the `set` just below)
        // the cached return value from the set will prevent this getter from _ever_
        // being called after a set has occurred
        return false;
      },

      set(_key, value) {
        this._isDisabled = value;
        return value ? this.disabledClass : false;
      }

    }),

    /**
      Accessed as a classname binding to apply the component's `activeClass`
      CSS `class` to the element when the link is active.
         This component is considered active when its `currentWhen` property is `true`
      or the application's current route is the route this component would trigger
      transitions into.
         The `currentWhen` property can match against multiple routes by separating
      route names using the ` ` (space) character.
         @property active
      @private
    */
    active: (0, _metal.computed)('activeClass', '_active', function computeLinkToComponentActiveClass() {
      return this._active ? this.activeClass : false;
    }),
    _active: (0, _metal.computed)('_currentRouterState', '_route', '_models', '_query', 'loading', 'current-when', function computeLinkToComponentActive() {
      var {
        _currentRouterState: state
      } = this;

      if (state) {
        return this._isActive(state);
      } else {
        return false;
      }
    }),
    willBeActive: (0, _metal.computed)('_currentRouterState', '_targetRouterState', '_route', '_models', '_query', 'loading', 'current-when', function computeLinkToComponentWillBeActive() {
      var {
        _currentRouterState: current,
        _targetRouterState: target
      } = this;

      if (current === target) {
        return;
      }

      return this._isActive(target);
    }),

    _isActive(routerState) {
      if (this.loading) {
        return false;
      }

      var currentWhen = this['current-when'];

      if (typeof currentWhen === 'boolean') {
        return currentWhen;
      }

      var isCurrentWhenSpecified = Boolean(currentWhen);

      if (isCurrentWhenSpecified) {
        currentWhen = currentWhen.split(' ');
      } else {
        currentWhen = [this._route];
      }

      var {
        _models: models,
        _query: query,
        _routing: routing
      } = this;

      for (var i = 0; i < currentWhen.length; i++) {
        if (routing.isActiveForRoute(models, query, currentWhen[i], routerState, isCurrentWhenSpecified)) {
          return true;
        }
      }

      return false;
    },

    transitioningIn: (0, _metal.computed)('_active', 'willBeActive', function computeLinkToComponentTransitioningIn() {
      if (this.willBeActive === true && !this._active) {
        return 'ember-transitioning-in';
      } else {
        return false;
      }
    }),
    transitioningOut: (0, _metal.computed)('_active', 'willBeActive', function computeLinkToComponentTransitioningOut() {
      if (this.willBeActive === false && this._active) {
        return 'ember-transitioning-out';
      } else {
        return false;
      }
    }),

    /**
      Event handler that invokes the link, activating the associated route.
         @method _invoke
      @param {Event} event
      @private
    */
    _invoke(event) {
      if (!(0, _views.isSimpleClick)(event)) {
        return true;
      }

      var {
        bubbles,
        preventDefault
      } = this;
      var target = this.element.target;
      var isSelf = !target || target === '_self';

      if (preventDefault !== false && isSelf) {
        event.preventDefault();
      }

      if (bubbles === false) {
        event.stopPropagation();
      }

      if (this._isDisabled) {
        return false;
      }

      if (this.loading) {
        // tslint:disable-next-line:max-line-length
        (true && (0, _debug.warn)('This link is in an inactive loading state because at least one of its models ' + 'currently has a null/undefined value, or the provided route name is invalid.', false, {
          id: 'ember-glimmer.link-to.inactive-loading-state'
        }));
        return false;
      }

      if (!isSelf) {
        return false;
      }

      var {
        _route: routeName,
        _models: models,
        _query: queryParams,
        replace: shouldReplace
      } = this;
      var payload = {
        queryParams,
        routeName
      };
      (0, _instrumentation.flaggedInstrument)('interaction.link-to', payload, this._generateTransition(payload, routeName, models, queryParams, shouldReplace));
      return false;
    },

    _generateTransition(payload, qualifiedRouteName, models, queryParams, shouldReplace) {
      var {
        _routing: routing
      } = this;
      return () => {
        payload.transition = routing.transitionTo(qualifiedRouteName, models, queryParams, shouldReplace);
      };
    },

    /**
      Sets the element's `href` attribute to the url for
      the `LinkComponent`'s targeted route.
         If the `LinkComponent`'s `tagName` is changed to a value other
      than `a`, this property will be ignored.
         @property href
      @private
    */
    href: (0, _metal.computed)('_currentRouterState', '_route', '_models', '_query', 'tagName', 'loading', 'loadingHref', function computeLinkToComponentHref() {
      if (this.tagName !== 'a') {
        return;
      }

      if (this.loading) {
        return this.loadingHref;
      }

      var {
        _route: route,
        _models: models,
        _query: query,
        _routing: routing
      } = this;

      if (true
      /* DEBUG */
      ) {
        /*
         * Unfortunately, to get decent error messages, we need to do this.
         * In some future state we should be able to use a "feature flag"
         * which allows us to strip this without needing to call it twice.
         *
         * if (isDebugBuild()) {
         *   // Do the useful debug thing, probably including try/catch.
         * } else {
         *   // Do the performant thing.
         * }
         */
        try {
          return routing.generateURL(route, models, query);
        } catch (e) {
          // tslint:disable-next-line:max-line-length
          (true && !(false) && (0, _debug.assert)(`You attempted to generate a link for the "${this.route}" route, but did not ` + `pass the models required for generating its dynamic segments. ` + e.message));
        }
      } else {
        return routing.generateURL(route, models, query);
      }
    }),
    loading: (0, _metal.computed)('_route', '_modelsAreLoaded', 'loadingClass', function computeLinkToComponentLoading() {
      var {
        _route: route,
        _modelsAreLoaded: loaded
      } = this;

      if (!loaded || route === null || route === undefined) {
        return this.loadingClass;
      }
    }),
    _modelsAreLoaded: (0, _metal.computed)('_models', function computeLinkToComponentModelsAreLoaded() {
      var {
        _models: models
      } = this;

      for (var i = 0; i < models.length; i++) {
        var model = models[i];

        if (model === null || model === undefined) {
          return false;
        }
      }

      return true;
    }),

    /**
      The default href value to use while a link-to is loading.
      Only applies when tagName is 'a'
         @property loadingHref
      @type String
      @default #
      @private
    */
    loadingHref: '#',

    didReceiveAttrs() {
      var {
        disabledWhen
      } = this;

      if (disabledWhen !== undefined) {
        this.set('disabled', disabledWhen);
      }

      var {
        params
      } = this;

      if (!params || params.length === 0) {
        (true && !(!(this.route === UNDEFINED && this.model === UNDEFINED && this.models === UNDEFINED && this.query === UNDEFINED)) && (0, _debug.assert)('You must provide at least one of the `@route`, `@model`, `@models` or `@query` argument to `<LinkTo>`.', !(this.route === UNDEFINED && this.model === UNDEFINED && this.models === UNDEFINED && this.query === UNDEFINED)));
        var {
          _models: models
        } = this;

        if (models.length > 0) {
          var lastModel = models[models.length - 1];

          if (typeof lastModel === 'object' && lastModel !== null && lastModel.isQueryParams) {
            this.query = lastModel.values;
            models.pop();
          }
        }

        return;
      }

      params = params.slice(); // Process the positional arguments, in order.
      // 1. Inline link title comes first, if present.

      if (!this[HAS_BLOCK]) {
        this.set('linkTitle', params.shift());
      } // 2. The last argument is possibly the `query` object.


      var queryParams = params[params.length - 1];

      if (queryParams && queryParams.isQueryParams) {
        this.set('query', params.pop().values);
      } else {
        this.set('query', UNDEFINED);
      } // 3. If there is a `route`, it is now at index 0.


      if (params.length === 0) {
        this.set('route', UNDEFINED);
      } else {
        this.set('route', params.shift());
      } // 4. Any remaining indices (if any) are `models`.


      this.set('model', UNDEFINED);
      this.set('models', params);
    }

  });
  _exports.LinkComponent = LinkComponent;

  LinkComponent.toString = () => '@ember/routing/link-component';

  LinkComponent.reopenClass({
    positionalParams: 'params'
  });
  /**
  @module @ember/component
  */

  var RECOMPUTE_TAG = (0, _utils.symbol)('RECOMPUTE_TAG');

  function isHelperFactory(helper) {
    return typeof helper === 'object' && helper !== null && helper.class && helper.class.isHelperFactory;
  }

  function isClassHelper(helper) {
    return helper.destroy !== undefined;
  }
  /**
    Ember Helpers are functions that can compute values, and are used in templates.
    For example, this code calls a helper named `format-currency`:
  
    ```app/templates/application.hbs
    <Cost @cents={{230}} />
    ```
  
    ```app/components/cost.hbs
    <div>{{format-currency @cents currency="$"}}</div>
    ```
  
    Additionally a helper can be called as a nested helper.
    In this example, we show the formatted currency value if the `showMoney`
    named argument is truthy.
  
    ```handlebars
    {{if @showMoney (format-currency @cents currency="$")}}
    ```
  
    Helpers defined using a class must provide a `compute` function. For example:
  
    ```app/helpers/format-currency.js
    import Helper from '@ember/component/helper';
  
    export default class extends Helper {
      compute([cents], { currency }) {
        return `${currency}${cents * 0.01}`;
      }
    }
    ```
  
    Each time the input to a helper changes, the `compute` function will be
    called again.
  
    As instances, these helpers also have access to the container and will accept
    injected dependencies.
  
    Additionally, class helpers can call `recompute` to force a new computation.
  
    @class Helper
    @public
    @since 1.13.0
  */


  var Helper = _runtime.FrameworkObject.extend({
    init() {
      this._super(...arguments);

      this[RECOMPUTE_TAG] = (0, _validator.createTag)();
    },

    /**
      On a class-based helper, it may be useful to force a recomputation of that
      helpers value. This is akin to `rerender` on a component.
         For example, this component will rerender when the `currentUser` on a
      session service changes:
         ```app/helpers/current-user-email.js
      import Helper from '@ember/component/helper'
      import { inject as service } from '@ember/service'
      import { observer } from '@ember/object'
         export default Helper.extend({
        session: service(),
           onNewUser: observer('session.currentUser', function() {
          this.recompute();
        }),
           compute() {
          return this.get('session.currentUser.email');
        }
      });
      ```
         @method recompute
      @public
      @since 1.13.0
    */
    recompute() {
      (0, _runloop.join)(() => (0, _validator.dirtyTag)(this[RECOMPUTE_TAG]));
    }

  });

  _exports.Helper = Helper;
  Helper.isHelperFactory = true;

  class Wrapper {
    constructor(compute) {
      this.compute = compute;
      this.isHelperFactory = true;
    }

    create() {
      // needs new instance or will leak containers
      return {
        compute: this.compute
      };
    }

  }
  /**
    In many cases it is not necessary to use the full `Helper` class.
    The `helper` method create pure-function helpers without instances.
    For example:
  
    ```app/helpers/format-currency.js
    import { helper } from '@ember/component/helper';
  
    export default helper(function([cents], {currency}) {
      return `${currency}${cents * 0.01}`;
    });
    ```
  
    @static
    @param {Function} helper The helper function
    @method helper
    @for @ember/component/helper
    @public
    @since 1.13.0
  */


  function helper(helperFn) {
    return new Wrapper(helperFn);
  }
  /**
  @module @ember/template
  */


  class SafeString {
    constructor(string) {
      this.string = string;
    }

    toString() {
      return `${this.string}`;
    }

    toHTML() {
      return this.toString();
    }

  }

  _exports.SafeString = SafeString;
  var escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
    '=': '&#x3D;'
  };
  var possible = /[&<>"'`=]/;
  var badChars = /[&<>"'`=]/g;

  function escapeChar(chr) {
    return escape[chr];
  }

  function escapeExpression(string) {
    if (typeof string !== 'string') {
      // don't escape SafeStrings, since they're already safe
      if (string && string.toHTML) {
        return string.toHTML();
      } else if (string === null || string === undefined) {
        return '';
      } else if (!string) {
        return String(string);
      } // Force a string conversion as this will be done by the append regardless and
      // the regex test will do this transparently behind the scenes, causing issues if
      // an object's to string has escaped characters in it.


      string = String(string);
    }

    if (!possible.test(string)) {
      return string;
    }

    return string.replace(badChars, escapeChar);
  }
  /**
    Mark a string as safe for unescaped output with Ember templates. If you
    return HTML from a helper, use this function to
    ensure Ember's rendering layer does not escape the HTML.
  
    ```javascript
    import { htmlSafe } from '@ember/template';
  
    htmlSafe('<div>someString</div>')
    ```
  
    @method htmlSafe
    @for @ember/template
    @static
    @return {SafeString} A string that will not be HTML escaped by Handlebars.
    @public
  */


  function htmlSafe(str) {
    if (str === null || str === undefined) {
      str = '';
    } else if (typeof str !== 'string') {
      str = String(str);
    }

    return new SafeString(str);
  }
  /**
    Detects if a string was decorated using `htmlSafe`.
  
    ```javascript
    import { htmlSafe, isHTMLSafe } from '@ember/template';
  
    var plainString = 'plain string',
        safeString = htmlSafe('<div>someValue</div>');
  
    isHTMLSafe(plainString); // false
    isHTMLSafe(safeString);  // true
    ```
  
    @method isHTMLSafe
    @for @ember/template
    @static
    @return {Boolean} `true` if the string was decorated with `htmlSafe`, `false` otherwise.
    @public
  */


  function isHTMLSafe(str) {
    return str !== null && typeof str === 'object' && typeof str.toHTML === 'function';
  }

  function isStaticComponentManager(_manager, capabilities) {
    return !capabilities.dynamicLayout;
  }

  class CompileTimeResolver {
    constructor(resolver) {
      this.resolver = resolver;
    }

    lookupHelper(name, referrer) {
      return this.resolver.lookupHelper(name, referrer);
    }

    lookupModifier(name, referrer) {
      return this.resolver.lookupModifier(name, referrer);
    }

    lookupComponent(name, referrer) {
      var definitionHandle = this.resolver.lookupComponentHandle(name, referrer);

      if (definitionHandle === null) {
        return null;
      }

      var {
        manager,
        state
      } = this.resolver.resolve(definitionHandle);
      var capabilities = manager.getCapabilities(state);

      if (!isStaticComponentManager(manager, capabilities)) {
        return {
          handle: definitionHandle,
          capabilities,
          compilable: null
        };
      }

      return {
        handle: definitionHandle,
        capabilities,
        compilable: manager.getJitStaticLayout(state, this.resolver)
      };
    }

    lookupPartial(name, referrer) {
      return this.resolver.lookupPartial(name, referrer);
    }

    resolve(handle) {
      return this.resolver.resolve(handle);
    }

  } // implements the ComponentManager interface as defined in glimmer:
  // tslint:disable-next-line:max-line-length
  // https://github.com/glimmerjs/glimmer-vm/blob/v0.24.0-beta.4/packages/%40glimmer/runtime/lib/component/interfaces.ts#L21


  class AbstractManager {
    prepareArgs(_state, _args) {
      return null;
    }

    didCreateElement(_component, _element, _operations) {// noop
    }

    didRenderLayout(_component, _bounds) {// noop
    }

    didCreate(_bucket) {// noop
    }

    update(_bucket, _dynamicScope) {// noop
    }

    didUpdateLayout(_bucket, _bounds) {// noop
    }

    didUpdate(_bucket) {// noop
    }

  }

  _exports.AbstractComponentManager = AbstractManager;

  function instrumentationPayload(def) {
    return {
      object: `${def.name}:${def.outlet}`
    };
  }

  var CAPABILITIES = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: _environment2.ENV._DEBUG_RENDER_TREE,
    attributeHook: false,
    elementHook: false,
    createCaller: false,
    dynamicScope: true,
    updateHook: _environment2.ENV._DEBUG_RENDER_TREE,
    createInstance: true,
    wrapped: false,
    willDestroy: false
  };

  class OutletComponentManager extends AbstractManager {
    create(environment, definition, args, dynamicScope) {
      var parentStateRef = dynamicScope.outletState;
      var currentStateRef = definition.ref;
      dynamicScope.outletState = currentStateRef;
      var state = {
        self: new _reference.ComponentRootReference(definition.controller, environment),
        environment,
        finalize: (0, _instrumentation._instrumentStart)('render.outlet', instrumentationPayload, definition)
      };

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        state.outlet = {
          name: definition.outlet
        };
        environment.extra.debugRenderTree.create(state.outlet, {
          type: 'outlet',
          name: state.outlet.name,
          args: _runtime2.EMPTY_ARGS,
          instance: undefined,
          template: undefined
        });
        var parentState = parentStateRef.value();
        var parentOwner = parentState && parentState.render && parentState.render.owner;
        var currentOwner = currentStateRef.value().render.owner;

        if (parentOwner && parentOwner !== currentOwner) {
          var engine = currentOwner;
          (true && !(typeof currentOwner.mountPoint === 'string') && (0, _debug.assert)('invalid engine: missing mountPoint', typeof currentOwner.mountPoint === 'string'));
          (true && !(currentOwner.routable === true) && (0, _debug.assert)('invalid engine: missing routable', currentOwner.routable === true));
          var mountPoint = engine.mountPoint;
          state.engine = {
            mountPoint
          };
          environment.extra.debugRenderTree.create(state.engine, {
            type: 'engine',
            name: mountPoint,
            args: _runtime2.EMPTY_ARGS,
            instance: engine,
            template: undefined
          });
        }

        environment.extra.debugRenderTree.create(state, {
          type: 'route-template',
          name: definition.name,
          args: args.capture(),
          instance: definition.controller,
          template: definition.template
        });
        (0, _runtime2.registerDestructor)(state, () => {
          state.environment.extra.debugRenderTree.willDestroy(state);

          if (state.engine) {
            state.environment.extra.debugRenderTree.willDestroy(state.engine);
          }

          state.environment.extra.debugRenderTree.willDestroy(state.outlet);
        });
      }

      return state;
    }

    getJitStaticLayout({
      template
    }, _resolver) {
      // The router has already resolved the template
      return (0, _util.unwrapTemplate)(template).asLayout();
    }

    getCapabilities() {
      return CAPABILITIES;
    }

    getSelf({
      self
    }) {
      return self;
    }

    getTag() {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        // returning a const tag skips the update hook (VM BUG?)
        return (0, _validator.createTag)();
      } else {
        // an outlet has no hooks
        return _validator.CONSTANT_TAG;
      }
    }

    didRenderLayout(state, bounds) {
      state.finalize();

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        state.environment.extra.debugRenderTree.didRender(state, bounds);

        if (state.engine) {
          state.environment.extra.debugRenderTree.didRender(state.engine, bounds);
        }

        state.environment.extra.debugRenderTree.didRender(state.outlet, bounds);
      }
    }

    update(state) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        state.environment.extra.debugRenderTree.update(state.outlet);

        if (state.engine) {
          state.environment.extra.debugRenderTree.update(state.engine);
        }

        state.environment.extra.debugRenderTree.update(state);
      }
    }

    didUpdateLayout(state, bounds) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        state.environment.extra.debugRenderTree.didRender(state, bounds);

        if (state.engine) {
          state.environment.extra.debugRenderTree.didRender(state.engine, bounds);
        }

        state.environment.extra.debugRenderTree.didRender(state.outlet, bounds);
      }
    }

    getDestroyable(state) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        return state;
      } else {
        return null;
      }
    }

  }

  var OUTLET_MANAGER = new OutletComponentManager();

  class OutletComponentDefinition {
    constructor(state, manager = OUTLET_MANAGER) {
      this.state = state;
      this.manager = manager;
    }

  }

  function createRootOutlet(outletView) {
    if (_environment2.ENV._APPLICATION_TEMPLATE_WRAPPER) {
      var WRAPPED_CAPABILITIES = (0, _polyfills.assign)({}, CAPABILITIES, {
        dynamicTag: true,
        elementHook: true,
        wrapped: true
      });
      var WrappedOutletComponentManager = class extends OutletComponentManager {
        getTagName(_component) {
          return 'div';
        }

        getJitStaticLayout({
          template
        }) {
          // The router has already resolved the template
          return (0, _util.unwrapTemplate)(template).asWrappedLayout();
        }

        getCapabilities() {
          return WRAPPED_CAPABILITIES;
        }

        didCreateElement(component, element, _operations) {
          // to add GUID id and class
          element.setAttribute('class', 'ember-view');
          element.setAttribute('id', (0, _utils.guidFor)(component));
        }

      };
      var WRAPPED_OUTLET_MANAGER = new WrappedOutletComponentManager();
      return new OutletComponentDefinition(outletView.state, WRAPPED_OUTLET_MANAGER);
    } else {
      return new OutletComponentDefinition(outletView.state);
    }
  }

  function NOOP() {}
  /**
    @module ember
  */

  /**
    Represents the internal state of the component.
  
    @class ComponentStateBucket
    @private
  */


  class ComponentStateBucket {
    constructor(environment, component, args, finalizer, hasWrappedElement) {
      this.environment = environment;
      this.component = component;
      this.args = args;
      this.finalizer = finalizer;
      this.hasWrappedElement = hasWrappedElement;
      this.classRef = null;
      this.classRef = null;
      this.argsRevision = args === null ? 0 : (0, _validator.valueForTag)(args.tag);
      this.rootRef = new _reference.ComponentRootReference(component, environment);
      (0, _runtime2.registerDestructor)(this, () => this.willDestroy(), true);
      (0, _runtime2.registerDestructor)(this, () => this.component.destroy());
    }

    willDestroy() {
      var {
        component,
        environment
      } = this;

      if (environment.isInteractive) {
        component.trigger('willDestroyElement');
        component.trigger('willClearRender');
        var element = (0, _views.getViewElement)(component);

        if (element) {
          (0, _views.clearElementView)(element);
          (0, _views.clearViewElement)(component);
        }
      }

      component.renderer.unregister(component);
    }

    finalize() {
      var {
        finalizer
      } = this;
      finalizer();
      this.finalizer = NOOP;
    }

  }

  class EmberHelperRootReference extends _reference.HelperRootReference {
    constructor(helper$$1, args, env) {
      var fnWrapper = args => {
        var {
          positional,
          named
        } = args;
        var positionalValue = positional.value();
        var namedValue = named.value();
        var ret;

        if (true
        /* DEBUG */
        ) {
          (0, _debug.debugFreeze)(positionalValue);
          (0, _debug.debugFreeze)(namedValue);
          (0, _validator.deprecateMutationsInAutotrackingTransaction)(() => {
            ret = helper$$1.compute(positionalValue, namedValue);
          });
        } else {
          ret = helper$$1.compute(positionalValue, namedValue);
        }

        if (helper$$1[RECOMPUTE_TAG]) {
          (0, _validator.consumeTag)(helper$$1[RECOMPUTE_TAG]);
        }

        return ret;
      };

      if (true
      /* DEBUG */
      ) {
        var debugName = isClassHelper(helper$$1) ? (0, _utils.getDebugName)(helper$$1) : (0, _utils.getDebugName)(helper$$1.compute);
        super(fnWrapper, args, env, debugName);
      } else {
        super(fnWrapper, args, env);
      }
    }

  }

  class UnboundRootReference extends _reference.RootReference {
    constructor(inner, env, parent, key) {
      super(env);
      this.inner = inner;
      this.env = env;

      if (true
      /* DEBUG */
      ) {
        env.setTemplatePathDebugContext(this, key || 'this', parent || null);
      }
    }

    value() {
      return this.inner;
    }

    get(key) {
      var value = this.value();

      if ((0, _utils.isObject)(value)) {
        // root of interop with ember objects
        return new UnboundPropertyReference(value[key], this.env, this, key);
      } else {
        return _runtime2.PrimitiveReference.create(value);
      }
    }

  }

  class UnboundPropertyReference extends UnboundRootReference {}

  function referenceFromParts(root, parts) {
    var reference = root;

    for (var i = 0; i < parts.length; i++) {
      reference = reference.get(parts[i]);
    }

    return reference;
  }

  function referenceForKey(rootRef, key) {
    return rootRef.get(key);
  }

  function referenceForParts(rootRef, parts) {
    var isAttrs = parts[0] === 'attrs'; // TODO deprecate this

    if (isAttrs) {
      parts.shift();

      if (parts.length === 1) {
        return referenceForKey(rootRef, parts[0]);
      }
    }

    return referenceFromParts(rootRef, parts);
  }

  var AttributeBinding = {
    parse(microsyntax) {
      var colonIndex = microsyntax.indexOf(':');

      if (colonIndex === -1) {
        (true && !(microsyntax !== 'class') && (0, _debug.assert)('You cannot use class as an attributeBinding, use classNameBindings instead.', microsyntax !== 'class'));
        return [microsyntax, microsyntax, true];
      } else {
        var prop = microsyntax.substring(0, colonIndex);
        var attribute = microsyntax.substring(colonIndex + 1);
        (true && !(attribute !== 'class') && (0, _debug.assert)('You cannot use class as an attributeBinding, use classNameBindings instead.', attribute !== 'class'));
        return [prop, attribute, false];
      }
    },

    install(component, rootRef, parsed, operations, env) {
      var [prop, attribute, isSimple] = parsed;

      if (attribute === 'id') {
        var elementId = (0, _metal.get)(component, prop);

        if (elementId === undefined || elementId === null) {
          elementId = component.elementId;
        }

        elementId = _runtime2.PrimitiveReference.create(elementId);
        operations.setAttribute('id', elementId, true, null); // operations.addStaticAttribute(element, 'id', elementId);

        return;
      }

      var isPath = prop.indexOf('.') > -1;
      var reference = isPath ? referenceForParts(rootRef, prop.split('.')) : referenceForKey(rootRef, prop);
      (true && !(!(isSimple && isPath)) && (0, _debug.assert)(`Illegal attributeBinding: '${prop}' is not a valid attribute name.`, !(isSimple && isPath)));

      if (_deprecatedFeatures.EMBER_COMPONENT_IS_VISIBLE && attribute === 'style' && StyleBindingReference !== undefined) {
        reference = new StyleBindingReference(rootRef, reference, referenceForKey(rootRef, 'isVisible'), env);
      }

      operations.setAttribute(attribute, reference, false, null); // operations.addDynamicAttribute(element, attribute, reference, false);
    }

  };
  var DISPLAY_NONE = 'display: none;';
  var SAFE_DISPLAY_NONE = htmlSafe(DISPLAY_NONE);
  var StyleBindingReference;
  var installIsVisibleBinding;

  if (_deprecatedFeatures.EMBER_COMPONENT_IS_VISIBLE) {
    StyleBindingReference = class {
      constructor(parent, inner, isVisible, env) {
        this.inner = inner;
        this.isVisible = isVisible;
        this.env = env;
        this.tag = (0, _validator.combine)([inner.tag, isVisible.tag]);

        if (true
        /* DEBUG */
        ) {
          env.setTemplatePathDebugContext(this, 'style', parent);
        }
      }

      value() {
        var value = this.inner.value();
        var isVisible = this.isVisible.value();

        if (isVisible !== undefined) {
          (true && !(false) && (0, _debug.deprecate)(`The \`isVisible\` property on classic component classes is deprecated. Was accessed ${this.env.getTemplatePathDebugContext(this).replace(/^W/, 'w')}`, false, {
            id: 'ember-component.is-visible',
            until: '4.0.0',
            url: 'https://deprecations.emberjs.com/v3.x#toc_ember-component-is-visible'
          }));
        }

        if (isVisible !== false) {
          return value;
        } else if (!value) {
          return SAFE_DISPLAY_NONE;
        } else {
          var style = value + ' ' + DISPLAY_NONE;
          return isHTMLSafe(value) ? htmlSafe(style) : style;
        }
      }

      get() {
        return _runtime2.UNDEFINED_REFERENCE;
      }

    };

    installIsVisibleBinding = (rootRef, operations, environment) => {
      operations.setAttribute('style', new StyleBindingReference(rootRef, _runtime2.UNDEFINED_REFERENCE, rootRef.get('isVisible'), environment), false, null);
    };
  }

  var ClassNameBinding = {
    install(_element, rootRef, microsyntax, operations) {
      var [prop, truthy, falsy] = microsyntax.split(':');
      var isStatic = prop === '';

      if (isStatic) {
        operations.setAttribute('class', _runtime2.PrimitiveReference.create(truthy), true, null);
      } else {
        var isPath = prop.indexOf('.') > -1;
        var parts = isPath ? prop.split('.') : [];
        var value = isPath ? referenceForParts(rootRef, parts) : referenceForKey(rootRef, prop);
        var ref;

        if (truthy === undefined) {
          ref = new SimpleClassNameBindingReference(value, isPath ? parts[parts.length - 1] : prop);
        } else {
          ref = new ColonClassNameBindingReference(value, truthy, falsy);
        }

        operations.setAttribute('class', ref, false, null);
      }
    }

  };

  class SimpleClassNameBindingReference {
    constructor(inner, path) {
      this.inner = inner;
      this.path = path;
      this.tag = inner.tag;
      this.dasherizedPath = null;
    }

    value() {
      var value = this.inner.value();

      if (value === true) {
        var {
          path,
          dasherizedPath
        } = this;
        return dasherizedPath || (this.dasherizedPath = (0, _string.dasherize)(path));
      } else if (value || value === 0) {
        return String(value);
      } else {
        return null;
      }
    }

  }

  class ColonClassNameBindingReference {
    constructor(inner, truthy = null, falsy = null) {
      this.inner = inner;
      this.truthy = truthy;
      this.falsy = falsy;
      this.tag = inner.tag;
    }

    value() {
      var {
        inner,
        truthy,
        falsy
      } = this;
      return inner.value() ? truthy : falsy;
    }

  }
  /**
  @module ember
  */

  /**
    The `mut` helper lets you __clearly specify__ that a child `Component` can update the
    (mutable) value passed to it, which will __change the value of the parent component__.
  
    To specify that a parameter is mutable, when invoking the child `Component`:
  
    ```handlebars
    <MyChild @childClickCount={{fn (mut totalClicks)}} />
    ```
  
     or
  
    ```handlebars
    {{my-child childClickCount=(mut totalClicks)}}
    ```
  
    The child `Component` can then modify the parent's value just by modifying its own
    property:
  
    ```javascript
    // my-child.js
    export default Component.extend({
      click() {
        this.incrementProperty('childClickCount');
      }
    });
    ```
  
    Note that for curly components (`{{my-component}}`) the bindings are already mutable,
    making the `mut` unnecessary.
  
    Additionally, the `mut` helper can be combined with the `fn` helper to
    mutate a value. For example:
  
    ```handlebars
    <MyChild @childClickCount={{this.totalClicks}} @click-count-change={{fn (mut totalClicks))}} />
    ```
  
    or
  
    ```handlebars
    {{my-child childClickCount=totalClicks click-count-change=(fn (mut totalClicks))}}
    ```
  
    The child `Component` would invoke the function with the new click value:
  
    ```javascript
    // my-child.js
    export default Component.extend({
      click() {
        this.get('click-count-change')(this.get('childClickCount') + 1);
      }
    });
    ```
  
    The `mut` helper changes the `totalClicks` value to what was provided as the `fn` argument.
  
    The `mut` helper, when used with `fn`, will return a function that
    sets the value passed to `mut` to its first argument. As an example, we can create a
    button that increments a value passing the value directly to the `fn`:
  
    ```handlebars
    {{! inc helper is not provided by Ember }}
    <button onclick={{fn (mut count) (inc count)}}>
      Increment count
    </button>
    ```
  
    @method mut
    @param {Object} [attr] the "two-way" attribute that can be modified.
    @for Ember.Templates.helpers
    @public
  */


  var INVOKE = (0, _utils.symbol)('INVOKE');
  _exports.INVOKE = INVOKE;
  var SOURCE = (0, _utils.symbol)('SOURCE');

  class MutReference extends _reference.RootReference {
    constructor(inner, env) {
      super(env);
      this.inner = inner;
      this.tag = inner.tag;
      this[SOURCE] = inner;
    }

    value() {
      return this.inner.value();
    }

    get(key) {
      return this.inner.get(key);
    }

    [_reference.UPDATE_REFERENCED_VALUE](value) {
      return this.inner[_reference.UPDATE_REFERENCED_VALUE](value);
    }

    [INVOKE](value) {
      return this.inner[_reference.UPDATE_REFERENCED_VALUE](value);
    }

  }

  function unMut(ref) {
    return ref[SOURCE] || ref;
  }

  function mut(args, vm) {
    var rawRef = args.positional.at(0);

    if (typeof rawRef[INVOKE] === 'function') {
      return rawRef;
    } // TODO: Improve this error message. This covers at least two distinct
    // cases:
    //
    // 1. (mut "not a path") – passing a literal, result from a helper
    //    invocation, etc
    //
    // 2. (mut receivedValue) – passing a value received from the caller
    //    that was originally derived from a literal, result from a helper
    //    invocation, etc
    //
    // This message is alright for the first case, but could be quite
    // confusing for the second case.


    (true && !(rawRef[_reference.UPDATE_REFERENCED_VALUE] !== undefined) && (0, _debug.assert)('You can only pass a path to mut', rawRef[_reference.UPDATE_REFERENCED_VALUE] !== undefined));
    return new MutReference(rawRef, vm.env);
  }
  /**
  @module ember
  */


  var ACTION = (0, _utils.symbol)('ACTION');
  /**
    The `{{action}}` helper provides a way to pass triggers for behavior (usually
    just a function) between components, and into components from controllers.
  
    ### Passing functions with the action helper
  
    There are three contexts an action helper can be used in. The first two
    contexts to discuss are attribute context, and Handlebars value context.
  
    ```handlebars
    {{! An example of attribute context }}
    <div onclick={{action "save"}}></div>
    {{! Examples of Handlebars value context }}
    {{input on-input=(action "save")}}
    {{yield (action "refreshData") andAnotherParam}}
    ```
  
    In these contexts,
    the helper is called a "closure action" helper. Its behavior is simple:
    If passed a function name, read that function off the `actions` property
    of the current context. Once that function is read, or immediately if a function was
    passed, create a closure over that function and any arguments.
    The resulting value of an action helper used this way is simply a function.
  
    For example, in the attribute context:
  
    ```handlebars
    {{! An example of attribute context }}
    <div onclick={{action "save"}}></div>
    ```
  
    The resulting template render logic would be:
  
    ```js
    var div = document.createElement('div');
    var actionFunction = (function(context){
      return function() {
        return context.actions.save.apply(context, arguments);
      };
    })(context);
    div.onclick = actionFunction;
    ```
  
    Thus when the div is clicked, the action on that context is called.
    Because the `actionFunction` is just a function, closure actions can be
    passed between components and still execute in the correct context.
  
    Here is an example action handler on a component:
  
    ```app/components/my-component.js
    import Component from '@glimmer/component';
    import { action } from '@ember/object';
  
    export default class extends Component {
      @action
      save() {
        this.model.save();
      }
    }
    ```
  
    Actions are always looked up on the `actions` property of the current context.
    This avoids collisions in the naming of common actions, such as `destroy`.
    Two options can be passed to the `action` helper when it is used in this way.
  
    * `target=someProperty` will look to `someProperty` instead of the current
      context for the `actions` hash. This can be useful when targeting a
      service for actions.
    * `value="target.value"` will read the path `target.value` off the first
      argument to the action when it is called and rewrite the first argument
      to be that value. This is useful when attaching actions to event listeners.
  
    ### Invoking an action
  
    Closure actions curry both their scope and any arguments. When invoked, any
    additional arguments are added to the already curried list.
    Actions should be invoked using the [sendAction](/ember/release/classes/Component/methods/sendAction?anchor=sendAction)
    method. The first argument to `sendAction` is the action to be called, and
    additional arguments are passed to the action function. This has interesting
    properties combined with currying of arguments. For example:
  
    ```app/components/update-name.js
    import Component from '@glimmer/component';
    import { action } from '@ember/object';
  
    export default class extends Component {
      @action
      setName(model, name) {
        model.set('name', name);
      }
    }
    ```
  
    ```app/components/update-name.hbs
    {{input on-input=(action (action 'setName' @model) value="target.value")}}
    ```
  
    The first argument (`@model`) was curried over, and the run-time argument (`event`)
    becomes a second argument. Action calls can be nested this way because each simply
    returns a function. Any function can be passed to the `{{action}}` helper, including
    other actions.
  
    Actions invoked with `sendAction` have the same currying behavior as demonstrated
    with `on-input` above. For example:
  
    ```app/components/my-input.js
    import Component from '@glimmer/component';
    import { action } from '@ember/object';
  
    export default class extends Component {
      @action
      setName(model, name) {
        model.set('name', name);
      }
    }
    ```
  
    ```handlebars
    <MyInput @submit={{action 'setName' @model}} />
    ```
  
    or
  
    ```handlebars
    {{my-input submit=(action 'setName' @model)}}
    ```
  
    ```app/components/my-component.js
    import Component from '@ember/component';
  
    export default Component.extend({
      click() {
        // Note that model is not passed, it was curried in the template
        this.sendAction('submit', 'bob');
      }
    });
    ```
  
    ### Attaching actions to DOM elements
  
    The third context of the `{{action}}` helper can be called "element space".
    For example:
  
    ```handlebars
    {{! An example of element space }}
    <div {{action "save"}}></div>
    ```
  
    Used this way, the `{{action}}` helper provides a useful shortcut for
    registering an HTML element in a template for a single DOM event and
    forwarding that interaction to the template's context (controller or component).
    If the context of a template is a controller, actions used this way will
    bubble to routes when the controller does not implement the specified action.
    Once an action hits a route, it will bubble through the route hierarchy.
  
    ### Event Propagation
  
    `{{action}}` helpers called in element space can control event bubbling. Note
    that the closure style actions cannot.
  
    Events triggered through the action helper will automatically have
    `.preventDefault()` called on them. You do not need to do so in your event
    handlers. If you need to allow event propagation (to handle file inputs for
    example) you can supply the `preventDefault=false` option to the `{{action}}` helper:
  
    ```handlebars
    <div {{action "sayHello" preventDefault=false}}>
      <input type="file" />
      <input type="checkbox" />
    </div>
    ```
  
    To disable bubbling, pass `bubbles=false` to the helper:
  
    ```handlebars
    <button {{action 'edit' post bubbles=false}}>Edit</button>
    ```
  
    To disable bubbling with closure style actions you must create your own
    wrapper helper that makes use of `event.stopPropagation()`:
  
    ```handlebars
    <div onclick={{disable-bubbling (action "sayHello")}}>Hello</div>
    ```
  
    ```app/helpers/disable-bubbling.js
    import { helper } from '@ember/component/helper';
  
    export function disableBubbling([action]) {
      return function(event) {
        event.stopPropagation();
        return action(event);
      };
    }
    export default helper(disableBubbling);
    ```
  
    If you need the default handler to trigger you should either register your
    own event handler, or use event methods on your view class. See
    ["Responding to Browser Events"](/ember/release/classes/Component)
    in the documentation for `Component` for more information.
  
    ### Specifying DOM event type
  
    `{{action}}` helpers called in element space can specify an event type.
    By default the `{{action}}` helper registers for DOM `click` events. You can
    supply an `on` option to the helper to specify a different DOM event name:
  
    ```handlebars
    <div {{action "anActionName" on="doubleClick"}}>
      click me
    </div>
    ```
  
    See ["Event Names"](/ember/release/classes/Component) for a list of
    acceptable DOM event names.
  
    ### Specifying whitelisted modifier keys
  
    `{{action}}` helpers called in element space can specify modifier keys.
    By default the `{{action}}` helper will ignore click events with pressed modifier
    keys. You can supply an `allowedKeys` option to specify which keys should not be ignored.
  
    ```handlebars
    <div {{action "anActionName" allowedKeys="alt"}}>
      click me
    </div>
    ```
  
    This way the action will fire when clicking with the alt key pressed down.
    Alternatively, supply "any" to the `allowedKeys` option to accept any combination of modifier keys.
  
    ```handlebars
    <div {{action "anActionName" allowedKeys="any"}}>
      click me with any key pressed
    </div>
    ```
  
    ### Specifying a Target
  
    A `target` option can be provided to the helper to change
    which object will receive the method call. This option must be a path
    to an object, accessible in the current context:
  
    ```app/templates/application.hbs
    <div {{action "anActionName" target=someService}}>
      click me
    </div>
    ```
  
    ```app/controllers/application.js
    import Controller from '@ember/controller';
    import { inject as service } from '@ember/service';
  
    export default class extends Controller {
      @service someService;
    }
    ```
  
    @method action
    @for Ember.Templates.helpers
    @public
  */

  function action(args, vm) {
    var {
      named,
      positional
    } = args;
    var capturedArgs = positional.capture(); // The first two argument slots are reserved.
    // pos[0] is the context (or `this`)
    // pos[1] is the action name or function
    // Anything else is an action argument.

    var [context, action, ...restArgs] = capturedArgs.references; // TODO: Is there a better way of doing this?

    var debugKey = action.propertyKey;
    var target = named.has('target') ? named.get('target') : context;
    var processArgs = makeArgsProcessor(named.has('value') && named.get('value'), restArgs);
    var fn;

    if (typeof action[INVOKE] === 'function') {
      fn = makeClosureAction(action, action, action[INVOKE], processArgs, debugKey);
    } else if ((0, _validator.isConstTagged)(target) && (0, _validator.isConstTagged)(action)) {
      fn = makeClosureAction(context.value(), target.value(), action.value(), processArgs, debugKey);
    } else {
      fn = makeDynamicClosureAction(context.value(), target, action, processArgs, debugKey);
    }

    fn[ACTION] = true;
    return new UnboundRootReference(fn, vm.env);
  }

  function NOOP$1(args) {
    return args;
  }

  function makeArgsProcessor(valuePathRef, actionArgsRef) {
    var mergeArgs;

    if (actionArgsRef.length > 0) {
      mergeArgs = args => {
        return actionArgsRef.map(ref => ref.value()).concat(args);
      };
    }

    var readValue;

    if (valuePathRef) {
      readValue = args => {
        var valuePath = valuePathRef.value();

        if (valuePath && args.length > 0) {
          args[0] = (0, _metal.get)(args[0], valuePath);
        }

        return args;
      };
    }

    if (mergeArgs && readValue) {
      return args => {
        return readValue(mergeArgs(args));
      };
    } else {
      return mergeArgs || readValue || NOOP$1;
    }
  }

  function makeDynamicClosureAction(context, targetRef, actionRef, processArgs, debugKey) {
    // We don't allow undefined/null values, so this creates a throw-away action to trigger the assertions
    if (true
    /* DEBUG */
    ) {
      makeClosureAction(context, targetRef.value(), actionRef.value(), processArgs, debugKey);
    }

    return (...args) => {
      return makeClosureAction(context, targetRef.value(), actionRef.value(), processArgs, debugKey)(...args);
    };
  }

  function makeClosureAction(context, target, action, processArgs, debugKey) {
    var self;
    var fn;
    (true && !(action !== undefined && action !== null) && (0, _debug.assert)(`Action passed is null or undefined in (action) from ${target}.`, action !== undefined && action !== null));

    if (typeof action[INVOKE] === 'function') {
      self = action;
      fn = action[INVOKE];
    } else {
      var typeofAction = typeof action;

      if (typeofAction === 'string') {
        self = target;
        fn = target.actions && target.actions[action];
        (true && !(fn) && (0, _debug.assert)(`An action named '${action}' was not found in ${target}`, fn));
      } else if (typeofAction === 'function') {
        self = context;
        fn = action;
      } else {
        // tslint:disable-next-line:max-line-length
        (true && !(false) && (0, _debug.assert)(`An action could not be made for \`${debugKey || action}\` in ${target}. Please confirm that you are using either a quoted action name (i.e. \`(action '${debugKey || 'myAction'}')\`) or a function available in ${target}.`, false));
      }
    }

    return (...args) => {
      var payload = {
        target: self,
        args,
        label: '@glimmer/closure-action'
      };
      return (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, () => {
        return (0, _runloop.join)(self, fn, ...processArgs(args));
      });
    };
  } // inputs needed by CurlyComponents (attrs and props, with mutable
  // cells, etc).


  function processComponentArgs(namedArgs) {
    var keys = namedArgs.names;
    var attrs = namedArgs.value();
    var props = Object.create(null);
    var args = Object.create(null);
    props[ARGS] = args;

    for (var i = 0; i < keys.length; i++) {
      var name = keys[i];
      var ref = namedArgs.get(name);
      var value = attrs[name];

      if (typeof value === 'function' && value[ACTION]) {
        attrs[name] = value;
      } else if (ref[_reference.UPDATE_REFERENCED_VALUE]) {
        attrs[name] = new MutableCell(ref, value);
      }

      args[name] = ref;
      props[name] = value;
    }

    props.attrs = attrs;
    return props;
  }

  var REF = (0, _utils.symbol)('REF');

  class MutableCell {
    constructor(ref, value) {
      this[_views.MUTABLE_CELL] = true;
      this[REF] = ref;
      this.value = value;
    }

    update(val) {
      this[REF][_reference.UPDATE_REFERENCED_VALUE](val);
    }

  }

  var __rest = undefined && undefined.__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function aliasIdToElementId(args, props) {
    if (args.named.has('id')) {
      // tslint:disable-next-line:max-line-length
      (true && !(!args.named.has('elementId')) && (0, _debug.assert)(`You cannot invoke a component with both 'id' and 'elementId' at the same time.`, !args.named.has('elementId')));
      props.elementId = props.id;
    }
  } // We must traverse the attributeBindings in reverse keeping track of
  // what has already been applied. This is essentially refining the concatenated
  // properties applying right to left.


  function applyAttributeBindings(attributeBindings, component, rootRef, operations, environment) {
    var seen = [];
    var i = attributeBindings.length - 1;

    while (i !== -1) {
      var binding = attributeBindings[i];
      var parsed = AttributeBinding.parse(binding);
      var attribute = parsed[1];

      if (seen.indexOf(attribute) === -1) {
        seen.push(attribute);
        AttributeBinding.install(component, rootRef, parsed, operations, environment);
      }

      i--;
    }

    if (seen.indexOf('id') === -1) {
      var id$$1 = component.elementId ? component.elementId : (0, _utils.guidFor)(component);
      operations.setAttribute('id', _runtime2.PrimitiveReference.create(id$$1), false, null);
    }

    if (_deprecatedFeatures.EMBER_COMPONENT_IS_VISIBLE && installIsVisibleBinding !== undefined && seen.indexOf('style') === -1) {
      installIsVisibleBinding(rootRef, operations, environment);
    }
  }

  var DEFAULT_LAYOUT = (0, _container.privatize)`template:components/-default`;
  var EMPTY_POSITIONAL_ARGS = [];
  (0, _debug.debugFreeze)(EMPTY_POSITIONAL_ARGS);

  class CurlyComponentManager extends AbstractManager {
    templateFor(component) {
      var {
        layout,
        layoutName
      } = component;
      var owner = (0, _owner.getOwner)(component);
      var factory;

      if (layout === undefined) {
        if (layoutName !== undefined) {
          var _factory = owner.lookup(`template:${layoutName}`);

          (true && !(_factory !== undefined) && (0, _debug.assert)(`Layout \`${layoutName}\` not found!`, _factory !== undefined));
          factory = _factory;
        } else {
          factory = owner.lookup(DEFAULT_LAYOUT);
        }
      } else if (isTemplateFactory(layout)) {
        factory = layout;
      } else {
        // we were provided an instance already
        return layout;
      }

      return factory(owner);
    }

    getJitStaticLayout(state, _resolver) {
      return (0, _util.unwrapTemplate)(state.template).asLayout();
    }

    getJitDynamicLayout(bucket) {
      var component = bucket.component;
      var template$$1 = this.templateFor(component);

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.environment.extra.debugRenderTree.setTemplate(bucket, template$$1);
      }

      return template$$1;
    }

    getTagName(state) {
      var {
        component,
        hasWrappedElement
      } = state;

      if (!hasWrappedElement) {
        return null;
      }

      return component && component.tagName || 'div';
    }

    getCapabilities(state) {
      return state.capabilities;
    }

    prepareArgs(state, args) {
      if (args.named.has('__ARGS__')) {
        var _a = args.named.capture().map,
            {
          __ARGS__
        } = _a,
            rest = __rest(_a, ["__ARGS__"]);

        var prepared = {
          positional: EMPTY_POSITIONAL_ARGS,
          named: (0, _polyfills.assign)({}, rest, __ARGS__.value())
        };
        return prepared;
      }

      var {
        positionalParams
      } = state.ComponentClass.class; // early exits

      if (positionalParams === undefined || positionalParams === null || args.positional.length === 0) {
        return null;
      }

      var named;

      if (typeof positionalParams === 'string') {
        (true && !(!args.named.has(positionalParams)) && (0, _debug.assert)(`You cannot specify positional parameters and the hash argument \`${positionalParams}\`.`, !args.named.has(positionalParams)));
        named = {
          [positionalParams]: args.positional.capture()
        };
        (0, _polyfills.assign)(named, args.named.capture().map);
      } else if (Array.isArray(positionalParams) && positionalParams.length > 0) {
        var count = Math.min(positionalParams.length, args.positional.length);
        named = {};
        (0, _polyfills.assign)(named, args.named.capture().map);

        for (var i = 0; i < count; i++) {
          var name = positionalParams[i];
          (true && !(!args.named.has(name)) && (0, _debug.assert)(`You cannot specify both a positional param (at position ${i}) and the hash argument \`${name}\`.`, !args.named.has(name)));
          named[name] = args.positional.at(i);
        }
      } else {
        return null;
      }

      return {
        positional: _util.EMPTY_ARRAY,
        named
      };
    }
    /*
     * This hook is responsible for actually instantiating the component instance.
     * It also is where we perform additional bookkeeping to support legacy
     * features like exposed by view mixins like ChildViewSupport, ActionSupport,
     * etc.
     */


    create(environment, state, args, dynamicScope, callerSelfRef, hasBlock) {
      // Get the nearest concrete component instance from the scope. "Virtual"
      // components will be skipped.
      var parentView = dynamicScope.view; // Get the Ember.Component subclass to instantiate for this component.

      var factory = state.ComponentClass; // Capture the arguments, which tells Glimmer to give us our own, stable
      // copy of the Arguments object that is safe to hold on to between renders.

      var capturedArgs = args.named.capture();
      var props = processComponentArgs(capturedArgs); // Alias `id` argument to `elementId` property on the component instance.

      aliasIdToElementId(args, props); // Set component instance's parentView property to point to nearest concrete
      // component.

      props.parentView = parentView; // Set whether this component was invoked with a block
      // (`{{#my-component}}{{/my-component}}`) or without one
      // (`{{my-component}}`).

      props[HAS_BLOCK] = hasBlock; // Save the current `this` context of the template as the component's
      // `_target`, so bubbled actions are routed to the right place.

      props._target = callerSelfRef.value(); // static layout asserts CurriedDefinition

      if (state.template) {
        props.layout = state.template;
      } // caller:
      // <FaIcon @name="bug" />
      //
      // callee:
      // <i class="fa-{{@name}}"></i>
      // Now that we've built up all of the properties to set on the component instance,
      // actually create it.


      var component = factory.create(props);
      var finalizer = (0, _instrumentation._instrumentStart)('render.component', initialRenderInstrumentDetails, component); // We become the new parentView for downstream components, so save our
      // component off on the dynamic scope.

      dynamicScope.view = component; // Unless we're the root component, we need to add ourselves to our parent
      // component's childViews array.

      if (parentView !== null && parentView !== undefined) {
        (0, _views.addChildView)(parentView, component);
      }

      component.trigger('didReceiveAttrs');
      var hasWrappedElement = component.tagName !== ''; // We usually do this in the `didCreateElement`, but that hook doesn't fire for tagless components

      if (!hasWrappedElement) {
        if (environment.isInteractive) {
          component.trigger('willRender');
        }

        component._transitionTo('hasElement');

        if (environment.isInteractive) {
          component.trigger('willInsertElement');
        }
      } // Track additional lifecycle metadata about this component in a state bucket.
      // Essentially we're saving off all the state we'll need in the future.


      var bucket = new ComponentStateBucket(environment, component, capturedArgs, finalizer, hasWrappedElement);

      if (args.named.has('class')) {
        bucket.classRef = args.named.get('class');
      }

      if (true
      /* DEBUG */
      ) {
        processComponentInitializationAssertions(component, props);
      }

      if (environment.isInteractive && hasWrappedElement) {
        component.trigger('willRender');
      }

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        environment.extra.debugRenderTree.create(bucket, {
          type: 'component',
          name: state.name,
          args: args.capture(),
          instance: component,
          template: state.template
        });
        (0, _runtime2.registerDestructor)(bucket, () => {
          environment.extra.debugRenderTree.willDestroy(bucket);
        });
      }

      return bucket;
    }

    getSelf({
      rootRef
    }) {
      return rootRef;
    }

    didCreateElement({
      component,
      classRef,
      environment,
      rootRef
    }, element, operations) {
      (0, _views.setViewElement)(component, element);
      (0, _views.setElementView)(element, component);
      var {
        attributeBindings,
        classNames,
        classNameBindings
      } = component;

      if (attributeBindings && attributeBindings.length) {
        applyAttributeBindings(attributeBindings, component, rootRef, operations, environment);
      } else {
        var id$$1 = component.elementId ? component.elementId : (0, _utils.guidFor)(component);
        operations.setAttribute('id', _runtime2.PrimitiveReference.create(id$$1), false, null);

        if (_deprecatedFeatures.EMBER_COMPONENT_IS_VISIBLE) {
          installIsVisibleBinding(rootRef, operations, environment);
        }
      }

      if (classRef) {
        var ref = new SimpleClassNameBindingReference(classRef, classRef['propertyKey']);
        operations.setAttribute('class', ref, false, null);
      }

      if (classNames && classNames.length) {
        classNames.forEach(name => {
          operations.setAttribute('class', _runtime2.PrimitiveReference.create(name), false, null);
        });
      }

      if (classNameBindings && classNameBindings.length) {
        classNameBindings.forEach(binding => {
          ClassNameBinding.install(element, rootRef, binding, operations);
        });
      }

      operations.setAttribute('class', _runtime2.PrimitiveReference.create('ember-view'), false, null);

      if ('ariaRole' in component) {
        operations.setAttribute('role', referenceForKey(rootRef, 'ariaRole'), false, null);
      }

      component._transitionTo('hasElement');

      if (environment.isInteractive) {
        component.trigger('willInsertElement');
      }
    }

    didRenderLayout(bucket, bounds) {
      bucket.component[BOUNDS] = bounds;
      bucket.finalize();

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.environment.extra.debugRenderTree.didRender(bucket, bounds);
      }
    }

    getTag({
      args,
      component
    }) {
      return args ? (0, _validator.combine)([args.tag, component[DIRTY_TAG]]) : component[DIRTY_TAG];
    }

    didCreate({
      component,
      environment
    }) {
      if (environment.isInteractive) {
        component._transitionTo('inDOM');

        component.trigger('didInsertElement');
        component.trigger('didRender');
      }
    }

    update(bucket) {
      var {
        component,
        args,
        argsRevision,
        environment
      } = bucket;

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        environment.extra.debugRenderTree.update(bucket);
      }

      bucket.finalizer = (0, _instrumentation._instrumentStart)('render.component', rerenderInstrumentDetails, component);

      if (args && !(0, _validator.validateTag)(args.tag, argsRevision)) {
        var props = processComponentArgs(args);
        bucket.argsRevision = (0, _validator.valueForTag)(args.tag);
        component[IS_DISPATCHING_ATTRS] = true;
        component.setProperties(props);
        component[IS_DISPATCHING_ATTRS] = false;
        component.trigger('didUpdateAttrs');
        component.trigger('didReceiveAttrs');
      }

      if (environment.isInteractive) {
        component.trigger('willUpdate');
        component.trigger('willRender');
      }
    }

    didUpdateLayout(bucket, bounds) {
      bucket.finalize();

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.environment.extra.debugRenderTree.didRender(bucket, bounds);
      }
    }

    didUpdate({
      component,
      environment
    }) {
      if (environment.isInteractive) {
        component.trigger('didUpdate');
        component.trigger('didRender');
      }
    }

    getDestroyable(bucket) {
      return bucket;
    }

  }

  function processComponentInitializationAssertions(component, props) {
    (true && !((() => {
      var {
        classNameBindings
      } = component;

      for (var i = 0; i < classNameBindings.length; i++) {
        var binding = classNameBindings[i];

        if (typeof binding !== 'string' || binding.length === 0) {
          return false;
        }
      }

      return true;
    })()) && (0, _debug.assert)(`classNameBindings must be non-empty strings: ${component}`, (() => {
      var {
        classNameBindings
      } = component;

      for (var i = 0; i < classNameBindings.length; i++) {
        var binding = classNameBindings[i];

        if (typeof binding !== 'string' || binding.length === 0) {
          return false;
        }
      }

      return true;
    })()));
    (true && !((() => {
      var {
        classNameBindings
      } = component;

      for (var i = 0; i < classNameBindings.length; i++) {
        var binding = classNameBindings[i];

        if (binding.split(' ').length > 1) {
          return false;
        }
      }

      return true;
    })()) && (0, _debug.assert)(`classNameBindings must not have spaces in them: ${component}`, (() => {
      var {
        classNameBindings
      } = component;

      for (var i = 0; i < classNameBindings.length; i++) {
        var binding = classNameBindings[i];

        if (binding.split(' ').length > 1) {
          return false;
        }
      }

      return true;
    })()));
    (true && !(component.tagName !== '' || !component.classNameBindings || component.classNameBindings.length === 0) && (0, _debug.assert)(`You cannot use \`classNameBindings\` on a tag-less component: ${component}`, component.tagName !== '' || !component.classNameBindings || component.classNameBindings.length === 0));
    (true && !(component.tagName !== '' || props.id === component.elementId || !component.elementId && component.elementId !== '') && (0, _debug.assert)(`You cannot use \`elementId\` on a tag-less component: ${component}`, component.tagName !== '' || props.id === component.elementId || !component.elementId && component.elementId !== ''));
    (true && !(component.tagName !== '' || !component.attributeBindings || component.attributeBindings.length === 0) && (0, _debug.assert)(`You cannot use \`attributeBindings\` on a tag-less component: ${component}`, component.tagName !== '' || !component.attributeBindings || component.attributeBindings.length === 0));
  }

  function initialRenderInstrumentDetails(component) {
    return component.instrumentDetails({
      initialRender: true
    });
  }

  function rerenderInstrumentDetails(component) {
    return component.instrumentDetails({
      initialRender: false
    });
  }

  var CURLY_CAPABILITIES = {
    dynamicLayout: true,
    dynamicTag: true,
    prepareArgs: true,
    createArgs: true,
    attributeHook: true,
    elementHook: true,
    createCaller: true,
    dynamicScope: true,
    updateHook: true,
    createInstance: true,
    wrapped: true,
    willDestroy: true
  };
  var CURLY_COMPONENT_MANAGER = new CurlyComponentManager();

  class CurlyComponentDefinition {
    constructor(name, ComponentClass, template$$1, args) {
      this.name = name;
      this.ComponentClass = ComponentClass;
      this.template = template$$1;
      this.args = args;
      this.manager = CURLY_COMPONENT_MANAGER;
      this.state = {
        name,
        ComponentClass,
        template: template$$1,
        capabilities: CURLY_CAPABILITIES
      };
    }

  }

  class RootComponentManager extends CurlyComponentManager {
    constructor(component) {
      super();
      this.component = component;
    }

    getJitStaticLayout(_state) {
      var template = this.templateFor(this.component);
      return (0, _util.unwrapTemplate)(template).asWrappedLayout();
    }

    create(environment, state, _args, dynamicScope) {
      var component = this.component;
      var finalizer = (0, _instrumentation._instrumentStart)('render.component', initialRenderInstrumentDetails, component);
      dynamicScope.view = component;
      var hasWrappedElement = component.tagName !== ''; // We usually do this in the `didCreateElement`, but that hook doesn't fire for tagless components

      if (!hasWrappedElement) {
        if (environment.isInteractive) {
          component.trigger('willRender');
        }

        component._transitionTo('hasElement');

        if (environment.isInteractive) {
          component.trigger('willInsertElement');
        }
      }

      if (true
      /* DEBUG */
      ) {
        processComponentInitializationAssertions(component, {});
      }

      var bucket = new ComponentStateBucket(environment, component, null, finalizer, hasWrappedElement);

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        environment.extra.debugRenderTree.create(bucket, {
          type: 'component',
          name: state.name,
          args: _runtime2.EMPTY_ARGS,
          instance: component,
          template: state.template
        });
      }

      return bucket;
    }

  } // ROOT is the top-level template it has nothing but one yield.
  // it is supposed to have a dummy element


  var ROOT_CAPABILITIES = {
    dynamicLayout: false,
    dynamicTag: true,
    prepareArgs: false,
    createArgs: false,
    attributeHook: true,
    elementHook: true,
    createCaller: true,
    dynamicScope: true,
    updateHook: true,
    createInstance: true,
    wrapped: true,
    willDestroy: false
  };

  class RootComponentDefinition {
    constructor(component) {
      this.component = component;
      var manager = new RootComponentManager(component);
      this.manager = manager;
      var factory = (0, _container.getFactoryFor)(component);
      this.state = {
        name: factory.fullName.slice(10),
        capabilities: ROOT_CAPABILITIES,
        ComponentClass: factory
      };
    }

    getTag({
      component
    }) {
      return component[DIRTY_TAG];
    }

  }
  /* globals module, URL */


  var nodeURL;
  var parsingNode;

  function installProtocolForURL(environment) {
    var protocol;

    if (_browserEnvironment.hasDOM) {
      protocol = browserProtocolForURL.call(environment, 'foobar:baz');
    } // Test to see if our DOM implementation parses
    // and normalizes URLs.


    if (protocol === 'foobar:') {
      // Swap in the method that doesn't do this test now that
      // we know it works.
      environment.protocolForURL = browserProtocolForURL;
    } else if (typeof URL === 'object') {
      // URL globally provided, likely from FastBoot's sandbox
      nodeURL = URL;
      environment.protocolForURL = nodeProtocolForURL;
    } else if (typeof module !== 'undefined' && typeof module.require === 'function') {
      // Otherwise, we need to fall back to our own URL parsing.
      // Global `require` is shadowed by Ember's loader so we have to use the fully
      // qualified `module.require`.
      // tslint:disable-next-line:no-require-imports
      nodeURL = module.require('url');
      environment.protocolForURL = nodeProtocolForURL;
    } else {
      throw new Error('Could not find valid URL parsing mechanism for URL Sanitization');
    }
  }

  function browserProtocolForURL(url) {
    if (!parsingNode) {
      parsingNode = document.createElement('a');
    }

    parsingNode.href = url;
    return parsingNode.protocol;
  }

  function nodeProtocolForURL(url) {
    var protocol = null;

    if (typeof url === 'string') {
      protocol = nodeURL.parse(url).protocol;
    }

    return protocol === null ? ':' : protocol;
  }

  var GUID = 0;

  function isPathNode(node) {
    return node.type === 'root' || node.type === 'argument' || node.type === 'property' || node.type === 'iterator';
  }

  class Ref {
    constructor(value) {
      this.id = GUID++;
      this.value = value;
    }

    get() {
      return this.value;
    }

    release() {
      (true && !(this.value !== null) && (0, _debug.assert)('BUG: double release?', this.value !== null));
      this.value = null;
    }

    toString() {
      var label = `Ref ${this.id}`;

      if (this.value === null) {
        return `${label} (released)`;
      } else {
        try {
          return `${label}: ${this.value}`;
        } catch (_a) {
          return label;
        }
      }
    }

  }

  var _repeat = String.prototype.repeat || function (count) {
    return new Array(count + 1).join(this);
  };

  function repeatString(str, count) {
    return _repeat.call(str, count);
  }

  class DebugRenderTree {
    constructor() {
      this.stack = new _util.Stack();
      this.refs = new WeakMap();
      this.roots = new Set();
      this.nodes = new WeakMap();
      this.pathNodes = new WeakMap();
    }

    begin() {
      this.reset();
    }

    create(state, node) {
      var internalNode = (0, _polyfills.assign)({}, node, {
        bounds: null,
        refs: new Set(),
        paths: new Set()
      });
      this.nodes.set(state, internalNode);
      this.appendChild(internalNode, state);
      this.enter(state);
    }

    update(state) {
      this.enter(state);
    } // for dynamic layouts


    setTemplate(state, template) {
      this.nodeFor(state).template = template;
    }

    didRender(state, bounds) {
      (true && !(this.stack.current === state) && (0, _debug.assert)(`BUG: expecting ${this.stack.current}, got ${state}`, this.stack.current === state));
      this.nodeFor(state).bounds = bounds;
      this.exit();
    }

    willDestroy(state) {
      (0, _util.expect)(this.refs.get(state), 'BUG: missing ref').release();
    }

    commit() {
      this.reset();
    }

    capture() {
      return this.captureRefs(this.roots);
    }

    createPath(pathRef, name, type, parentRef) {
      (true && !(!this.pathNodes.has(pathRef)) && (0, _debug.assert)('BUG: Attempted to register a path that had already been registered', !this.pathNodes.has(pathRef)));
      var {
        current
      } = this.stack;

      if (current === null) {
        // Not currently in a rendering context, don't register the node
        return;
      }

      var currentNode = (0, _util.expect)(this.nodes.get(current), 'BUG: Attempted to create a path, but there is no current render node');
      var parent;

      if (parentRef === null) {
        parent = currentNode;
      } else {
        var {
          named
        } = currentNode.args;
        var refIndex = named.references.indexOf(parentRef);

        if (refIndex !== -1) {
          parent = {
            parent: currentNode,
            type: 'argument',
            name: `@${named.names[refIndex]}`,
            paths: new Set()
          };
        } else if (this.pathNodes.has(parentRef)) {
          parent = this.pathNodes.get(parentRef);
        } else {
          // Some RootReferences get created before a component context has been
          // setup (root, curly). This is mainly because the debugRenderTree is
          // tied to the manager hooks, and not built into the VM directly. In
          // these cases, we setup the path lazily when the first property is
          // accessed.
          this.createPath(parentRef, 'this', 'root', null);
          parent = this.pathNodes.get(parentRef);
        }
      }

      var pathNode = {
        name,
        type,
        parent,
        paths: new Set()
      };
      parent.paths.add(pathNode);
      this.pathNodes.set(pathRef, pathNode);
    }

    logRenderStackForPath(pathRef) {
      var node = (0, _util.expect)(this.pathNodes.get(pathRef), 'BUG: Attempted to create a log for a path reference, but no node exist for that reference');
      var pathParts = [];

      while (node !== undefined && isPathNode(node)) {
        if (node.type === 'iterator') {
          // Iterator items are a combination of their own name (the key of the item) and
          // their parent, the iterable itself.
          var part = `${node.parent.name}[${node.name}]`;
          pathParts.push(part);
          node = node.parent;
        } else {
          pathParts.unshift(node.name);
        }

        node = node.parent;
      }

      var messageParts = [pathParts.join('.')];

      while (node !== undefined) {
        if (node.type === 'outlet' || node.name === '-top-level') {
          node = node.parent;
          continue;
        }

        messageParts.unshift(node.name);
        node = node.parent;
      }

      return messageParts.map((part, index) => `${repeatString(' ', index * 2)}${part}`).join('\n');
    }

    reset() {
      if (this.stack.size !== 0) {
        // We probably encountered an error during the rendering loop. This will
        // likely trigger undefined behavior and memory leaks as the error left
        // things in an inconsistent state. It is recommended that the user
        // refresh the page.
        // TODO: We could warn here? But this happens all the time in our tests?
        while (!this.stack.isEmpty()) {
          this.stack.pop();
        }
      }
    }

    enter(state) {
      this.stack.push(state);
    }

    exit() {
      (true && !(this.stack.size !== 0) && (0, _debug.assert)('BUG: unbalanced pop', this.stack.size !== 0));
      this.stack.pop();
    }

    nodeFor(state) {
      return (0, _util.expect)(this.nodes.get(state), 'BUG: missing node');
    }

    appendChild(node, state) {
      (true && !(!this.refs.has(state)) && (0, _debug.assert)('BUG: child already appended', !this.refs.has(state)));
      var parent = this.stack.current;
      var ref = new Ref(state);
      this.refs.set(state, ref);

      if (parent) {
        var parentNode = this.nodeFor(parent);
        parentNode.refs.add(ref);
        node.parent = parentNode;
      } else {
        this.roots.add(ref);
      }
    }

    captureRefs(refs) {
      var captured = [];
      refs.forEach(ref => {
        var state = ref.get();

        if (state) {
          captured.push(this.captureNode(`render-node:${ref.id}`, state));
        } else {
          refs.delete(ref);
        }
      });
      return captured;
    }

    captureNode(id, state) {
      var node = this.nodeFor(state);
      var {
        type,
        name,
        args,
        instance,
        refs
      } = node;
      var template = this.captureTemplate(node);
      var bounds = this.captureBounds(node);
      var children = this.captureRefs(refs);
      return {
        id,
        type,
        name,
        args: args.value(),
        instance,
        template,
        bounds,
        children
      };
    }

    captureTemplate({
      template
    }) {
      return template && (0, _util.unwrapTemplate)(template).referrer.moduleName || null;
    }

    captureBounds(node) {
      var bounds = (0, _util.expect)(node.bounds, 'BUG: missing bounds');
      var parentElement = bounds.parentElement();
      var firstNode = bounds.firstNode();
      var lastNode = bounds.lastNode();
      return {
        parentElement,
        firstNode,
        lastNode
      };
    }

  }
  /**
  @module ember
  */

  /**
    The `{{#each}}` helper loops over elements in a collection. It is an extension
    of the base Handlebars `{{#each}}` helper.
  
    The default behavior of `{{#each}}` is to yield its inner block once for every
    item in an array passing the item as the first block parameter.
  
    Assuming the `@developers` argument contains this array:
  
    ```javascript
    [{ name: 'Yehuda' },{ name: 'Tom' }, { name: 'Paul' }];
    ```
  
    ```handlebars
    <ul>
      {{#each @developers as |person|}}
        <li>Hello, {{person.name}}!</li>
      {{/each}}
    </ul>
    ```
  
    The same rules apply to arrays of primitives.
  
    ```javascript
    ['Yehuda', 'Tom', 'Paul']
    ```
  
    ```handlebars
    <ul>
      {{#each @developerNames as |name|}}
        <li>Hello, {{name}}!</li>
      {{/each}}
    </ul>
    ```
  
    During iteration, the index of each item in the array is provided as a second block
    parameter.
  
    ```handlebars
    <ul>
      {{#each @developers as |person index|}}
        <li>Hello, {{person.name}}! You're number {{index}} in line</li>
      {{/each}}
    </ul>
    ```
  
    ### Specifying Keys
  
    In order to improve rendering speed, Ember will try to reuse the DOM elements
    where possible. Specifically, if the same item is present in the array both
    before and after the change, its DOM output will be reused.
  
    The `key` option is used to tell Ember how to determine if the items in the
    array being iterated over with `{{#each}}` has changed between renders. By
    default the item's object identity is used.
  
    This is usually sufficient, so in most cases, the `key` option is simply not
    needed. However, in some rare cases, the objects' identities may change even
    though they represent the same underlying data.
  
    For example:
  
    ```javascript
    people.map(person => {
      return { ...person, type: 'developer' };
    });
    ```
  
    In this case, each time the `people` array is `map`-ed over, it will produce
    an new array with completely different objects between renders. In these cases,
    you can help Ember determine how these objects related to each other with the
    `key` option:
  
    ```handlebars
    <ul>
      {{#each @developers key="name" as |person|}}
        <li>Hello, {{person.name}}!</li>
      {{/each}}
    </ul>
    ```
  
    By doing so, Ember will use the value of the property specified (`person.name`
    in the example) to find a "match" from the previous render. That is, if Ember
    has previously seen an object from the `@developers` array with a matching
    name, its DOM elements will be re-used.
  
    There are two special values for `key`:
  
      * `@index` - The index of the item in the array.
      * `@identity` - The item in the array itself.
  
    ### {{else}} condition
  
    `{{#each}}` can have a matching `{{else}}`. The contents of this block will render
    if the collection is empty.
  
    ```handlebars
    <ul>
      {{#each @developers as |person|}}
        <li>{{person.name}} is available!</li>
      {{else}}
        <li>Sorry, nobody is available for this task.</li>
      {{/each}}
    </ul>
    ```
  
    @method each
    @for Ember.Templates.helpers
    @public
   */

  /**
    The `{{each-in}}` helper loops over properties on an object.
  
    For example, given this component definition:
  
    ```app/components/developer-details.js
    import Component from '@glimmer/component';
    import { tracked } from '@glimmer/tracking';
  
    export default class extends Component {
      @tracked developer = {
        "name": "Shelly Sails",
        "age": 42
      };
    }
    ```
  
    This template would display all properties on the `developer`
    object in a list:
  
    ```app/components/developer-details.hbs
    <ul>
      {{#each-in this.developer as |key value|}}
        <li>{{key}}: {{value}}</li>
      {{/each-in}}
    </ul>
    ```
  
    Outputting their name and age.
  
    @method each-in
    @for Ember.Templates.helpers
    @public
    @since 2.1.0
  */


  class EachInReference {
    constructor(inner) {
      this.inner = inner;
      this.valueTag = (0, _validator.createUpdatableTag)();
      this.tag = (0, _validator.combine)([inner.tag, this.valueTag]);
    }

    value() {
      var iterable = this.inner.value();
      var tag = (0, _metal.tagForObject)(iterable);

      if ((0, _utils.isProxy)(iterable)) {
        // this is because the each-in doesn't actually get(proxy, 'key') but bypasses it
        // and the proxy's tag is lazy updated on access
        iterable = (0, _runtime._contentFor)(iterable);
      }

      (0, _validator.updateTag)(this.valueTag, tag);
      return new EachInWrapper(iterable);
    }

    get(key) {
      return this.inner.get(key);
    }

  }

  class EachInWrapper {
    constructor(inner) {
      this.inner = inner;
    }

  }

  function eachIn(args) {
    return new EachInReference(args.positional.at(0));
  }

  function toIterator(iterable) {
    if (iterable instanceof EachInWrapper) {
      return toEachInIterator(iterable.inner);
    } else {
      return toEachIterator(iterable);
    }
  }

  function toEachInIterator(iterable) {
    if (!isIndexable(iterable)) {
      return null;
    }

    if (Array.isArray(iterable) || (0, _utils.isEmberArray)(iterable)) {
      return ObjectIterator.fromIndexable(iterable);
    } else if (_utils.HAS_NATIVE_SYMBOL && isNativeIterable(iterable)) {
      return MapLikeNativeIterator.from(iterable);
    } else if (hasForEach(iterable)) {
      return ObjectIterator.fromForEachable(iterable);
    } else {
      return ObjectIterator.fromIndexable(iterable);
    }
  }

  function toEachIterator(iterable) {
    if (!(0, _utils.isObject)(iterable)) {
      return null;
    }

    if (Array.isArray(iterable)) {
      return ArrayIterator.from(iterable);
    } else if ((0, _utils.isEmberArray)(iterable)) {
      return EmberArrayIterator.from(iterable);
    } else if (_utils.HAS_NATIVE_SYMBOL && isNativeIterable(iterable)) {
      return ArrayLikeNativeIterator.from(iterable);
    } else if (hasForEach(iterable)) {
      return ArrayIterator.fromForEachable(iterable);
    } else {
      return null;
    }
  }

  class BoundedIterator {
    constructor(length) {
      this.length = length;
      this.position = 0;
    }

    isEmpty() {
      return false;
    }

    memoFor(position) {
      return position;
    }

    next() {
      var {
        length,
        position
      } = this;

      if (position >= length) {
        return null;
      }

      var value = this.valueFor(position);
      var memo = this.memoFor(position);
      this.position++;
      return {
        value,
        memo
      };
    }

  }

  class ArrayIterator extends BoundedIterator {
    constructor(array) {
      super(array.length);
      this.array = array;
    }

    static from(iterable) {
      return iterable.length > 0 ? new this(iterable) : null;
    }

    static fromForEachable(object) {
      var array = [];
      object.forEach(item => array.push(item));
      return this.from(array);
    }

    valueFor(position) {
      return this.array[position];
    }

  }

  class EmberArrayIterator extends BoundedIterator {
    constructor(array) {
      super(array.length);
      this.array = array;
    }

    static from(iterable) {
      return iterable.length > 0 ? new this(iterable) : null;
    }

    valueFor(position) {
      return (0, _metal.objectAt)(this.array, position);
    }

  }

  class ObjectIterator extends BoundedIterator {
    constructor(keys, values) {
      super(values.length);
      this.keys = keys;
      this.values = values;
    }

    static fromIndexable(obj) {
      var keys = Object.keys(obj);
      var {
        length
      } = keys;

      if (length === 0) {
        return null;
      } else {
        var values = [];

        for (var i = 0; i < length; i++) {
          var value = void 0;
          var key = keys[i];
          value = obj[key]; // Add the tag of the returned value if it is an array, since arrays
          // should always cause updates if they are consumed and then changed

          if ((0, _validator.isTracking)()) {
            (0, _validator.consumeTag)((0, _validator.tagFor)(obj, key));

            if (Array.isArray(value)) {
              (0, _validator.consumeTag)((0, _validator.tagFor)(value, '[]'));
            }
          }

          values.push(value);
        }

        return new this(keys, values);
      }
    }

    static fromForEachable(obj) {
      var keys = [];
      var values = [];
      var length = 0;
      var isMapLike = false; // Not using an arrow function here so we can get an accurate `arguments`

      obj.forEach(function (value, key) {
        isMapLike = isMapLike || arguments.length >= 2;

        if (isMapLike) {
          keys.push(key);
        }

        values.push(value);
        length++;
      });

      if (length === 0) {
        return null;
      } else if (isMapLike) {
        return new this(keys, values);
      } else {
        return new ArrayIterator(values);
      }
    }

    valueFor(position) {
      return this.values[position];
    }

    memoFor(position) {
      return this.keys[position];
    }

  }

  class NativeIterator {
    constructor(iterable, result) {
      this.iterable = iterable;
      this.result = result;
      this.position = 0;
    }

    static from(iterable) {
      var iterator = iterable[Symbol.iterator]();
      var result = iterator.next();
      var {
        done
      } = result;

      if (done) {
        return null;
      } else {
        return new this(iterator, result);
      }
    }

    isEmpty() {
      return false;
    }

    next() {
      var {
        iterable,
        result,
        position
      } = this;

      if (result.done) {
        return null;
      }

      var value = this.valueFor(result, position);
      var memo = this.memoFor(result, position);
      this.position++;
      this.result = iterable.next();
      return {
        value,
        memo
      };
    }

  }

  class ArrayLikeNativeIterator extends NativeIterator {
    valueFor(result) {
      return result.value;
    }

    memoFor(_result, position) {
      return position;
    }

  }

  class MapLikeNativeIterator extends NativeIterator {
    valueFor(result) {
      return result.value[1];
    }

    memoFor(result) {
      return result.value[0];
    }

  }

  function hasForEach(value) {
    return typeof value['forEach'] === 'function';
  }

  function isNativeIterable(value) {
    return typeof value[Symbol.iterator] === 'function';
  }

  function isIndexable(value) {
    return value !== null && (typeof value === 'object' || typeof value === 'function');
  }

  function toBool(predicate) {
    if ((0, _utils.isProxy)(predicate)) {
      (0, _validator.consumeTag)((0, _metal.tagForProperty)(predicate, 'content'));
      return Boolean((0, _metal.get)(predicate, 'isTruthy'));
    } else if ((0, _runtime.isArray)(predicate)) {
      (0, _validator.consumeTag)((0, _metal.tagForProperty)(predicate, '[]'));
      return predicate.length !== 0;
    } else {
      return Boolean(predicate);
    }
  } // Setup global environment
  // Autotracking


  (0, _validator.setPropertyDidChange)(() => _runloop.backburner.ensureInstance());

  if (true
  /* DEBUG */
  ) {
    (0, _validator.setAutotrackingTransactionEnv)({
      assert(message) {
        (true && !(false) && (0, _debug.assert)(message, false));
      },

      deprecate(message) {
        (true && !(false) && (0, _debug.deprecate)(message, false, {
          id: 'autotracking.mutation-after-consumption',
          until: '4.0.0'
        }));
      },

      debugMessage(obj, keyName) {
        var dirtyString = keyName ? `\`${keyName}\` on \`${(0, _utils.getDebugName)(obj)}\`` : `\`${(0, _utils.getDebugName)(obj)}\``;
        return `You attempted to update ${dirtyString}, but it had already been used previously in the same computation.  Attempting to update a value after using it in a computation can cause logical errors, infinite revalidation bugs, and performance issues, and is not supported.`;
      }

    });
  } // Destruction


  (0, _runtime2.setScheduleDestroy)((destroyable, destructor) => {
    (0, _runloop.schedule)('actions', null, destructor, destroyable);
  });
  (0, _runtime2.setScheduleDestroyed)(finalizeDestructor => {
    (0, _runloop.schedule)('destroy', null, finalizeDestructor);
  });

  class EmberEnvironmentExtra {
    constructor(owner) {
      this.owner = owner;

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        this._debugRenderTree = new DebugRenderTree();
      }
    }

    get debugRenderTree() {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        return this._debugRenderTree;
      } else {
        throw new Error("Can't access debug render tree outside of the inspector (_DEBUG_RENDER_TREE flag is disabled)");
      }
    }

    begin() {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        this.debugRenderTree.begin();
      }
    }

    commit() {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        this.debugRenderTree.commit();
      }
    }

  }

  class EmberEnvironmentDelegate {
    constructor(owner, isInteractive) {
      this.toBool = toBool;
      this.toIterator = toIterator;
      this.getProp = _metal._getProp;
      this.getPath = _metal.get;
      this.setPath = _metal.set;
      this.extra = new EmberEnvironmentExtra(owner);
      this.isInteractive = isInteractive;
      installProtocolForURL(this);
    } // this gets clobbered by installPlatformSpecificProtocolForURL
    // it really should just delegate to a platform specific injection


    protocolForURL(s) {
      return s;
    }

    getTemplatePathDebugContext(pathRef) {
      var stack = this.extra.debugRenderTree.logRenderStackForPath(pathRef);
      return `While rendering:\n\n${stack}`;
    }

    setTemplatePathDebugContext(pathRef, desc, parentRef) {
      var type = 'root';

      if (pathRef instanceof _reference.IterationItemReference) {
        type = 'iterator';
      } else if (pathRef instanceof _reference.PropertyReference) {
        type = 'property';
      }

      this.extra.debugRenderTree.createPath(pathRef, desc, type, parentRef);
    }

    onTransactionBegin() {
      this.extra.begin();
    }

    onTransactionCommit() {
      this.extra.commit();
    }

  }

  if (true
  /* DEBUG */
  ) {
    class StyleAttributeManager extends _runtime2.SimpleDynamicAttribute {
      set(dom, value, env) {
        (true && (0, _debug.warn)((0, _views.constructStyleDeprecationMessage)(value), (() => {
          if (value === null || value === undefined || isHTMLSafe(value)) {
            return true;
          }

          return false;
        })(), {
          id: 'ember-htmlbars.style-xss-warning'
        }));
        super.set(dom, value, env);
      }

      update(value, env) {
        (true && (0, _debug.warn)((0, _views.constructStyleDeprecationMessage)(value), (() => {
          if (value === null || value === undefined || isHTMLSafe(value)) {
            return true;
          }

          return false;
        })(), {
          id: 'ember-htmlbars.style-xss-warning'
        }));
        super.update(value, env);
      }

    }

    EmberEnvironmentDelegate.prototype.attributeFor = function (element, attribute, isTrusting, namespace) {
      if (attribute === 'style' && !isTrusting) {
        return new StyleAttributeManager({
          element,
          name: attribute,
          namespace
        });
      }

      return (0, _runtime2.dynamicAttribute)(element, attribute, namespace);
    };
  }

  var CAPABILITIES$1 = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: true,
    attributeHook: false,
    elementHook: false,
    createCaller: false,
    dynamicScope: true,
    updateHook: true,
    createInstance: true,
    wrapped: false,
    willDestroy: false
  };

  function capabilities(managerAPI, options = {}) {
    (true && !(managerAPI === '3.4' || managerAPI === '3.13') && (0, _debug.assert)('Invalid component manager compatibility specified', managerAPI === '3.4' || managerAPI === '3.13'));
    var updateHook = true;
    {
      updateHook = managerAPI === '3.13' ? Boolean(options.updateHook) : true;
    }
    return {
      asyncLifeCycleCallbacks: Boolean(options.asyncLifecycleCallbacks),
      destructor: Boolean(options.destructor),
      updateHook
    };
  }

  function hasAsyncLifeCycleCallbacks(delegate) {
    return delegate.capabilities.asyncLifeCycleCallbacks;
  }

  function hasUpdateHook(delegate) {
    return delegate.capabilities.updateHook;
  }

  function hasAsyncUpdateHook(delegate) {
    return hasAsyncLifeCycleCallbacks(delegate) && hasUpdateHook(delegate);
  }

  function hasDestructors(delegate) {
    return delegate.capabilities.destructor;
  }
  /**
    The CustomComponentManager allows addons to provide custom component
    implementations that integrate seamlessly into Ember. This is accomplished
    through a delegate, registered with the custom component manager, which
    implements a set of hooks that determine component behavior.
  
    To create a custom component manager, instantiate a new CustomComponentManager
    class and pass the delegate as the first argument:
  
    ```js
    let manager = new CustomComponentManager({
      // ...delegate implementation...
    });
    ```
  
    ## Delegate Hooks
  
    Throughout the lifecycle of a component, the component manager will invoke
    delegate hooks that are responsible for surfacing those lifecycle changes to
    the end developer.
  
    * `create()` - invoked when a new instance of a component should be created
    * `update()` - invoked when the arguments passed to a component change
    * `getContext()` - returns the object that should be
  */


  class CustomComponentManager extends AbstractManager {
    create(env, definition, args) {
      var {
        delegate
      } = definition;
      var capturedArgs = args.capture();
      var namedArgs = capturedArgs.named;
      var value;
      var namedArgsProxy = {};
      {
        var getTag = key => {
          return namedArgs.get(key).tag;
        };

        if (_utils.HAS_NATIVE_PROXY) {
          var handler = {
            get(_target, prop) {
              if (namedArgs.has(prop)) {
                var ref = namedArgs.get(prop);
                (0, _validator.consumeTag)(ref.tag);
                return ref.value();
              } else if (prop === _metal.CUSTOM_TAG_FOR) {
                return getTag;
              }
            },

            has(_target, prop) {
              return namedArgs.has(prop);
            },

            ownKeys(_target) {
              return namedArgs.names;
            },

            getOwnPropertyDescriptor(_target, prop) {
              (true && !(namedArgs.has(prop)) && (0, _debug.assert)('args proxies do not have real property descriptors, so you should never need to call getOwnPropertyDescriptor yourself. This code exists for enumerability, such as in for-in loops and Object.keys()', namedArgs.has(prop)));
              return {
                enumerable: true,
                configurable: true
              };
            }

          };

          if (true
          /* DEBUG */
          ) {
            handler.set = function (_target, prop) {
              (true && !(false) && (0, _debug.assert)(`You attempted to set ${definition.ComponentClass.class}#${String(prop)} on a components arguments. Component arguments are immutable and cannot be updated directly, they always represent the values that are passed to your component. If you want to set default values, you should use a getter instead`));
              return false;
            };
          }

          namedArgsProxy = new Proxy(namedArgsProxy, handler);
        } else {
          Object.defineProperty(namedArgsProxy, _metal.CUSTOM_TAG_FOR, {
            configurable: false,
            enumerable: false,
            value: getTag
          });
          namedArgs.names.forEach(name => {
            Object.defineProperty(namedArgsProxy, name, {
              enumerable: true,
              configurable: true,

              get() {
                var ref = namedArgs.get(name);
                (0, _validator.consumeTag)(ref.tag);
                return ref.value();
              }

            });
          });
        }

        value = {
          named: namedArgsProxy,
          positional: capturedArgs.positional.value()
        };
      }
      var component = delegate.createComponent(definition.ComponentClass.class, value);
      var bucket = new CustomComponentState(delegate, component, capturedArgs, env, namedArgsProxy);

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        env.extra.debugRenderTree.create(bucket, {
          type: 'component',
          name: definition.name,
          args: args.capture(),
          instance: component,
          template: definition.template
        });
        (0, _runtime2.registerDestructor)(bucket, () => {
          env.extra.debugRenderTree.willDestroy(bucket);
        });
      }

      return bucket;
    }

    update(bucket) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.env.extra.debugRenderTree.update(bucket);
      }

      var {
        delegate,
        component,
        args,
        namedArgsProxy
      } = bucket;
      var value;
      {
        value = {
          named: namedArgsProxy,
          positional: args.positional.value()
        };
      }

      if (hasUpdateHook(delegate)) {
        delegate.updateComponent(component, value);
      }
    }

    didCreate({
      delegate,
      component
    }) {
      if (hasAsyncLifeCycleCallbacks(delegate)) {
        delegate.didCreateComponent(component);
      }
    }

    didUpdate({
      delegate,
      component
    }) {
      if (hasAsyncUpdateHook(delegate)) {
        delegate.didUpdateComponent(component);
      }
    }

    getContext({
      delegate,
      component
    }) {
      delegate.getContext(component);
    }

    getSelf({
      env,
      delegate,
      component
    }) {
      return new _reference.ComponentRootReference(delegate.getContext(component), env);
    }

    getDestroyable(bucket) {
      return bucket;
    }

    getCapabilities({
      delegate
    }) {
      return (0, _polyfills.assign)({}, CAPABILITIES$1, {
        updateHook: _environment2.ENV._DEBUG_RENDER_TREE || delegate.capabilities.updateHook
      });
    }

    getTag({
      args
    }) {
      if ((0, _validator.isConstTagged)(args)) {
        // returning a const tag skips the update hook (VM BUG?)
        return (0, _validator.createTag)();
      } else {
        return args.tag;
      }
    }

    didRenderLayout(bucket, bounds) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.env.extra.debugRenderTree.didRender(bucket, bounds);
      }
    }

    didUpdateLayout(bucket, bounds) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.env.extra.debugRenderTree.didRender(bucket, bounds);
      }
    }

    getJitStaticLayout(state) {
      return (0, _util.unwrapTemplate)(state.template).asLayout();
    }

  }

  var CUSTOM_COMPONENT_MANAGER = new CustomComponentManager();
  /**
   * Stores internal state about a component instance after it's been created.
   */

  class CustomComponentState {
    constructor(delegate, component, args, env, namedArgsProxy) {
      this.delegate = delegate;
      this.component = component;
      this.args = args;
      this.env = env;
      this.namedArgsProxy = namedArgsProxy;

      if (hasDestructors(delegate)) {
        (0, _runtime2.registerDestructor)(this, () => delegate.destroyComponent(component));
      }
    }

  }

  class CustomManagerDefinition {
    constructor(name, ComponentClass, delegate, template) {
      this.name = name;
      this.ComponentClass = ComponentClass;
      this.delegate = delegate;
      this.template = template;
      this.manager = CUSTOM_COMPONENT_MANAGER;
      this.state = {
        name,
        ComponentClass,
        template,
        delegate
      };
    }

  }

  class InternalComponentDefinition {
    constructor(manager, ComponentClass, layout) {
      this.manager = manager;
      this.state = {
        ComponentClass,
        layout
      };
    }

  }

  class InternalManager extends AbstractManager {
    constructor(owner) {
      super();
      this.owner = owner;
    }

    getJitStaticLayout({
      layout: template
    }) {
      return (0, _util.unwrapTemplate)(template).asLayout();
    }

  }

  var CAPABILITIES$2 = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: _environment2.ENV._DEBUG_RENDER_TREE,
    attributeHook: false,
    elementHook: false,
    createCaller: false,
    dynamicScope: false,
    updateHook: _environment2.ENV._DEBUG_RENDER_TREE,
    createInstance: true,
    wrapped: false,
    willDestroy: false
  };

  class TemplateOnlyComponentManager extends AbstractManager {
    getJitStaticLayout({
      template
    }) {
      return (0, _util.unwrapTemplate)(template).asLayout();
    }

    getCapabilities() {
      return CAPABILITIES$2;
    }

    create(environment, {
      name,
      template
    }, args) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        var bucket = {
          environment
        };
        environment.extra.debugRenderTree.create(bucket, {
          type: 'component',
          name: name,
          args: args.capture(),
          instance: null,
          template
        });
        (0, _runtime2.registerDestructor)(bucket, () => {
          bucket.environment.extra.debugRenderTree.willDestroy(bucket);
        });
        return bucket;
      } else {
        return null;
      }
    }

    getSelf() {
      return _runtime2.NULL_REFERENCE;
    }

    getTag() {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        // returning a const tag skips the update hook (VM BUG?)
        return (0, _validator.createTag)();
      } else {
        // an outlet has no hooks
        return _validator.CONSTANT_TAG;
      }
    }

    getDestroyable(bucket) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        return bucket;
      } else {
        return null;
      }
    }

    didRenderLayout(bucket, bounds) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.environment.extra.debugRenderTree.didRender(bucket, bounds);
      }
    }

    update(bucket) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.environment.extra.debugRenderTree.update(bucket);
      }
    }

    didUpdateLayout(bucket, bounds) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.environment.extra.debugRenderTree.didRender(bucket, bounds);
      }
    }

  }

  var MANAGER = new TemplateOnlyComponentManager();

  class TemplateOnlyComponentDefinition {
    constructor(name, template) {
      this.name = name;
      this.template = template;
      this.manager = MANAGER;
    }

    get state() {
      return this;
    }

  }

  var helper$1;

  if (true
  /* DEBUG */
  ) {
    class ComponentAssertionReference {
      constructor(component, message) {
        this.component = component;
        this.message = message;
        this.tag = component.tag;
      }

      value() {
        var value = this.component.value();
        (true && !(typeof value !== 'string') && (0, _debug.assert)(this.message, typeof value !== 'string'));
        return value;
      }

      get(property) {
        return this.component.get(property);
      }

    }

    helper$1 = args => new ComponentAssertionReference(args.positional.at(0), args.positional.at(1).value());
  } else {
    helper$1 = args => args.positional.at(0);
  }

  var componentAssertionHelper = helper$1;
  var helper$2;

  if (true
  /* DEBUG */
  ) {
    class InElementNullCheckReference {
      constructor(inner) {
        this.inner = inner;
        this.tag = inner.tag;
      }

      value() {
        var value = this.inner.value();
        (true && !(value !== null && value !== undefined) && (0, _debug.assert)('You cannot pass a null or undefined destination element to in-element', value !== null && value !== undefined));
        return value;
      }

      get(key) {
        return this.inner.get(key);
      }

    }

    helper$2 = args => new InElementNullCheckReference(args.positional.at(0));
  } else {
    helper$2 = args => args.positional.at(0);
  }

  var inElementNullCheckHelper = helper$2;

  function normalizeClass({
    positional
  }) {
    var classNameParts = positional.at(0).value().split('.');
    var className = classNameParts[classNameParts.length - 1];
    var value = positional.at(1).value();

    if (value === true) {
      return (0, _string.dasherize)(className);
    } else if (!value && value !== 0) {
      return '';
    } else {
      return String(value);
    }
  }

  function normalizeClassHelper(args, vm) {
    return new _reference.HelperRootReference(normalizeClass, args.capture(), vm.env);
  }
  /**
  @module ember
  */

  /**
    This reference is used to get the `[]` tag of iterables, so we can trigger
    updates to `{{each}}` when it changes. It is put into place by a template
    transform at build time, similar to the (-each-in) helper
  */


  class TrackArrayReference {
    constructor(inner) {
      this.inner = inner;
      this.valueTag = (0, _validator.createUpdatableTag)();
      this.tag = (0, _validator.combine)([inner.tag, this.valueTag]);
    }

    value() {
      var iterable = this.inner.value();
      var tag = (0, _utils.isObject)(iterable) ? (0, _metal.tagForProperty)(iterable, '[]') : _validator.CONSTANT_TAG;
      (0, _validator.updateTag)(this.valueTag, tag);
      return iterable;
    }

    get(key) {
      return this.inner.get(key);
    }

  }

  function trackArray(args) {
    return new TrackArrayReference(args.positional.at(0));
  }
  /**
  @module ember
  */

  /**
     Use the `{{array}}` helper to create an array to pass as an option to your
     components.
  
     ```handlebars
     <MyComponent @people={{array
       'Tom Dade'
       'Yehuda Katz'
       this.myOtherPerson}}
     />
     ```
      or
     ```handlebars
     {{my-component people=(array
       'Tom Dade'
       'Yehuda Katz'
       this.myOtherPerson)
     }}
     ```
  
     Would result in an object such as:
  
     ```js
     ['Tom Date', 'Yehuda Katz', this.get('myOtherPerson')]
     ```
  
     Where the 3rd item in the array is bound to updates of the `myOtherPerson` property.
  
     @method array
     @for Ember.Templates.helpers
     @param {Array} options
     @return {Array} Array
     @since 3.8.0
     @public
   */


  function array(args) {
    return args.positional.capture();
  }

  var isEmpty = value => {
    return value === null || value === undefined || typeof value.toString !== 'function';
  };

  var normalizeTextValue = value => {
    if (isEmpty(value)) {
      return '';
    }

    return String(value);
  };
  /**
  @module ember
  */

  /**
    Concatenates the given arguments into a string.
  
    Example:
  
    ```handlebars
    {{some-component name=(concat firstName " " lastName)}}
  
    {{! would pass name="<first name value> <last name value>" to the component}}
    ```
  
    or for angle bracket invocation, you actually don't need concat at all.
  
    ```handlebars
    <SomeComponent @name="{{firstName}} {{lastName}}" />
    ```
  
    @public
    @method concat
    @for Ember.Templates.helpers
    @since 1.13.0
  */


  function concat({
    positional
  }) {
    return positional.value().map(normalizeTextValue).join('');
  }

  function concat$1(args, vm) {
    return new _reference.HelperRootReference(concat, args.capture(), vm.env);
  }

  function buildUntouchableThis(source) {
    var context = null;

    if (true
    /* DEBUG */
    && _utils.HAS_NATIVE_PROXY) {
      var assertOnProperty = property => {
        (true && !(false) && (0, _debug.assert)(`You accessed \`this.${String(property)}\` from a function passed to the ${source}, but the function itself was not bound to a valid \`this\` context. Consider updating to usage of \`@action\`.`));
      };

      context = new Proxy({}, {
        get(_target, property) {
          assertOnProperty(property);
        },

        set(_target, property) {
          assertOnProperty(property);
          return false;
        },

        has(_target, property) {
          assertOnProperty(property);
          return false;
        }

      });
    }

    return context;
  }

  var context = buildUntouchableThis('`fn` helper');
  /**
  @module ember
  */

  /**
    The `fn` helper allows you to ensure a function that you are passing off
    to another component, helper, or modifier has access to arguments that are
    available in the template.
  
    For example, if you have an `each` helper looping over a number of items, you
    may need to pass a function that expects to receive the item as an argument
    to a component invoked within the loop. Here's how you could use the `fn`
    helper to pass both the function and its arguments together:
  
      ```app/templates/components/items-listing.hbs
    {{#each @items as |item|}}
      <DisplayItem @item=item @select={{fn this.handleSelected item}} />
    {{/each}}
    ```
  
    ```app/components/items-list.js
    import Component from '@glimmer/component';
    import { action } from '@ember/object';
  
    export default class ItemsList extends Component {
      @action
      handleSelected(item) {
        // ...snip...
      }
    }
    ```
  
    In this case the `display-item` component will receive a normal function
    that it can invoke. When it invokes the function, the `handleSelected`
    function will receive the `item` and any arguments passed, thanks to the
    `fn` helper.
  
    Let's take look at what that means in a couple circumstances:
  
    - When invoked as `this.args.select()` the `handleSelected` function will
      receive the `item` from the loop as its first and only argument.
    - When invoked as `this.args.select('foo')` the `handleSelected` function
      will receive the `item` from the loop as its first argument and the
      string `'foo'` as its second argument.
  
    In the example above, we used `@action` to ensure that `handleSelected` is
    properly bound to the `items-list`, but let's explore what happens if we
    left out `@action`:
  
    ```app/components/items-list.js
    import Component from '@glimmer/component';
  
    export default class ItemsList extends Component {
      handleSelected(item) {
        // ...snip...
      }
    }
    ```
  
    In this example, when `handleSelected` is invoked inside the `display-item`
    component, it will **not** have access to the component instance. In other
    words, it will have no `this` context, so please make sure your functions
    are bound (via `@action` or other means) before passing into `fn`!
  
    See also [partial application](https://en.wikipedia.org/wiki/Partial_application).
  
    @method fn
    @for Ember.Templates.helpers
    @public
    @since 3.11.0
  */

  function fn({
    positional
  }, env) {
    var callbackRef = positional.at(0);
    (true && !(callbackRef !== undefined) && (0, _debug.assert)(`You must pass a function as the \`fn\` helpers first argument.`, callbackRef !== undefined));

    if (true
    /* DEBUG */
    && typeof callbackRef[INVOKE] !== 'function') {
      var callback = callbackRef.value();
      (true && !(typeof callback === 'function') && (0, _debug.assert)(`You must pass a function as the \`fn\` helpers first argument, you passed ${callback === null ? 'null' : typeof callback}. ${env.getTemplatePathDebugContext(callbackRef)}`, typeof callback === 'function'));
    }

    return (...invocationArgs) => {
      var [fn, ...args] = positional.value();

      if (typeof callbackRef[INVOKE] === 'function') {
        // references with the INVOKE symbol expect the function behind
        // the symbol to be bound to the reference
        return callbackRef[INVOKE](...args, ...invocationArgs);
      } else {
        return fn.call(context, ...args, ...invocationArgs);
      }
    };
  }

  function fn$1(args, vm) {
    var callback = fn;

    if (true
    /* DEBUG */
    ) {
      callback = args => {
        return fn(args, vm.env);
      };
    }

    return new _reference.HelperRootReference(callback, args.capture(), vm.env);
  }
  /**
  @module ember
  */

  /**
    Dynamically look up a property on an object. The second argument to `{{get}}`
    should have a string value, although it can be bound.
  
    For example, these two usages are equivalent:
  
    ```app/components/developer-detail.js
    import Component from '@glimmer/component';
    import { tracked } from '@glimmer/tracking';
  
    export default class extends Component {
      @tracked developer = {
        name: "Sandi Metz",
        language: "Ruby"
      }
    }
    ```
  
    ```handlebars
    {{this.developer.name}}
    {{get this.developer "name"}}
    ```
  
    If there were several facts about a person, the `{{get}}` helper can dynamically
    pick one:
  
    ```app/templates/application.hbs
    <DeveloperDetail @factName="language" />
    ```
  
    ```handlebars
    {{get this.developer @factName}}
    ```
  
    For a more complex example, this template would allow the user to switch
    between showing the user's height and weight with a click:
  
    ```app/components/developer-detail.js
    import Component from '@glimmer/component';
    import { tracked } from '@glimmer/tracking';
  
    export default class extends Component {
      @tracked developer = {
        name: "Sandi Metz",
        language: "Ruby"
      }
  
      @tracked currentFact = 'name'
  
      @action
      showFact(fact) {
        this.currentFact = fact;
      }
    }
    ```
  
    ```app/components/developer-detail.js
    {{get this.developer this.currentFact}}
  
    <button {{on 'click' (fn this.showFact "name")}}>Show name</button>
    <button {{on 'click' (fn this.showFact "language")}}>Show language</button>
    ```
  
    The `{{get}}` helper can also respect mutable values itself. For example:
  
    ```app/components/developer-detail.js
    <Input @value={{mut (get this.person this.currentFact)}} />
  
    <button {{on 'click' (fn this.showFact "name")}}>Show name</button>
    <button {{on 'click' (fn this.showFact "language")}}>Show language</button>
    ```
  
    Would allow the user to swap what fact is being displayed, and also edit
    that fact via a two-way mutable binding.
  
    @public
    @method get
    @for Ember.Templates.helpers
    @since 2.1.0
   */


  function get$1(args, vm) {
    var sourceReference = args.positional.at(0);
    var pathReference = args.positional.at(1);

    if ((0, _validator.isConstTagged)(pathReference)) {
      // Since the path is constant, we can create a normal chain of property
      // references. The source reference will update like normal, and all of the
      // child references will update accordingly.
      var path = pathReference.value();

      if (path === undefined || path === null || path === '') {
        return _runtime2.NULL_REFERENCE;
      } else if (typeof path === 'string' && path.indexOf('.') > -1) {
        return referenceFromParts(sourceReference, path.split('.'));
      } else {
        return sourceReference.get(String(path));
      }
    } else {
      return new GetHelperRootReference(args.capture(), vm.env);
    }
  }

  function get$2({
    positional
  }) {
    var source = positional.at(0).value();

    if ((0, _utils.isObject)(source)) {
      var path = positional.at(1).value();
      return (0, _metal.get)(source, String(path));
    }
  }

  class GetHelperRootReference extends _reference.HelperRootReference {
    constructor(args, env) {
      super(get$2, args, env);
      this.sourceReference = args.positional.at(0);
      this.pathReference = args.positional.at(1);
    }

    [_reference.UPDATE_REFERENCED_VALUE](value) {
      var source = this.sourceReference.value();

      if ((0, _utils.isObject)(source)) {
        var path = String(this.pathReference.value());
        (0, _metal.set)(source, path, value);
      }
    }

  }
  /**
  @module ember
  */

  /**
     Use the `{{hash}}` helper to create a hash to pass as an option to your
     components. This is specially useful for contextual components where you can
     just yield a hash:
  
     ```handlebars
     {{yield (hash
        name='Sarah'
        title=office
     )}}
     ```
  
     Would result in an object such as:
  
     ```js
     { name: 'Sarah', title: this.get('office') }
     ```
  
     Where the `title` is bound to updates of the `office` property.
  
     Note that the hash is an empty object with no prototype chain, therefore
     common methods like `toString` are not available in the resulting hash.
     If you need to use such a method, you can use the `call` or `apply`
     approach:
  
     ```js
     function toString(obj) {
       return Object.prototype.toString.apply(obj);
     }
     ```
  
     @method hash
     @for Ember.Templates.helpers
     @param {Object} options
     @return {Object} Hash
     @since 2.3.0
     @public
   */


  function hash(args) {
    return args.named.capture();
  }
  /**
  @module ember
  */


  function ifHelper({
    positional
  }) {
    (true && !(positional.length === 3 || positional.length === 2) && (0, _debug.assert)('The inline form of the `if` helper expects two or three arguments, e.g. `{{if trialExpired "Expired" expiryDate}}`.', positional.length === 3 || positional.length === 2));
    var condition = positional.at(0);
    var truthyValue = positional.at(1);
    var falsyValue = positional.at(2);

    if (toBool(condition.value()) === true) {
      return truthyValue.value();
    } else {
      return falsyValue !== undefined ? falsyValue.value() : undefined;
    }
  }

  function unless({
    positional
  }) {
    (true && !(positional.length === 3 || positional.length === 2) && (0, _debug.assert)('The inline form of the `unless` helper expects two or three arguments, e.g. `{{unless isFirstLogin "Welcome back!"}}`.', positional.length === 3 || positional.length === 2));
    var condition = positional.at(0);
    var truthyValue = positional.at(2);
    var falsyValue = positional.at(1);

    if (toBool(condition.value()) === true) {
      return truthyValue !== undefined ? truthyValue.value() : undefined;
    } else {
      return falsyValue.value();
    }
  }
  /**
    The `if` helper allows you to conditionally render one of two branches,
    depending on the "truthiness" of a property.
    For example the following values are all falsey: `false`, `undefined`, `null`, `""`, `0`, `NaN` or an empty array.
  
    This helper has two forms, block and inline.
  
    ## Block form
  
    You can use the block form of `if` to conditionally render a section of the template.
  
    To use it, pass the conditional value to the `if` helper,
    using the block form to wrap the section of template you want to conditionally render.
    Like so:
  
    ```app/templates/application.hbs
    <Weather />
    ```
  
    ```app/components/weather.hbs
    {{! will not render because greeting is undefined}}
    {{#if @isRaining}}
      Yes, grab an umbrella!
    {{/if}}
    ```
  
    You can also define what to show if the property is falsey by using
    the `else` helper.
  
    ```app/components/weather.hbs
    {{#if @isRaining}}
      Yes, grab an umbrella!
    {{else}}
      No, it's lovely outside!
    {{/if}}
    ```
  
    You are also able to combine `else` and `if` helpers to create more complex
    conditional logic.
  
    For the following template:
  
     ```app/components/weather.hbs
    {{#if @isRaining}}
      Yes, grab an umbrella!
    {{else if @isCold}}
      Grab a coat, it's chilly!
    {{else}}
      No, it's lovely outside!
    {{/if}}
    ```
  
    If you call it by saying `isCold` is true:
  
    ```app/templates/application.hbs
    <Weather @isCold={{true}} />
    ```
  
    Then `Grab a coat, it's chilly!` will be rendered.
  
    ## Inline form
  
    The inline `if` helper conditionally renders a single property or string.
  
    In this form, the `if` helper receives three arguments, the conditional value,
    the value to render when truthy, and the value to render when falsey.
  
    For example, if `useLongGreeting` is truthy, the following:
  
    ```app/templates/application.hbs
    <Greeting @useLongGreeting={{true}} />
    ```
  
    ```app/components/greeting.hbs
    {{if @useLongGreeting "Hello" "Hi"}} Alex
    ```
  
    Will render:
  
    ```html
    Hello Alex
    ```
  
    One detail to keep in mind is that both branches of the `if` helper will be evaluated,
    so if you have `{{if condition "foo" (expensive-operation "bar")`,
    `expensive-operation` will always calculate.
  
    @method if
    @for Ember.Templates.helpers
    @public
  */


  function inlineIf(args, vm) {
    return new _reference.HelperRootReference(ifHelper, args.capture(), vm.env);
  }
  /**
    The `unless` helper is the inverse of the `if` helper. It displays if a value
    is falsey ("not true" or "is false"). Example values that will display with
    `unless`: `false`, `undefined`, `null`, `""`, `0`, `NaN` or an empty array.
  
    ## Inline form
  
    The inline `unless` helper conditionally renders a single property or string.
    This helper acts like a ternary operator. If the first property is falsy,
    the second argument will be displayed, otherwise, the third argument will be
    displayed
  
    For example, if you pass a falsey `useLongGreeting` to the `Greeting` component:
  
    ```app/templates/application.hbs
    <Greeting @useLongGreeting={{false}} />
    ```
  
    ```app/components/greeting.hbs
    {{unless @useLongGreeting "Hi" "Hello"}} Ben
    ```
  
    Then it will display:
  
    ```html
    Hi Ben
    ```
  
    ## Block form
  
    Like the `if` helper, the `unless` helper also has a block form.
  
    The following will not render anything:
  
    ```app/templates/application.hbs
    <Greeting />
    ```
  
    ```app/components/greeting.hbs
    {{#unless @greeting}}
      No greeting was found. Why not set one?
    {{/unless}}
    ```
  
    You can also use an `else` helper with the `unless` block. The
    `else` will display if the value is truthy.
  
    If you have the following component:
  
    ```app/components/logged-in.hbs
    {{#unless @userData}}
      Please login.
    {{else}}
      Welcome back!
    {{/unless}}
    ```
  
    Calling it with a truthy `userData`:
  
    ```app/templates/application.hbs
    <LoggedIn @userData={{hash username="Zoey"}} />
    ```
  
    Will render:
  
    ```html
    Welcome back!
    ```
  
    and calling it with a falsey `userData`:
  
    ```app/templates/application.hbs
    <LoggedIn @userData={{false}} />
    ```
  
    Will render:
  
    ```html
    Please login.
    ```
  
    @method unless
    @for Ember.Templates.helpers
    @public
  */


  function inlineUnless(args, vm) {
    return new _reference.HelperRootReference(unless, args.capture(), vm.env);
  }
  /**
  @module ember
  */

  /**
    `log` allows you to output the value of variables in the current rendering
    context. `log` also accepts primitive types such as strings or numbers.
  
    ```handlebars
    {{log "myVariable:" myVariable }}
    ```
  
    @method log
    @for Ember.Templates.helpers
    @param {Array} params
    @public
  */


  function log({
    positional
  }) {
    /* eslint-disable no-console */
    console.log(...positional.value());
    /* eslint-enable no-console */
  }

  function log$1(args, vm) {
    return new _reference.HelperRootReference(log, args.capture(), vm.env);
  }
  /**
  @module ember
  */

  /**
    This is a helper to be used in conjunction with the link-to helper.
    It will supply url query parameters to the target route.
  
    @example In this example we are setting the `direction` query param to the value `"asc"`
  
    ```app/templates/application.hbs
    <LinkTo
      @route="posts"
      {{query-params direction="asc"}}
    >
      Sort
    </LinkTo>
    ```
  
    @method query-params
    @for Ember.Templates.helpers
    @param {Object} hash takes a hash of query parameters
    @return {Object} A `QueryParams` object for `{{link-to}}`
    @public
  */


  function queryParams({
    positional,
    named
  }) {
    // tslint:disable-next-line:max-line-length
    (true && !(positional.value().length === 0) && (0, _debug.assert)("The `query-params` helper only accepts hash parameters, e.g. (query-params queryParamPropertyName='foo') as opposed to just (query-params 'foo')", positional.value().length === 0));
    return new _routing.QueryParams((0, _polyfills.assign)({}, named.value()));
  }

  function queryParams$1(args, vm) {
    return new _reference.HelperRootReference(queryParams, args.capture(), vm.env);
  }
  /**
    The `readonly` helper let's you specify that a binding is one-way only,
    instead of two-way.
    When you pass a `readonly` binding from an outer context (e.g. parent component),
    to to an inner context (e.g. child component), you are saying that changing that
    property in the inner context does not change the value in the outer context.
  
    To specify that a binding is read-only, when invoking the child `Component`:
  
    ```app/components/my-parent.js
    export default Component.extend({
      totalClicks: 3
    });
    ```
  
    ```app/templates/components/my-parent.hbs
    {{log totalClicks}} // -> 3
    <MyChild @childClickCount={{readonly totalClicks}} />
    ```
    ```
    {{my-child childClickCount=(readonly totalClicks)}}
    ```
  
    Now, when you update `childClickCount`:
  
    ```app/components/my-child.js
    export default Component.extend({
      click() {
        this.incrementProperty('childClickCount');
      }
    });
    ```
  
    The value updates in the child component, but not the parent component:
  
    ```app/templates/components/my-child.hbs
    {{log childClickCount}} //-> 4
    ```
  
    ```app/templates/components/my-parent.hbs
    {{log totalClicks}} //-> 3
    <MyChild @childClickCount={{readonly totalClicks}} />
    ```
    or
    ```app/templates/components/my-parent.hbs
    {{log totalClicks}} //-> 3
    {{my-child childClickCount=(readonly totalClicks)}}
    ```
  
    ### Objects and Arrays
  
    When passing a property that is a complex object (e.g. object, array) instead of a primitive object (e.g. number, string),
    only the reference to the object is protected using the readonly helper.
    This means that you can change properties of the object both on the parent component, as well as the child component.
    The `readonly` binding behaves similar to the `const` keyword in JavaScript.
  
    Let's look at an example:
  
    First let's set up the parent component:
  
    ```app/components/my-parent.js
    import Component from '@ember/component';
  
    export default Component.extend({
      clicks: null,
  
      init() {
        this._super(...arguments);
        this.set('clicks', { total: 3 });
      }
    });
    ```
  
    ```app/templates/components/my-parent.hbs
    {{log clicks.total}} //-> 3
    <MyChild @childClicks={{readonly clicks}} />
    ```
    ```app/templates/components/my-parent.hbs
    {{log clicks.total}} //-> 3
    {{my-child childClicks=(readonly clicks)}}
    ```
  
    Now, if you update the `total` property of `childClicks`:
  
    ```app/components/my-child.js
    import Component from '@ember/component';
  
    export default Component.extend({
      click() {
        this.get('clicks').incrementProperty('total');
      }
    });
    ```
  
    You will see the following happen:
  
    ```app/templates/components/my-parent.hbs
    {{log clicks.total}} //-> 4
    <MyChild @childClicks={{readonly clicks}} />
    ```
    or
    ```app/templates/components/my-parent.hbs
    {{log clicks.total}} //-> 4
    {{my-child childClicks=(readonly clicks)}}
    ```
  
    ```app/templates/components/my-child.hbs
    {{log childClicks.total}} //-> 4
    ```
  
    @method readonly
    @param {Object} [attr] the read-only attribute.
    @for Ember.Templates.helpers
    @private
  */


  class ReadonlyReference extends _reference.RootReference {
    constructor(inner, env) {
      super(env);
      this.inner = inner;
      this.tag = inner.tag;
    }

    get [INVOKE]() {
      return this.inner[INVOKE];
    }

    value() {
      return this.inner.value();
    }

    get(key) {
      return this.inner.get(key);
    }

  }

  function readonly(args, vm) {
    var ref = unMut(args.positional.at(0));
    return new ReadonlyReference(ref, vm.env);
  }
  /**
  @module ember
  */

  /**
    The `{{unbound}}` helper disconnects the one-way binding of a property,
    essentially freezing its value at the moment of rendering. For example,
    in this example the display of the variable `name` will not change even
    if it is set with a new value:
  
    ```handlebars
    {{unbound this.name}}
    ```
  
    Like any helper, the `unbound` helper can accept a nested helper expression.
    This allows for custom helpers to be rendered unbound:
  
    ```handlebars
    {{unbound (some-custom-helper)}}
    {{unbound (capitalize this.name)}}
    {{! You can use any helper, including unbound, in a nested expression }}
    {{capitalize (unbound this.name)}}
    ```
  
    The `unbound` helper only accepts a single argument, and it return an
    unbound value.
  
    @method unbound
    @for Ember.Templates.helpers
    @public
  */


  function unbound(args, vm) {
    (true && !(args.positional.length === 1 && args.named.length === 0) && (0, _debug.assert)('unbound helper cannot be called with multiple params or hash params', args.positional.length === 1 && args.named.length === 0));
    return new UnboundRootReference(args.positional.at(0).value(), vm.env);
  }

  var MODIFIERS = ['alt', 'shift', 'meta', 'ctrl'];
  var POINTER_EVENT_TYPE_REGEX = /^click|mouse|touch/;

  function isAllowedEvent(event, allowedKeys) {
    if (allowedKeys === null || allowedKeys === undefined) {
      if (POINTER_EVENT_TYPE_REGEX.test(event.type)) {
        return (0, _views.isSimpleClick)(event);
      } else {
        allowedKeys = '';
      }
    }

    if (allowedKeys.indexOf('any') >= 0) {
      return true;
    }

    for (var i = 0; i < MODIFIERS.length; i++) {
      if (event[MODIFIERS[i] + 'Key'] && allowedKeys.indexOf(MODIFIERS[i]) === -1) {
        return false;
      }
    }

    return true;
  }

  var ActionHelper = {
    // registeredActions is re-exported for compatibility with older plugins
    // that were using this undocumented API.
    registeredActions: _views.ActionManager.registeredActions,

    registerAction(actionState) {
      var {
        actionId
      } = actionState;
      _views.ActionManager.registeredActions[actionId] = actionState;
      return actionId;
    },

    unregisterAction(actionState) {
      var {
        actionId
      } = actionState;
      delete _views.ActionManager.registeredActions[actionId];
    }

  };

  class ActionState {
    constructor(element, actionId, actionName, actionArgs, namedArgs, positionalArgs, implicitTarget, dom, tag) {
      this.element = element;
      this.actionId = actionId;
      this.actionName = actionName;
      this.actionArgs = actionArgs;
      this.namedArgs = namedArgs;
      this.positional = positionalArgs;
      this.implicitTarget = implicitTarget;
      this.dom = dom;
      this.eventName = this.getEventName();
      this.tag = tag;
      (0, _runtime2.registerDestructor)(this, () => ActionHelper.unregisterAction(this));
    }

    getEventName() {
      return this.namedArgs.get('on').value() || 'click';
    }

    getActionArgs() {
      var result = new Array(this.actionArgs.length);

      for (var i = 0; i < this.actionArgs.length; i++) {
        result[i] = this.actionArgs[i].value();
      }

      return result;
    }

    getTarget() {
      var {
        implicitTarget,
        namedArgs
      } = this;
      var target;

      if (namedArgs.has('target')) {
        target = namedArgs.get('target').value();
      } else {
        target = implicitTarget.value();
      }

      return target;
    }

    handler(event) {
      var {
        actionName,
        namedArgs
      } = this;
      var bubbles = namedArgs.get('bubbles');
      var preventDefault = namedArgs.get('preventDefault');
      var allowedKeys = namedArgs.get('allowedKeys');
      var target = this.getTarget();
      var shouldBubble = bubbles.value() !== false;

      if (!isAllowedEvent(event, allowedKeys.value())) {
        return true;
      }

      if (preventDefault.value() !== false) {
        event.preventDefault();
      }

      if (!shouldBubble) {
        event.stopPropagation();
      }

      (0, _runloop.join)(() => {
        var args = this.getActionArgs();
        var payload = {
          args,
          target,
          name: null
        };

        if (typeof actionName[INVOKE] === 'function') {
          (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, () => {
            actionName[INVOKE].apply(actionName, args);
          });
          return;
        }

        if (typeof actionName === 'function') {
          (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, () => {
            actionName.apply(target, args);
          });
          return;
        }

        payload.name = actionName;

        if (target.send) {
          (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, () => {
            target.send.apply(target, [actionName, ...args]);
          });
        } else {
          (true && !(typeof target[actionName] === 'function') && (0, _debug.assert)(`The action '${actionName}' did not exist on ${target}`, typeof target[actionName] === 'function'));
          (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, () => {
            target[actionName].apply(target, args);
          });
        }
      });
      return shouldBubble;
    }

  } // implements ModifierManager<Action>


  class ActionModifierManager {
    create(element, _state, args, _dynamicScope, dom) {
      var {
        named,
        positional,
        tag
      } = args.capture();
      var implicitTarget;
      var actionName;
      var actionNameRef;

      if (positional.length > 1) {
        implicitTarget = positional.at(0);
        actionNameRef = positional.at(1);

        if (actionNameRef[INVOKE]) {
          actionName = actionNameRef;
        } else {
          var actionLabel = actionNameRef.propertyKey;
          actionName = actionNameRef.value();
          (true && !(typeof actionName === 'string' || typeof actionName === 'function') && (0, _debug.assert)('You specified a quoteless path, `' + actionLabel + '`, to the ' + '{{action}} helper which did not resolve to an action name (a ' + 'string). Perhaps you meant to use a quoted actionName? (e.g. ' + '{{action "' + actionLabel + '"}}).', typeof actionName === 'string' || typeof actionName === 'function'));
        }
      }

      var actionArgs = []; // The first two arguments are (1) `this` and (2) the action name.
      // Everything else is a param.

      for (var i = 2; i < positional.length; i++) {
        actionArgs.push(positional.at(i));
      }

      var actionId = (0, _utils.uuid)();
      var actionState = new ActionState(element, actionId, actionName, actionArgs, named, positional, implicitTarget, dom, tag);
      (true && !(actionState.eventName !== 'mouseEnter' && actionState.eventName !== 'mouseLeave' && actionState.eventName !== 'mouseMove') && (0, _debug.deprecate)(`Using the \`{{action}}\` modifier with \`${actionState.eventName}\` events has been deprecated.`, actionState.eventName !== 'mouseEnter' && actionState.eventName !== 'mouseLeave' && actionState.eventName !== 'mouseMove', {
        id: 'ember-views.event-dispatcher.mouseenter-leave-move',
        until: '4.0.0',
        url: 'https://emberjs.com/deprecations/v3.x#toc_action-mouseenter-leave-move'
      }));
      return actionState;
    }

    install(actionState) {
      var {
        dom,
        element,
        actionId
      } = actionState;
      ActionHelper.registerAction(actionState);
      dom.setAttribute(element, 'data-ember-action', '');
      dom.setAttribute(element, `data-ember-action-${actionId}`, actionId);
    }

    update(actionState) {
      var {
        positional
      } = actionState;
      var actionNameRef = positional.at(1);

      if (!actionNameRef[INVOKE]) {
        actionState.actionName = actionNameRef.value();
      }

      actionState.eventName = actionState.getEventName();
    }

    getTag(actionState) {
      return actionState.tag;
    }

    getDestroyable(actionState) {
      return actionState;
    }

  }

  var debugRenderMessage;

  if (true
  /* DEBUG */
  ) {
    debugRenderMessage = renderingStack => {
      return `While rendering:\n----------------\n${renderingStack.replace(/^/gm, '  ')}`;
    };
  }

  var debugRenderMessage$1 = debugRenderMessage;

  function capabilities$1(managerAPI, optionalFeatures = {}) {
    (true && !(managerAPI === '3.13') && (0, _debug.assert)('Invalid modifier manager compatibility specified', managerAPI === '3.13'));
    return {
      disableAutoTracking: Boolean(optionalFeatures.disableAutoTracking)
    };
  }

  class CustomModifierDefinition {
    constructor(name, ModifierClass, delegate, isInteractive) {
      this.name = name;
      this.ModifierClass = ModifierClass;
      this.delegate = delegate;
      this.state = {
        ModifierClass,
        name,
        delegate
      };
      this.manager = isInteractive ? CUSTOM_INTERACTIVE_MODIFIER_MANAGER : CUSTOM_NON_INTERACTIVE_MODIFIER_MANAGER;
    }

  }

  class CustomModifierState {
    constructor(element, delegate, modifier, args) {
      this.element = element;
      this.delegate = delegate;
      this.modifier = modifier;
      this.args = args;
      this.tag = (0, _validator.createUpdatableTag)();
      (0, _runtime2.registerDestructor)(this, () => delegate.destroyModifier(modifier, args.value()));
    }

  }
  /**
    The CustomModifierManager allows addons to provide custom modifier
    implementations that integrate seamlessly into Ember. This is accomplished
    through a delegate, registered with the custom modifier manager, which
    implements a set of hooks that determine modifier behavior.
    To create a custom modifier manager, instantiate a new CustomModifierManager
    class and pass the delegate as the first argument:
  
    ```js
    let manager = new CustomModifierManager({
      // ...delegate implementation...
    });
    ```
  
    ## Delegate Hooks
  
    Throughout the lifecycle of a modifier, the modifier manager will invoke
    delegate hooks that are responsible for surfacing those lifecycle changes to
    the end developer.
    * `createModifier()` - invoked when a new instance of a modifier should be created
    * `installModifier()` - invoked when the modifier is installed on the element
    * `updateModifier()` - invoked when the arguments passed to a modifier change
    * `destroyModifier()` - invoked when the modifier is about to be destroyed
  */


  class InteractiveCustomModifierManager {
    create(element, definition, args) {
      var {
        delegate,
        ModifierClass
      } = definition;
      var capturedArgs = args.capture();
      var instance = definition.delegate.createModifier(ModifierClass, capturedArgs.value());
      return new CustomModifierState(element, delegate, instance, capturedArgs);
    }

    getTag({
      args,
      tag
    }) {
      return (0, _validator.combine)([tag, args.tag]);
    }

    install(state) {
      var {
        element,
        args,
        delegate,
        modifier,
        tag
      } = state;
      (true && !(typeof delegate.capabilities === 'object' && delegate.capabilities !== null) && (0, _debug.assert)('Custom modifier managers must define their capabilities using the capabilities() helper function', typeof delegate.capabilities === 'object' && delegate.capabilities !== null));
      var {
        capabilities
      } = delegate;

      if (capabilities.disableAutoTracking === true) {
        (0, _validator.untrack)(() => delegate.installModifier(modifier, element, args.value()));
      } else {
        var combinedTrackingTag = (0, _validator.track)(() => delegate.installModifier(modifier, element, args.value()), true
        /* DEBUG */
        && debugRenderMessage$1(`(instance of a \`${(0, _utils.getDebugName)(modifier)}\` modifier)`));
        (0, _validator.updateTag)(tag, combinedTrackingTag);
      }
    }

    update(state) {
      var {
        args,
        delegate,
        modifier,
        tag
      } = state;
      var {
        capabilities
      } = delegate;

      if (capabilities.disableAutoTracking === true) {
        (0, _validator.untrack)(() => delegate.updateModifier(modifier, args.value()));
      } else {
        var combinedTrackingTag = (0, _validator.track)(() => delegate.updateModifier(modifier, args.value()), true
        /* DEBUG */
        && debugRenderMessage$1(`(instance of a \`${(0, _utils.getDebugName)(modifier)}\` modifier)`));
        (0, _validator.updateTag)(tag, combinedTrackingTag);
      }
    }

    getDestroyable(state) {
      return state;
    }

  }

  class NonInteractiveCustomModifierManager {
    create() {
      return null;
    }

    getTag() {
      return _validator.CONSTANT_TAG;
    }

    install() {}

    update() {}

    getDestroyable() {
      return null;
    }

  }

  var CUSTOM_INTERACTIVE_MODIFIER_MANAGER = new InteractiveCustomModifierManager();
  var CUSTOM_NON_INTERACTIVE_MODIFIER_MANAGER = new NonInteractiveCustomModifierManager();
  var untouchableContext = buildUntouchableThis('`on` modifier');
  /**
  @module ember
  */

  /*
    Internet Explorer 11 does not support `once` and also does not support
    passing `eventOptions`. In some situations it then throws a weird script
    error, like:
  
    ```
    Could not complete the operation due to error 80020101
    ```
  
    This flag determines, whether `{ once: true }` and thus also event options in
    general are supported.
  */

  var SUPPORTS_EVENT_OPTIONS = (() => {
    try {
      var div = document.createElement('div');
      var counter = 0;
      div.addEventListener('click', () => counter++, {
        once: true
      });
      var event;

      if (typeof Event === 'function') {
        event = new Event('click');
      } else {
        event = document.createEvent('Event');
        event.initEvent('click', true, true);
      }

      div.dispatchEvent(event);
      div.dispatchEvent(event);
      return counter === 1;
    } catch (error) {
      return false;
    }
  })();

  class OnModifierState {
    constructor(owner, element, args) {
      this.shouldUpdate = true;
      this.owner = owner;
      this.element = element;
      this.args = args;
      this.tag = args.tag;
    }

    updateFromArgs() {
      var {
        args
      } = this;
      var {
        once,
        passive,
        capture
      } = args.named.value();

      if (once !== this.once) {
        this.once = once;
        this.shouldUpdate = true;
      }

      if (passive !== this.passive) {
        this.passive = passive;
        this.shouldUpdate = true;
      }

      if (capture !== this.capture) {
        this.capture = capture;
        this.shouldUpdate = true;
      }

      var options;

      if (once || passive || capture) {
        options = this.options = {
          once,
          passive,
          capture
        };
      } else {
        this.options = undefined;
      }

      (true && !(args.positional.at(0) !== undefined && typeof args.positional.at(0).value() === 'string') && (0, _debug.assert)('You must pass a valid DOM event name as the first argument to the `on` modifier', args.positional.at(0) !== undefined && typeof args.positional.at(0).value() === 'string'));
      var eventName = args.positional.at(0).value();

      if (eventName !== this.eventName) {
        this.eventName = eventName;
        this.shouldUpdate = true;
      }

      var userProvidedCallbackReference = args.positional.at(1);

      if (true
      /* DEBUG */
      ) {
        (true && !(args.positional.at(1) !== undefined) && (0, _debug.assert)(`You must pass a function as the second argument to the \`on\` modifier.`, args.positional.at(1) !== undefined)); // hardcoding `renderer:-dom` here because we guard for `this.isInteractive` before instantiating OnModifierState, it can never be created when the renderer is `renderer:-inert`

        var renderer = (0, _util.expect)(this.owner.lookup('renderer:-dom'), `BUG: owner is missing renderer:-dom`);
        var stack = renderer.debugRenderTree.logRenderStackForPath(userProvidedCallbackReference);
        var value = userProvidedCallbackReference.value();
        (true && !(typeof value === 'function') && (0, _debug.assert)(`You must pass a function as the second argument to the \`on\` modifier, you passed ${value === null ? 'null' : typeof value}. While rendering:\n\n${stack}`, typeof value === 'function'));
      }

      var userProvidedCallback = userProvidedCallbackReference.value();

      if (userProvidedCallback !== this.userProvidedCallback) {
        this.userProvidedCallback = userProvidedCallback;
        this.shouldUpdate = true;
      }

      (true && !(args.positional.length === 2) && (0, _debug.assert)(`You can only pass two positional arguments (event name and callback) to the \`on\` modifier, but you provided ${args.positional.length}. Consider using the \`fn\` helper to provide additional arguments to the \`on\` callback.`, args.positional.length === 2));
      var needsCustomCallback = SUPPORTS_EVENT_OPTIONS === false && once ||
      /* needs manual once implementation */
      true
      /* DEBUG */
      && passive
      /* needs passive enforcement */
      ;

      if (this.shouldUpdate) {
        if (needsCustomCallback) {
          var callback = this.callback = function (event) {
            if (true
            /* DEBUG */
            && passive) {
              event.preventDefault = () => {
                (true && !(false) && (0, _debug.assert)(`You marked this listener as 'passive', meaning that you must not call 'event.preventDefault()': \n\n${userProvidedCallback}`));
              };
            }

            if (!SUPPORTS_EVENT_OPTIONS && once) {
              removeEventListener(this, eventName, callback, options);
            }

            return userProvidedCallback.call(untouchableContext, event);
          };
        } else if (true
        /* DEBUG */
        ) {
          // prevent the callback from being bound to the element
          this.callback = userProvidedCallback.bind(untouchableContext);
        } else {
          this.callback = userProvidedCallback;
        }
      }
    }

  }

  var adds = 0;
  var removes = 0;

  function removeEventListener(element, eventName, callback, options) {
    removes++;

    if (SUPPORTS_EVENT_OPTIONS) {
      // when options are supported, use them across the board
      element.removeEventListener(eventName, callback, options);
    } else if (options !== undefined && options.capture) {
      // used only in the following case:
      //
      // `{ once: true | false, passive: true | false, capture: true }
      //
      // `once` is handled via a custom callback that removes after first
      // invocation so we only care about capture here as a boolean
      element.removeEventListener(eventName, callback, true);
    } else {
      // used only in the following cases:
      //
      // * where there is no options
      // * `{ once: true | false, passive: true | false, capture: false }
      element.removeEventListener(eventName, callback);
    }
  }

  function addEventListener(element, eventName, callback, options) {
    adds++;

    if (SUPPORTS_EVENT_OPTIONS) {
      // when options are supported, use them across the board
      element.addEventListener(eventName, callback, options);
    } else if (options !== undefined && options.capture) {
      // used only in the following case:
      //
      // `{ once: true | false, passive: true | false, capture: true }
      //
      // `once` is handled via a custom callback that removes after first
      // invocation so we only care about capture here as a boolean
      element.addEventListener(eventName, callback, true);
    } else {
      // used only in the following cases:
      //
      // * where there is no options
      // * `{ once: true | false, passive: true | false, capture: false }
      element.addEventListener(eventName, callback);
    }
  }
  /**
    The `{{on}}` modifier lets you easily add event listeners (it uses
    [EventTarget.addEventListener](https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener)
    internally).
  
    For example, if you'd like to run a function on your component when a `<button>`
    in the components template is clicked you might do something like:
  
    ```app/components/like-post.hbs
    <button {{on 'click' this.saveLike}}>Like this post!</button>
    ```
  
    ```app/components/like-post.js
    import Component from '@glimmer/component';
    import { action } from '@ember/object';
  
    export default class LikePostComponent extends Component {
      @action
      saveLike() {
        // someone likes your post!
        // better send a request off to your server...
      }
    }
    ```
  
    ### Arguments
  
    `{{on}}` accepts two positional arguments, and a few named arguments.
  
    The positional arguments are:
  
    - `event` -- the name to use when calling `addEventListener`
    - `callback` -- the function to be passed to `addEventListener`
  
    The named arguments are:
  
    - capture -- a `true` value indicates that events of this type will be dispatched
      to the registered listener before being dispatched to any EventTarget beneath it
      in the DOM tree.
    - once -- indicates that the listener should be invoked at most once after being
      added. If true, the listener would be automatically removed when invoked.
    - passive -- if `true`, indicates that the function specified by listener will never
      call preventDefault(). If a passive listener does call preventDefault(), the user
      agent will do nothing other than generate a console warning. See
      [Improving scrolling performance with passive listeners](https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners)
      to learn more.
  
    The callback function passed to `{{on}}` will receive any arguments that are passed
    to the event handler. Most commonly this would be the `event` itself.
  
    If you would like to pass additional arguments to the function you should use
    the `{{fn}}` helper.
  
    For example, in our example case above if you'd like to pass in the post that
    was being liked when the button is clicked you could do something like:
  
    ```app/components/like-post.hbs
    <button {{on 'click' (fn this.saveLike @post)}}>Like this post!</button>
    ```
  
    In this case, the `saveLike` function will receive two arguments: the value
    of `@post` and the click event. The click event will always be the last
    argument passed to the handler because `{{fn}}` injects custom arguments
    first.
  
    ### Function Context
  
    In the example above, we used `@action` to ensure that `likePost` is
    properly bound to the `items-list`, but let's explore what happens if we
    left out `@action`:
  
    ```app/components/like-post.js
    import Component from '@glimmer/component';
  
    export default class LikePostComponent extends Component {
      saveLike() {
        // ...snip...
      }
    }
    ```
  
    In this example, when the button is clicked `saveLike` will be invoked,
    it will **not** have access to the component instance. In other
    words, it will have no `this` context, so please make sure your functions
    are bound (via `@action` or other means) before passing into `on`!
  
    @method on
    @for Ember.Templates.helpers
    @public
    @since 3.11.0
  */


  class OnModifierManager {
    constructor(owner, isInteractive) {
      this.SUPPORTS_EVENT_OPTIONS = SUPPORTS_EVENT_OPTIONS;
      this.isInteractive = isInteractive;
      this.owner = owner;
    }

    get counters() {
      return {
        adds,
        removes
      };
    }

    create(element, _state, args) {
      if (!this.isInteractive) {
        return null;
      }

      var capturedArgs = args.capture();
      return new OnModifierState(this.owner, element, capturedArgs);
    }

    getTag(state) {
      if (state === null) {
        return _validator.CONSTANT_TAG;
      }

      return state.tag;
    }

    install(state) {
      if (state === null) {
        return;
      }

      state.updateFromArgs();
      var {
        element,
        eventName,
        callback,
        options
      } = state;
      addEventListener(element, eventName, callback, options);
      (0, _runtime2.registerDestructor)(state, () => removeEventListener(element, eventName, callback, options));
      state.shouldUpdate = false;
    }

    update(state) {
      if (state === null) {
        return;
      } // stash prior state for el.removeEventListener


      var {
        element,
        eventName,
        callback,
        options
      } = state;
      state.updateFromArgs();

      if (!state.shouldUpdate) {
        return;
      } // use prior state values for removal


      removeEventListener(element, eventName, callback, options); // read updated values from the state object

      addEventListener(state.element, state.eventName, state.callback, state.options);
      state.shouldUpdate = false;
    }

    getDestroyable(state) {
      return state;
    }

  }

  var CAPABILITIES$3 = {
    dynamicLayout: true,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: true,
    attributeHook: false,
    elementHook: false,
    createCaller: true,
    dynamicScope: true,
    updateHook: true,
    createInstance: true,
    wrapped: false,
    willDestroy: false
  }; // TODO
  // This "disables" the "@model" feature by making the arg untypable syntatically
  // Delete this when EMBER_ROUTING_MODEL_ARG has shipped

  var MODEL_ARG_NAME = 'model';

  class MountManager extends AbstractManager {
    getJitDynamicLayout(state, _) {
      var templateFactory$$1 = state.engine.lookup('template:application');
      var template = templateFactory$$1(state.engine);

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        state.environment.extra.debugRenderTree.setTemplate(state.controller, template);
      }

      return template;
    }

    getCapabilities() {
      return CAPABILITIES$3;
    }

    create(environment, {
      name
    }, args) {
      // TODO
      // mount is a runtime helper, this shouldn't use dynamic layout
      // we should resolve the engine app template in the helper
      // it also should use the owner that looked up the mount helper.
      var engine = environment.extra.owner.buildChildEngineInstance(name);
      engine.boot();
      var applicationFactory = engine.factoryFor(`controller:application`);
      var controllerFactory = applicationFactory || (0, _routing.generateControllerFactory)(engine, 'application');
      var controller;
      var self;
      var bucket;
      var modelRef;

      if (args.named.has(MODEL_ARG_NAME)) {
        modelRef = args.named.get(MODEL_ARG_NAME);
      }

      if (modelRef === undefined) {
        controller = controllerFactory.create();
        self = new _reference.ComponentRootReference(controller, environment);
        bucket = {
          engine,
          controller,
          self,
          environment
        };
      } else {
        var model = modelRef.value();
        controller = controllerFactory.create({
          model
        });
        self = new _reference.ComponentRootReference(controller, environment);
        bucket = {
          engine,
          controller,
          self,
          modelRef,
          environment
        };
      }

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        environment.extra.debugRenderTree.create(bucket, {
          type: 'engine',
          name,
          args: args.capture(),
          instance: engine,
          template: undefined
        });
        environment.extra.debugRenderTree.create(controller, {
          type: 'route-template',
          name: 'application',
          args: args.capture(),
          instance: controller,
          // set in getDynamicLayout
          template: undefined
        });
        (0, _runtime2.registerDestructor)(engine, () => {
          environment.extra.debugRenderTree.willDestroy(controller);
          environment.extra.debugRenderTree.willDestroy(bucket);
        });
      }

      return bucket;
    }

    getSelf({
      self
    }) {
      return self;
    }

    getTag(state) {
      var tag = _validator.CONSTANT_TAG;

      if (state.modelRef) {
        tag = state.modelRef.tag;
      }

      if (_environment2.ENV._DEBUG_RENDER_TREE && (0, _validator.isConstTag)(tag)) {
        tag = (0, _validator.createTag)();
      }

      return tag;
    }

    getDestroyable(bucket) {
      return bucket.engine;
    }

    didRenderLayout(bucket, bounds) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.environment.extra.debugRenderTree.didRender(bucket.controller, bounds);
        bucket.environment.extra.debugRenderTree.didRender(bucket, bounds);
      }
    }

    update(bucket) {
      var {
        controller,
        environment,
        modelRef
      } = bucket;

      if (modelRef !== undefined) {
        controller.set('model', modelRef.value());
      }

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        environment.extra.debugRenderTree.update(bucket);
        environment.extra.debugRenderTree.update(bucket.controller);
      }
    }

    didUpdateLayout(bucket, bounds) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        bucket.environment.extra.debugRenderTree.didRender(bucket.controller, bounds);
        bucket.environment.extra.debugRenderTree.didRender(bucket, bounds);
      }
    }

  }

  var MOUNT_MANAGER = new MountManager();

  class MountDefinition {
    constructor(name) {
      this.manager = MOUNT_MANAGER;
      this.state = {
        name
      };
    }

  }
  /**
  @module ember
  */


  function mountHelper(args, vm) {
    var env = vm.env;
    var nameRef = args.positional.at(0);
    var captured = null;
    (true && !(args.positional.length === 1) && (0, _debug.assert)('You can only pass a single positional argument to the {{mount}} helper, e.g. {{mount "chat-engine"}}.', args.positional.length === 1));

    if (true
    /* DEBUG */
    && args.named) {
      var keys = args.named.names;
      var extra = keys.filter(k => k !== 'model');
      (true && !(extra.length === 0) && (0, _debug.assert)('You can only pass a `model` argument to the {{mount}} helper, ' + 'e.g. {{mount "profile-engine" model=this.profile}}. ' + `You passed ${extra.join(',')}.`, extra.length === 0));
    } // TODO: the functionality to create a proper CapturedArgument should be
    // exported by glimmer, or that it should provide an overload for `curry`
    // that takes `PreparedArguments`


    if (args.named.has('model')) {
      (true && !(args.named.length === 1) && (0, _debug.assert)('[BUG] this should already be checked by the macro', args.named.length === 1));
      var named = args.named.capture();
      var {
        tag
      } = named; // TODO delete me after EMBER_ROUTING_MODEL_ARG has shipped

      if (true
      /* DEBUG */
      && MODEL_ARG_NAME !== 'model') {
        (true && !(named['_map'] === null) && (0, _debug.assert)('[BUG] named._map is not null', named['_map'] === null));
        named.names = [MODEL_ARG_NAME];
      }

      captured = {
        tag,
        positional: _runtime2.EMPTY_ARGS.positional,
        named,
        length: 1,

        value() {
          return {
            named: this.named.value(),
            positional: this.positional.value()
          };
        }

      };
    }

    return new DynamicEngineReference(nameRef, env, captured);
  }
  /**
    The `{{mount}}` helper lets you embed a routeless engine in a template.
    Mounting an engine will cause an instance to be booted and its `application`
    template to be rendered.
  
    For example, the following template mounts the `ember-chat` engine:
  
    ```handlebars
    {{! application.hbs }}
    {{mount "ember-chat"}}
    ```
  
    Additionally, you can also pass in a `model` argument that will be
    set as the engines model. This can be an existing object:
  
    ```
    <div>
      {{mount 'admin' model=userSettings}}
    </div>
    ```
  
    Or an inline `hash`, and you can even pass components:
  
    ```
    <div>
      <h1>Application template!</h1>
      {{mount 'admin' model=(hash
          title='Secret Admin'
          signInButton=(component 'sign-in-button')
      )}}
    </div>
    ```
  
    @method mount
    @param {String} name Name of the engine to mount.
    @param {Object} [model] Object that will be set as
                            the model of the engine.
    @for Ember.Templates.helpers
    @public
  */


  class DynamicEngineReference {
    constructor(nameRef, env, args) {
      this.nameRef = nameRef;
      this.env = env;
      this.args = args;
      this._lastName = null;
      this._lastDef = null;
      this.tag = nameRef.tag;
    }

    value() {
      var {
        env,
        nameRef,
        args
      } = this;
      var name = nameRef.value();

      if (typeof name === 'string') {
        if (this._lastName === name) {
          return this._lastDef;
        }

        (true && !(env.extra.owner.hasRegistration(`engine:${name}`)) && (0, _debug.assert)(`You used \`{{mount '${name}'}}\`, but the engine '${name}' can not be found.`, env.extra.owner.hasRegistration(`engine:${name}`)));

        if (!env.extra.owner.hasRegistration(`engine:${name}`)) {
          return null;
        }

        this._lastName = name;
        this._lastDef = (0, _runtime2.curry)(new MountDefinition(name), args);
        return this._lastDef;
      } else {
        (true && !(name === null || name === undefined) && (0, _debug.assert)(`Invalid engine name '${name}' specified, engine name must be either a string, null or undefined.`, name === null || name === undefined));
        this._lastDef = null;
        this._lastName = null;
        return null;
      }
    }

    get() {
      return _runtime2.UNDEFINED_REFERENCE;
    }

  }
  /**
   * Represents the root outlet.
   */


  class RootOutletReference {
    constructor(outletState) {
      this.outletState = outletState;
      this.tag = (0, _validator.createTag)();
    }

    get(key) {
      return new PathReference(this, key);
    }

    value() {
      return this.outletState;
    }

    update(state) {
      this.outletState.outlets.main = state;
      (0, _validator.dirtyTag)(this.tag);
    }

  }
  /**
   * Represents the connected outlet.
   */


  class OutletReference {
    constructor(parentStateRef, outletNameRef) {
      this.parentStateRef = parentStateRef;
      this.outletNameRef = outletNameRef;
      this.tag = (0, _validator.combine)([parentStateRef.tag, outletNameRef.tag]);
    }

    value() {
      var outletState = this.parentStateRef.value();
      var outlets = outletState === undefined ? undefined : outletState.outlets;
      return outlets === undefined ? undefined : outlets[this.outletNameRef.value()];
    }

    get(key) {
      return new PathReference(this, key);
    }

  }
  /**
   * Outlet state is dirtied from root.
   * This just using the parent tag for dirtiness.
   */


  class PathReference {
    constructor(parent, key) {
      this.parent = parent;
      this.key = key;
      this.tag = parent.tag;
    }

    get(key) {
      return new PathReference(this, key);
    }

    value() {
      var parent = this.parent.value();
      return parent && parent[this.key];
    }

  }
  /**
    The `{{outlet}}` helper lets you specify where a child route will render in
    your template. An important use of the `{{outlet}}` helper is in your
    application's `application.hbs` file:
  
    ```app/templates/application.hbs
    <MyHeader />
  
    <div class="my-dynamic-content">
      <!-- this content will change based on the current route, which depends on the current URL -->
      {{outlet}}
    </div>
  
    <MyFooter />
    ```
  
    You may also specify a name for the `{{outlet}}`, which is useful when using more than one
    `{{outlet}}` in a template:
  
    ```app/templates/application.hbs
    {{outlet "menu"}}
    {{outlet "sidebar"}}
    {{outlet "main"}}
    ```
  
    Your routes can then render into a specific one of these `outlet`s by specifying the `outlet`
    attribute in your `renderTemplate` function:
  
    ```app/routes/menu.js
    import Route from '@ember/routing/route';
  
    export default class MenuRoute extends Route {
      renderTemplate() {
        this.render({ outlet: 'menu' });
      }
    }
    ```
  
    See the [routing guide](https://guides.emberjs.com/release/routing/rendering-a-template/) for more
    information on how your `route` interacts with the `{{outlet}}` helper.
    Note: Your content __will not render__ if there isn't an `{{outlet}}` for it.
  
    @method outlet
    @param {String} [name]
    @for Ember.Templates.helpers
    @public
  */


  function outletHelper(args, vm) {
    var scope = vm.dynamicScope();
    var nameRef;

    if (args.positional.length === 0) {
      nameRef = new _reference.ConstReference('main');
    } else {
      nameRef = args.positional.at(0);
    }

    return new OutletComponentReference(new OutletReference(scope.outletState, nameRef), vm.env);
  }

  class OutletModelReference extends _reference.RootReference {
    constructor(parent, env) {
      super(env);
      this.parent = parent;
      this.tag = parent.tag;
    }

    value() {
      var state = this.parent.value();

      if (state === undefined) {
        return undefined;
      }

      var {
        render
      } = state;

      if (render === undefined) {
        return undefined;
      }

      return render.model;
    }

  }

  if (true
  /* DEBUG */
  ) {
    OutletModelReference.prototype['debugLogName'] = '@model';
  }

  class OutletComponentReference {
    constructor(outletRef, env) {
      this.outletRef = outletRef;
      this.env = env;
      this.definition = null;
      this.lastState = null; // The router always dirties the root state.

      this.tag = outletRef.tag;
    }

    value() {
      var state = stateFor(this.outletRef);

      if (validate(state, this.lastState)) {
        return this.definition;
      }

      this.lastState = state;
      var definition = null;

      if (state !== null) {
        var args = makeArgs(this.outletRef, this.env);
        definition = (0, _runtime2.curry)(new OutletComponentDefinition(state), args);
      }

      return this.definition = definition;
    }

    get(_key) {
      return _runtime2.UNDEFINED_REFERENCE;
    }

  }

  function makeArgs(outletRef, env) {
    var tag = outletRef.tag;
    var modelRef = new OutletModelReference(outletRef, env);
    var map = (0, _util.dict)();
    map.model = modelRef; // TODO: the functionailty to create a proper CapturedArgument should be
    // exported by glimmer, or that it should provide an overload for `curry`
    // that takes `PreparedArguments`

    return {
      tag,
      positional: _runtime2.EMPTY_ARGS.positional,
      named: {
        tag,
        map,
        names: ['model'],
        references: [modelRef],
        length: 1,

        has(key) {
          return key === 'model';
        },

        get(key) {
          return key === 'model' ? modelRef : _runtime2.UNDEFINED_REFERENCE;
        },

        value() {
          var model = modelRef.value();
          return {
            model
          };
        }

      },
      length: 1,

      value() {
        return {
          named: this.named.value(),
          positional: this.positional.value()
        };
      }

    };
  }

  function stateFor(ref) {
    var outlet = ref.value();
    if (outlet === undefined) return null;
    var render = outlet.render;
    if (render === undefined) return null;
    var template$$1 = render.template;
    if (template$$1 === undefined) return null; // this guard can be removed once @ember/test-helpers@1.6.0 has "aged out"
    // and is no longer considered supported

    if (isTemplateFactory(template$$1)) {
      template$$1 = template$$1(render.owner);
    }

    return {
      ref,
      name: render.name,
      outlet: render.outlet,
      template: template$$1,
      controller: render.controller,
      model: render.model
    };
  }

  function validate(state, lastState) {
    if (state === null) {
      return lastState === null;
    }

    if (lastState === null) {
      return false;
    }

    return state.template === lastState.template && state.controller === lastState.controller;
  }

  var TEMPLATES = new WeakMap();
  var getPrototypeOf = Object.getPrototypeOf;

  function setComponentTemplate(factory, obj) {
    (true && !(obj !== null && (typeof obj === 'object' || typeof obj === 'function')) && (0, _debug.assert)(`Cannot call \`setComponentTemplate\` on \`${(0, _utils.toString)(obj)}\``, obj !== null && (typeof obj === 'object' || typeof obj === 'function')));
    (true && !(!TEMPLATES.has(obj)) && (0, _debug.assert)(`Cannot call \`setComponentTemplate\` multiple times on the same class (\`${obj}\`)`, !TEMPLATES.has(obj)));
    TEMPLATES.set(obj, factory);
    return obj;
  }

  function getComponentTemplate(obj) {
    var pointer = obj;

    while (pointer !== undefined && pointer !== null) {
      var _template = TEMPLATES.get(pointer);

      if (_template !== undefined) {
        return _template;
      }

      pointer = getPrototypeOf(pointer);
    }

    return null;
  }

  var MANAGERS = new WeakMap();
  var getPrototypeOf$1 = Object.getPrototypeOf;

  function setManager(wrapper, obj) {
    MANAGERS.set(obj, wrapper);
    return obj;
  }

  function getManager(obj) {
    var pointer = obj;

    while (pointer !== undefined && pointer !== null) {
      var manager = MANAGERS.get(pointer);

      if (manager !== undefined) {
        return manager;
      }

      pointer = getPrototypeOf$1(pointer);
    }

    return null;
  }

  function setModifierManager(factory, obj) {
    return setManager({
      factory,
      internal: false,
      type: 'modifier'
    }, obj);
  }

  function getModifierManager(obj) {
    var wrapper = getManager(obj);

    if (wrapper && !wrapper.internal && wrapper.type === 'modifier') {
      return wrapper.factory;
    } else {
      return undefined;
    }
  }

  function instrumentationPayload$1(name) {
    return {
      object: `component:${name}`
    };
  }

  function makeOptions(moduleName, namespace) {
    return {
      source: moduleName !== undefined ? `template:${moduleName}` : undefined,
      namespace
    };
  }

  function componentFor(name, owner, options) {
    var fullName = `component:${name}`;
    return owner.factoryFor(fullName, options) || null;
  }

  function layoutFor(name, owner, options) {
    var templateFullName = `template:components/${name}`;
    return owner.lookup(templateFullName, options) || null;
  }

  function lookupComponentPair(owner, name, options) {
    var component = componentFor(name, owner, options);
    {
      if (component !== null && component.class !== undefined) {
        var _layout = getComponentTemplate(component.class);

        if (_layout !== null) {
          return {
            component,
            layout: _layout
          };
        }
      }
    }
    var layout = layoutFor(name, owner, options);

    if (component === null && layout === null) {
      return null;
    } else {
      return {
        component,
        layout
      };
    }
  }

  function lookupComponent(owner, name, options) {
    if (options.source || options.namespace) {
      var pair = lookupComponentPair(owner, name, options);

      if (pair !== null) {
        return pair;
      }
    }

    return lookupComponentPair(owner, name);
  }

  var lookupPartial;
  var templateFor;
  var parseUnderscoredName;

  if (_deprecatedFeatures.PARTIALS) {
    lookupPartial = function (templateName, owner) {
      (true && !(false) && (0, _debug.deprecate)(`The use of \`{{partial}}\` is deprecated, please refactor the "${templateName}" partial to a component`, false, {
        id: 'ember-views.partial',
        until: '4.0.0',
        url: 'https://deprecations.emberjs.com/v3.x#toc_ember-views-partial'
      }));

      if (templateName === null) {
        return;
      }

      var template = templateFor(owner, parseUnderscoredName(templateName), templateName);
      (true && !(Boolean(template)) && (0, _debug.assert)(`Unable to find partial with name "${templateName}"`, Boolean(template)));
      return template;
    };

    templateFor = function (owner, underscored, name) {
      if (_deprecatedFeatures.PARTIALS) {
        if (!name) {
          return;
        }

        (true && !(name.indexOf('.') === -1) && (0, _debug.assert)(`templateNames are not allowed to contain periods: ${name}`, name.indexOf('.') === -1));

        if (!owner) {
          throw new _error.default('Container was not found when looking up a views template. ' + 'This is most likely due to manually instantiating an Ember.View. ' + 'See: http://git.io/EKPpnA');
        }

        return owner.lookup(`template:${underscored}`) || owner.lookup(`template:${name}`);
      }
    };

    parseUnderscoredName = function (templateName) {
      var nameParts = templateName.split('/');
      var lastPart = nameParts[nameParts.length - 1];
      nameParts[nameParts.length - 1] = `_${lastPart}`;
      return nameParts.join('/');
    };
  }

  var BUILTINS_HELPERS = {
    if: inlineIf,
    action,
    array,
    concat: concat$1,
    fn: fn$1,
    get: get$1,
    hash,
    log: log$1,
    mut,
    'query-params': queryParams$1,
    readonly,
    unbound,
    unless: inlineUnless,
    '-hash': hash,
    '-each-in': eachIn,
    '-normalize-class': normalizeClassHelper,
    '-track-array': trackArray,
    '-get-dynamic-var': _runtime2.getDynamicVar,
    '-mount': mountHelper,
    '-outlet': outletHelper,
    '-assert-implicit-component-helper-argument': componentAssertionHelper,
    '-in-el-null': inElementNullCheckHelper
  };

  class RuntimeResolver {
    constructor(owner, isInteractive) {
      this.handles = [undefined];
      this.objToHandle = new WeakMap();
      this.builtInHelpers = BUILTINS_HELPERS;
      this.componentDefinitionCache = new Map();
      this.componentDefinitionCount = 0;
      this.helperDefinitionCount = 0;
      this.isInteractive = isInteractive;
      this.builtInModifiers = {
        action: {
          manager: new ActionModifierManager(),
          state: null
        },
        on: {
          manager: new OnModifierManager(owner, isInteractive),
          state: null
        }
      };
    }
    /***  IRuntimeResolver ***/

    /**
     * public componentDefHandleCount = 0;
     * Called while executing Append Op.PushDynamicComponentManager if string
     */


    lookupComponent(name, meta) {
      var handle = this.lookupComponentHandle(name, meta);

      if (handle === null) {
        (true && !(false) && (0, _debug.assert)(`Could not find component named "${name}" (no component or template with that name was found)`));
        return null;
      }

      return this.resolve(handle);
    }

    lookupComponentHandle(name, meta) {
      var nextHandle = this.handles.length;
      var handle = this.handle(this._lookupComponentDefinition(name, meta));
      (true && !(!(name === 'text-area' && handle === null)) && (0, _debug.assert)('Could not find component `<TextArea />` (did you mean `<Textarea />`?)', !(name === 'text-area' && handle === null)));

      if (nextHandle === handle) {
        this.componentDefinitionCount++;
      }

      return handle;
    }
    /**
     * Called by RuntimeConstants to lookup unresolved handles.
     */


    resolve(handle) {
      return this.handles[handle];
    } // End IRuntimeResolver

    /**
     * Called by CompileTimeLookup compiling Unknown or Helper OpCode
     */


    lookupHelper(name, meta) {
      var nextHandle = this.handles.length;

      var helper$$1 = this._lookupHelper(name, meta);

      if (helper$$1 !== null) {
        var handle = this.handle(helper$$1);

        if (nextHandle === handle) {
          this.helperDefinitionCount++;
        }

        return handle;
      }

      return null;
    }
    /**
     * Called by CompileTimeLookup compiling the
     */


    lookupModifier(name, meta) {
      return this.handle(this._lookupModifier(name, meta));
    }
    /**
     * Called by CompileTimeLookup to lookup partial
     */


    lookupPartial(name, meta) {
      if (_deprecatedFeatures.PARTIALS) {
        var partial = this._lookupPartial(name, meta);

        return this.handle(partial);
      } else {
        return null;
      }
    } // TODO: This isn't necessary in all embedding environments, we should likely
    // make it optional within Glimmer-VM


    compilable() {} // end CompileTimeLookup
    // needed for lazy compile time lookup


    handle(obj) {
      if (obj === undefined || obj === null) {
        return null;
      }

      var handle = this.objToHandle.get(obj);

      if (handle === undefined) {
        handle = this.handles.push(obj) - 1;
        this.objToHandle.set(obj, handle);
      }

      return handle;
    }

    _lookupHelper(_name, meta) {
      (true && !(!(this.builtInHelpers[_name] && meta.owner.hasRegistration(`helper:${_name}`))) && (0, _debug.assert)(`You attempted to overwrite the built-in helper "${_name}" which is not allowed. Please rename the helper.`, !(this.builtInHelpers[_name] && meta.owner.hasRegistration(`helper:${_name}`))));
      var helper$$1 = this.builtInHelpers[_name];

      if (helper$$1 !== undefined) {
        return helper$$1;
      }

      var {
        moduleName
      } = meta;
      var owner = meta.owner;
      var name = _name;
      var namespace = undefined;
      var options = makeOptions(moduleName, namespace);
      var factory = owner.factoryFor(`helper:${name}`, options) || owner.factoryFor(`helper:${name}`);

      if (!isHelperFactory(factory)) {
        return null;
      }

      return (args, vm) => {
        var helper$$1 = factory.create();

        if (isClassHelper(helper$$1)) {
          var helperDestroyable = {}; // Do this so that `destroy` gets called correctly

          (0, _runtime2.registerDestructor)(helperDestroyable, () => helper$$1.destroy(), true);
          vm.associateDestroyable(helperDestroyable);
        } else if (true
        /* DEBUG */
        ) {
          // Bind to null in case someone accidentally passed an unbound function
          // in, and attempts use `this` on it.
          //
          // TODO: Update buildUntouchableThis to be flexible enough to provide a
          // nice error message here.
          helper$$1.compute = helper$$1.compute.bind(null);
        }

        return new EmberHelperRootReference(helper$$1, args.capture(), vm.env);
      };
    }

    _lookupPartial(name, meta) {
      var owner = meta.owner;
      var templateFactory$$1 = lookupPartial(name, owner);
      var template = templateFactory$$1(owner);
      return new _opcodeCompiler.PartialDefinitionImpl(name, template);
    }

    _lookupModifier(name, meta) {
      var builtin = this.builtInModifiers[name];

      if (builtin === undefined) {
        var owner = meta.owner;
        var modifier = owner.factoryFor(`modifier:${name}`);

        if (modifier !== undefined) {
          var managerFactory = getModifierManager(modifier.class);
          var manager = managerFactory(owner);
          return new CustomModifierDefinition(name, modifier, manager, this.isInteractive);
        }
      }

      return builtin;
    }

    _parseNameForNamespace(_name) {
      var name = _name;
      var namespace = undefined;

      var namespaceDelimiterOffset = _name.indexOf('::');

      if (namespaceDelimiterOffset !== -1) {
        name = _name.slice(namespaceDelimiterOffset + 2);
        namespace = _name.slice(0, namespaceDelimiterOffset);
      }

      return {
        name,
        namespace
      };
    }

    _lookupComponentDefinition(_name, meta) {
      var name = _name;
      var namespace = undefined;
      var owner = meta.owner;
      var {
        moduleName
      } = meta;
      var pair = lookupComponent(owner, name, makeOptions(moduleName, namespace));

      if (pair === null) {
        return null;
      }

      var layout;
      var key;

      if (pair.component === null) {
        key = layout = pair.layout(owner);
      } else {
        key = pair.component;
      }

      var cachedComponentDefinition = this.componentDefinitionCache.get(key);

      if (cachedComponentDefinition !== undefined) {
        return cachedComponentDefinition;
      }

      if (layout === undefined && pair.layout !== null) {
        layout = pair.layout(owner);
      }

      var finalizer = (0, _instrumentation._instrumentStart)('render.getComponentDefinition', instrumentationPayload$1, name);
      var definition = null;

      if (pair.component === null) {
        if (_environment2.ENV._TEMPLATE_ONLY_GLIMMER_COMPONENTS) {
          definition = new TemplateOnlyComponentDefinition(name, layout);
        }
      } else if (true
      /* EMBER_GLIMMER_SET_COMPONENT_TEMPLATE */
      && (0, _templateOnly.isTemplateOnlyComponent)(pair.component.class)) {
        definition = new TemplateOnlyComponentDefinition(name, layout);
      }

      if (pair.component !== null) {
        (true && !(pair.component.class !== undefined) && (0, _debug.assert)(`missing component class ${name}`, pair.component.class !== undefined));
        var ComponentClass = pair.component.class;
        var wrapper = getManager(ComponentClass);

        if (wrapper !== null && wrapper.type === 'component') {
          var {
            factory
          } = wrapper;

          if (wrapper.internal) {
            (true && !(pair.layout !== null) && (0, _debug.assert)(`missing layout for internal component ${name}`, pair.layout !== null));
            definition = new InternalComponentDefinition(factory(owner), ComponentClass, layout);
          } else {
            definition = new CustomManagerDefinition(name, pair.component, factory(owner), layout !== undefined ? layout : owner.lookup((0, _container.privatize)`template:components/-default`)(owner));
          }
        }
      }

      if (definition === null) {
        definition = new CurlyComponentDefinition(name, pair.component || owner.factoryFor((0, _container.privatize)`component:-default`), layout);
      }

      finalizer();
      this.componentDefinitionCache.set(key, definition);
      return definition;
    }

  }

  function hashToArgs(hash) {
    if (hash === null) return null;
    var names = hash[0].map(key => `@${key}`);
    return [names, hash[1]];
  }

  var experimentalMacros = []; // This is a private API to allow for experimental macros
  // to be created in user space. Registering a macro should
  // should be done in an initializer.

  _exports._experimentalMacros = experimentalMacros;

  function registerMacros(macro) {
    experimentalMacros.push(macro);
  }

  function refineInlineSyntax(name, params, hash, context) {
    var component = context.resolver.lookupComponent(name, context.meta.referrer);

    if (component !== null) {
      return (0, _opcodeCompiler.staticComponent)(component, [params === null ? [] : params, hashToArgs(hash), _opcodeCompiler.EMPTY_BLOCKS]);
    }

    return _opcodeCompiler.UNHANDLED;
  }

  function refineBlockSyntax(name, params, hash, blocks, context) {
    var handle = context.resolver.lookupComponent(name, context.meta.referrer);

    if (handle !== null) {
      return (0, _opcodeCompiler.staticComponent)(handle, [params, hashToArgs(hash), blocks]);
    }

    (true && !(context.meta.referrer.owner.hasRegistration(`helper:${name}`)) && (0, _debug.assert)(`A component or helper named "${name}" could not be found`, context.meta.referrer.owner.hasRegistration(`helper:${name}`)));
    (true && !(!(() => {
      var resolver = context.resolver['inner']['resolver'];
      var {
        moduleName,
        owner
      } = context.meta.referrer;

      if (name === 'component' || resolver['builtInHelpers'][name]) {
        return true;
      }

      var options = {
        source: `template:${moduleName}`
      };
      return owner.hasRegistration(`helper:${name}`, options) || owner.hasRegistration(`helper:${name}`);
    })()) && (0, _debug.assert)(`Helpers may not be used in the block form, for example {{#${name}}}{{/${name}}}. Please use a component, or alternatively use the helper in combination with a built-in Ember helper, for example {{#if (${name})}}{{/if}}.`, !(() => {
      var resolver = context.resolver['inner']['resolver'];
      var {
        moduleName,
        owner
      } = context.meta.referrer;

      if (name === 'component' || resolver['builtInHelpers'][name]) {
        return true;
      }

      var options = {
        source: `template:${moduleName}`
      };
      return owner.hasRegistration(`helper:${name}`, options) || owner.hasRegistration(`helper:${name}`);
    })()));
    return _opcodeCompiler.NONE;
  }

  function populateMacros(macros) {
    var {
      inlines,
      blocks
    } = macros;
    inlines.addMissing(refineInlineSyntax);
    blocks.addMissing(refineBlockSyntax);

    for (var i = 0; i < experimentalMacros.length; i++) {
      var macro = experimentalMacros[i];
      macro(blocks, inlines);
    }

    return {
      blocks,
      inlines
    };
  }

  class DynamicScope {
    constructor(view, outletState) {
      this.view = view;
      this.outletState = outletState;
    }

    child() {
      return new DynamicScope(this.view, this.outletState);
    }

    get(key) {
      // tslint:disable-next-line:max-line-length
      (true && !(key === 'outletState') && (0, _debug.assert)(`Using \`-get-dynamic-scope\` is only supported for \`outletState\` (you used \`${key}\`).`, key === 'outletState'));
      return this.outletState;
    }

    set(key, value) {
      // tslint:disable-next-line:max-line-length
      (true && !(key === 'outletState') && (0, _debug.assert)(`Using \`-with-dynamic-scope\` is only supported for \`outletState\` (you used \`${key}\`).`, key === 'outletState'));
      this.outletState = value;
      return value;
    }

  }

  class RootState {
    constructor(root, runtime, context, template, self, parentElement, dynamicScope, builder) {
      this.root = root;
      this.runtime = runtime;
      (true && !(template !== undefined) && (0, _debug.assert)(`You cannot render \`${self.value()}\` without a template.`, template !== undefined));
      this.id = (0, _views.getViewId)(root);
      this.result = undefined;
      this.destroyed = false;

      this.render = () => {
        var layout = (0, _util.unwrapTemplate)(template).asLayout();
        var handle = layout.compile(context);
        var iterator = (0, _runtime2.renderJitMain)(runtime, context, self, builder(runtime.env, {
          element: parentElement,
          nextSibling: null
        }), (0, _util.unwrapHandle)(handle), dynamicScope);
        var iteratorResult;

        do {
          iteratorResult = iterator.next();
        } while (!iteratorResult.done);

        var result = this.result = iteratorResult.value; // override .render function after initial render

        this.render = () => result.rerender({
          alwaysRevalidate: false
        });
      };
    }

    isFor(possibleRoot) {
      return this.root === possibleRoot;
    }

    destroy() {
      var {
        result,
        runtime: {
          env
        }
      } = this;
      this.destroyed = true;
      this.runtime = undefined;
      this.root = null;
      this.result = undefined;
      this.render = undefined;

      if (result !== undefined) {
        /*
         Handles these scenarios:
                * When roots are removed during standard rendering process, a transaction exists already
           `.begin()` / `.commit()` are not needed.
         * When roots are being destroyed manually (`component.append(); component.destroy() case), no
           transaction exists already.
         * When roots are being destroyed during `Renderer#destroy`, no transaction exists
                */
        (0, _runtime2.inTransaction)(env, () => (0, _runtime2.destroy)(result));
      }
    }

  }

  var renderers = [];

  function _resetRenderers() {
    renderers.length = 0;
  }

  function register(renderer) {
    (true && !(renderers.indexOf(renderer) === -1) && (0, _debug.assert)('Cannot register the same renderer twice', renderers.indexOf(renderer) === -1));
    renderers.push(renderer);
  }

  function deregister(renderer) {
    var index = renderers.indexOf(renderer);
    (true && !(index !== -1) && (0, _debug.assert)('Cannot deregister unknown unregistered renderer', index !== -1));
    renderers.splice(index, 1);
  }

  function loopBegin() {
    for (var i = 0; i < renderers.length; i++) {
      renderers[i]._scheduleRevalidate();
    }
  }

  function K() {
    /* noop */
  }

  var renderSettledDeferred = null;
  /*
    Returns a promise which will resolve when rendering has settled. Settled in
    this context is defined as when all of the tags in use are "current" (e.g.
    `renderers.every(r => r._isValid())`). When this is checked at the _end_ of
    the run loop, this essentially guarantees that all rendering is completed.
  
    @method renderSettled
    @returns {Promise<void>} a promise which fulfills when rendering has settled
  */

  function renderSettled() {
    if (renderSettledDeferred === null) {
      renderSettledDeferred = _rsvp.default.defer(); // if there is no current runloop, the promise created above will not have
      // a chance to resolve (because its resolved in backburner's "end" event)

      if (!(0, _runloop.getCurrentRunLoop)()) {
        // ensure a runloop has been kicked off
        _runloop.backburner.schedule('actions', null, K);
      }
    }

    return renderSettledDeferred.promise;
  }

  function resolveRenderPromise() {
    if (renderSettledDeferred !== null) {
      var resolve = renderSettledDeferred.resolve;
      renderSettledDeferred = null;

      _runloop.backburner.join(null, resolve);
    }
  }

  var loops = 0;

  function loopEnd() {
    for (var i = 0; i < renderers.length; i++) {
      if (!renderers[i]._isValid()) {
        if (loops > _environment2.ENV._RERENDER_LOOP_LIMIT) {
          loops = 0; // TODO: do something better

          renderers[i].destroy();
          throw new Error('infinite rendering invalidation detected');
        }

        loops++;
        return _runloop.backburner.join(null, K);
      }
    }

    loops = 0;
    resolveRenderPromise();
  }

  _runloop.backburner.on('begin', loopBegin);

  _runloop.backburner.on('end', loopEnd);

  class Renderer {
    constructor(owner, document, env, rootTemplate, viewRegistry, destinedForDOM = false, builder = _runtime2.clientBuilder) {
      this._inRenderTransaction = false;
      this._lastRevision = -1;
      this._destroyed = false;
      this._rootTemplate = rootTemplate(owner);
      this._viewRegistry = viewRegistry;
      this._destinedForDOM = destinedForDOM;
      this._roots = [];
      this._removedRoots = [];
      this._builder = builder; // resolver is exposed for tests

      var runtimeResolver = this._runtimeResolver = new RuntimeResolver(owner, env.isInteractive);
      var compileTimeResolver = new CompileTimeResolver(runtimeResolver);
      var context = this._context = (0, _opcodeCompiler.JitContext)(compileTimeResolver);
      populateMacros(context.macros);
      var runtimeEnvironmentDelegate = new EmberEnvironmentDelegate(owner, env.isInteractive);
      this._runtime = (0, _runtime2.JitRuntime)({
        appendOperations: env.hasDOM ? new _runtime2.DOMTreeConstruction(document) : new _node.NodeDOMTreeConstruction(document),
        updateOperations: new _runtime2.DOMChanges(document)
      }, runtimeEnvironmentDelegate, context, runtimeResolver);
    }

    get debugRenderTree() {
      return this._runtime.env.extra.debugRenderTree;
    } // renderer HOOKS


    appendOutletView(view, target) {
      var definition = createRootOutlet(view);

      this._appendDefinition(view, (0, _runtime2.curry)(definition), target);
    }

    appendTo(view, target) {
      var definition = new RootComponentDefinition(view);

      this._appendDefinition(view, (0, _runtime2.curry)(definition), target);
    }

    _appendDefinition(root, definition, target) {
      var self = new UnboundRootReference(definition, this._runtime.env);
      var dynamicScope = new DynamicScope(null, _runtime2.UNDEFINED_REFERENCE);
      var rootState = new RootState(root, this._runtime, this._context, this._rootTemplate, self, target, dynamicScope, this._builder);

      this._renderRoot(rootState);
    }

    rerender() {
      this._scheduleRevalidate();
    }

    register(view) {
      var id = (0, _views.getViewId)(view);
      (true && !(!this._viewRegistry[id]) && (0, _debug.assert)('Attempted to register a view with an id already in use: ' + id, !this._viewRegistry[id]));
      this._viewRegistry[id] = view;
    }

    unregister(view) {
      delete this._viewRegistry[(0, _views.getViewId)(view)];
    }

    remove(view) {
      view._transitionTo('destroying');

      this.cleanupRootFor(view);

      if (this._destinedForDOM) {
        view.trigger('didDestroyElement');
      }
    }

    cleanupRootFor(view) {
      // no need to cleanup roots if we have already been destroyed
      if (this._destroyed) {
        return;
      }

      var roots = this._roots; // traverse in reverse so we can remove items
      // without mucking up the index

      var i = this._roots.length;

      while (i--) {
        var root = roots[i];

        if (root.isFor(view)) {
          root.destroy();
          roots.splice(i, 1);
        }
      }
    }

    destroy() {
      if (this._destroyed) {
        return;
      }

      this._destroyed = true;

      this._clearAllRoots();
    }

    getBounds(view) {
      var bounds = view[BOUNDS];
      (true && !(Boolean(bounds)) && (0, _debug.assert)('object passed to getBounds must have the BOUNDS symbol as a property', Boolean(bounds)));
      var parentElement = bounds.parentElement();
      var firstNode = bounds.firstNode();
      var lastNode = bounds.lastNode();
      return {
        parentElement,
        firstNode,
        lastNode
      };
    }

    createElement(tagName) {
      return this._runtime.env.getAppendOperations().createElement(tagName);
    }

    _renderRoot(root) {
      var {
        _roots: roots
      } = this;
      roots.push(root);

      if (roots.length === 1) {
        register(this);
      }

      this._renderRootsTransaction();
    }

    _renderRoots() {
      var {
        _roots: roots,
        _runtime: runtime,
        _removedRoots: removedRoots
      } = this;
      var initialRootsLength;

      do {
        initialRootsLength = roots.length;
        (0, _runtime2.inTransaction)(runtime.env, () => {
          // ensure that for the first iteration of the loop
          // each root is processed
          for (var i = 0; i < roots.length; i++) {
            var root = roots[i];

            if (root.destroyed) {
              // add to the list of roots to be removed
              // they will be removed from `this._roots` later
              removedRoots.push(root); // skip over roots that have been marked as destroyed

              continue;
            } // when processing non-initial reflush loops,
            // do not process more roots than needed


            if (i >= initialRootsLength) {
              continue;
            }

            if (true
            /* DEBUG */
            ) {
              // run in an autotracking transaction to prevent backflow errors.
              // we use `bind` here to avoid creating a closure (and requiring a
              // hoisted variable).
              (0, _validator.runInAutotrackingTransaction)(root.render.bind(root));
            } else {
              root.render();
            }
          }

          this._lastRevision = (0, _validator.valueForTag)(_validator.CURRENT_TAG);
        });
      } while (roots.length > initialRootsLength); // remove any roots that were destroyed during this transaction


      while (removedRoots.length) {
        var root = removedRoots.pop();
        var rootIndex = roots.indexOf(root);
        roots.splice(rootIndex, 1);
      }

      if (this._roots.length === 0) {
        deregister(this);
      }
    }

    _renderRootsTransaction() {
      if (this._inRenderTransaction) {
        // currently rendering roots, a new root was added and will
        // be processed by the existing _renderRoots invocation
        return;
      } // used to prevent calling _renderRoots again (see above)
      // while we are actively rendering roots


      this._inRenderTransaction = true;
      var completedWithoutError = false;

      try {
        this._renderRoots();

        completedWithoutError = true;
      } finally {
        if (!completedWithoutError) {
          this._lastRevision = (0, _validator.valueForTag)(_validator.CURRENT_TAG);
        }

        this._inRenderTransaction = false;
      }
    }

    _clearAllRoots() {
      var roots = this._roots;

      for (var i = 0; i < roots.length; i++) {
        var root = roots[i];
        root.destroy();
      }

      this._removedRoots.length = 0;
      this._roots = []; // if roots were present before destroying
      // deregister this renderer instance

      if (roots.length) {
        deregister(this);
      }
    }

    _scheduleRevalidate() {
      _runloop.backburner.scheduleOnce('render', this, this._revalidate);
    }

    _isValid() {
      return this._destroyed || this._roots.length === 0 || (0, _validator.validateTag)(_validator.CURRENT_TAG, this._lastRevision);
    }

    _revalidate() {
      if (this._isValid()) {
        return;
      }

      this._renderRootsTransaction();
    }

  }

  _exports.Renderer = Renderer;

  class InertRenderer extends Renderer {
    static create(props) {
      var {
        document,
        env,
        rootTemplate,
        _viewRegistry,
        builder
      } = props;
      return new this((0, _owner.getOwner)(props), document, env, rootTemplate, _viewRegistry, false, builder);
    }

    getElement(_view) {
      throw new Error('Accessing `this.element` is not allowed in non-interactive environments (such as FastBoot).');
    }

  }

  _exports.InertRenderer = InertRenderer;

  class InteractiveRenderer extends Renderer {
    static create(props) {
      var {
        document,
        env,
        rootTemplate,
        _viewRegistry,
        builder
      } = props;
      return new this((0, _owner.getOwner)(props), document, env, rootTemplate, _viewRegistry, true, builder);
    }

    getElement(view) {
      return (0, _views.getViewElement)(view);
    }

  }

  _exports.InteractiveRenderer = InteractiveRenderer;
  var TEMPLATES$1 = {};

  function setTemplates(templates) {
    TEMPLATES$1 = templates;
  }

  function getTemplates() {
    return TEMPLATES$1;
  }

  function getTemplate(name) {
    if (Object.prototype.hasOwnProperty.call(TEMPLATES$1, name)) {
      return TEMPLATES$1[name];
    }
  }

  function hasTemplate(name) {
    return Object.prototype.hasOwnProperty.call(TEMPLATES$1, name);
  }

  function setTemplate(name, template) {
    return TEMPLATES$1[name] = template;
  }

  var CAPABILITIES$4 = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: true,
    createArgs: true,
    attributeHook: false,
    elementHook: false,
    createCaller: true,
    dynamicScope: false,
    updateHook: true,
    createInstance: true,
    wrapped: false,
    willDestroy: false
  };
  var EMPTY_POSITIONAL_ARGS$1 = [];
  (0, _debug.debugFreeze)(EMPTY_POSITIONAL_ARGS$1);

  class InputComponentManager extends InternalManager {
    getCapabilities() {
      return CAPABILITIES$4;
    }

    prepareArgs(_state, args) {
      (true && !(args.positional.length === 0) && (0, _debug.assert)('The `<Input />` component does not take any positional arguments', args.positional.length === 0));
      var __ARGS__ = args.named.capture().map;
      return {
        positional: EMPTY_POSITIONAL_ARGS$1,
        named: {
          __ARGS__: new _reference.ConstReference(__ARGS__),
          type: args.named.get('type')
        }
      };
    }

    create(env, {
      ComponentClass,
      layout
    }, args, _dynamicScope, caller) {
      (true && !((0, _validator.isConstTagged)(caller)) && (0, _debug.assert)('caller must be const', (0, _validator.isConstTagged)(caller)));
      var type = args.named.get('type');
      var instance = ComponentClass.create({
        caller: caller.value(),
        type: type.value()
      });
      var state = {
        env,
        type,
        instance
      };

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        env.extra.debugRenderTree.create(state, {
          type: 'component',
          name: 'input',
          args: args.capture(),
          instance,
          template: layout
        });
        (0, _runtime2.registerDestructor)(instance, () => env.extra.debugRenderTree.willDestroy(state));
      }

      return state;
    }

    getSelf({
      env,
      instance
    }) {
      return new _reference.ComponentRootReference(instance, env);
    }

    getTag() {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        // returning a const tag skips the update hook (VM BUG?)
        return (0, _validator.createTag)();
      } else {
        // an outlet has no hooks
        return _validator.CONSTANT_TAG;
      }
    }

    didRenderLayout(state, bounds) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        state.env.extra.debugRenderTree.didRender(state, bounds);
      }
    }

    update(state) {
      (0, _metal.set)(state.instance, 'type', state.type.value());

      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        state.env.extra.debugRenderTree.update(state);
      }
    }

    didUpdateLayout(state, bounds) {
      if (_environment2.ENV._DEBUG_RENDER_TREE) {
        state.env.extra.debugRenderTree.didRender(state, bounds);
      }
    }

    getDestroyable(state) {
      return state.instance;
    }

  }

  var InputComponentManagerFactory = owner => {
    return new InputComponentManager(owner);
  };
  /**
  @module @ember/component
  */

  /**
    See [Ember.Templates.components.Input](/ember/release/classes/Ember.Templates.components/methods/Input?anchor=Input).
  
    @method input
    @for Ember.Templates.helpers
    @param {Hash} options
    @public
    */

  /**
    The `Input` component lets you create an HTML `<input>` element.
  
    ```handlebars
    <Input @value="987" />
    ```
  
    creates an `<input>` element with `type="text"` and value set to 987.
  
    ### Text field
  
    If no `type` argument is specified, a default of type 'text' is used.
  
    ```handlebars
    Search:
    <Input @value={{this.searchWord}} />
    ```
  
    In this example, the initial value in the `<input>` will be set to the value of
    `this.searchWord`. If the user changes the text, the value of `this.searchWord` will also be
    updated.
  
    ### Actions
  
    The `Input` component takes a number of arguments with callbacks that are invoked in response to
    user events.
  
    * `enter`
    * `insert-newline`
    * `escape-press`
    * `focus-in`
    * `focus-out`
    * `key-down`
    * `key-press`
    * `key-up`
  
    These callbacks are passed to `Input` like this:
  
    ```handlebars
    <Input @value={{this.searchWord}} @enter={{this.query}} />
    ```
  
    ### `<input>` HTML Attributes to Avoid
  
    In most cases, if you want to pass an attribute to the underlying HTML `<input>` element, you
    can pass the attribute directly, just like any other Ember component.
  
    ```handlebars
    <Input @type="text" size="10" />
    ```
  
    In this example, the `size` attribute will be applied to the underlying `<input>` element in the
    outputted HTML.
  
    However, there are a few attributes where you **must** use the `@` version.
  
    * `@type`: This argument is used to control which Ember component is used under the hood
    * `@value`: The `@value` argument installs a two-way binding onto the element. If you wanted a
      one-way binding, use `<input>` with the `value` property and the `input` event instead.
    * `@checked` (for checkboxes): like `@value`, the `@checked` argument installs a two-way binding
      onto the element. If you wanted a one-way binding, use `<input type="checkbox">` with
      `checked` and the `input` event instead.
  
    ### Extending `TextField`
  
    Internally, `<Input @type="text" />` creates an instance of `TextField`, passing arguments from
    the helper to `TextField`'s `create` method. Subclassing `TextField` is supported but not
    recommended.
  
    See [TextField](/ember/release/classes/TextField)
  
    ### Checkbox
  
    To create an `<input type="checkbox">`:
  
    ```handlebars
    Emberize Everything:
    <Input @type="checkbox" @checked={{this.isEmberized}} name="isEmberized" />
    ```
  
    This will bind the checked state of this checkbox to the value of `isEmberized` -- if either one
    changes, it will be reflected in the other.
  
    ### Extending `Checkbox`
  
    Internally, `<Input @type="checkbox" />` creates an instance of `Checkbox`. Subclassing
    `TextField` is supported but not recommended.
  
    See [Checkbox](/ember/release/classes/Checkbox)
  
    @method Input
    @for Ember.Templates.components
    @see {TextField}
    @see {Checkbox}
    @param {Hash} options
    @public
  */


  var Input = _runtime.Object.extend({
    isCheckbox: (0, _metal.computed)('type', function () {
      return this.type === 'checkbox';
    })
  });

  setManager({
    factory: InputComponentManagerFactory,
    internal: true,
    type: 'component'
  }, Input);

  Input.toString = () => '@ember/component/input';
  /**
  @module ember
  */

  /**
    Calls [String.loc](/ember/release/classes/String/methods/loc?anchor=loc) with the
    provided string. This is a convenient way to localize text within a template.
    For example:
  
    ```javascript
    Ember.STRINGS = {
      '_welcome_': 'Bonjour'
    };
    ```
  
    ```handlebars
    <div class='message'>
      {{loc '_welcome_'}}
    </div>
    ```
  
    ```html
    <div class='message'>
      Bonjour
    </div>
    ```
  
    See [String.loc](/ember/release/classes/String/methods/loc?anchor=loc) for how to
    set up localized string references.
  
    @method loc
    @for Ember.Templates.helpers
    @param {String} str The string to format.
    @see {String#loc}
    @public
  */


  var loc$1 = helper(function (params) {
    return _string.loc.apply(null, params
    /* let the other side handle errors */
    );
  });
  var ComponentTemplate = template({
    "id": "RLf1peEf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/component.hbs"
    }
  });
  var InputTemplate = template({
    "id": "ExnzE3OS",
    "block": "{\"symbols\":[\"Checkbox\",\"TextField\",\"@__ARGS__\",\"&attrs\"],\"statements\":[[6,[37,2],[[30,[36,1],[\"-checkbox\"],null],[30,[36,1],[\"-text-field\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,0,[\"isCheckbox\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[8,[32,1],[[17,4]],[[\"@target\",\"@__ARGS__\"],[[32,0,[\"caller\"]],[32,3]]],null]],\"parameters\":[]},{\"statements\":[[8,[32,2],[[17,4]],[[\"@target\",\"@__ARGS__\"],[[32,0,[\"caller\"]],[32,3]]],null]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/input.hbs"
    }
  });
  var OutletTemplate = template({
    "id": "vA+C0Wde",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/outlet.hbs"
    }
  });
  var TOP_LEVEL_NAME = '-top-level';
  var TOP_LEVEL_OUTLET = 'main';

  class OutletView {
    constructor(_environment, renderer, owner, template) {
      this._environment = _environment;
      this.renderer = renderer;
      this.owner = owner;
      this.template = template;
      var ref = this.ref = new RootOutletReference({
        outlets: {
          main: undefined
        },
        render: {
          owner: owner,
          into: undefined,
          outlet: TOP_LEVEL_OUTLET,
          name: TOP_LEVEL_NAME,
          controller: undefined,
          model: undefined,
          template
        }
      });
      this.state = {
        ref,
        name: TOP_LEVEL_NAME,
        outlet: TOP_LEVEL_OUTLET,
        template,
        controller: undefined,
        model: undefined
      };
    }

    static extend(injections) {
      return class extends OutletView {
        static create(options) {
          if (options) {
            return super.create((0, _polyfills.assign)({}, injections, options));
          } else {
            return super.create(injections);
          }
        }

      };
    }

    static reopenClass(injections) {
      (0, _polyfills.assign)(this, injections);
    }

    static create(options) {
      var {
        _environment,
        renderer,
        template: templateFactory$$1
      } = options;
      var owner = (0, _owner.getOwner)(options);
      var template = templateFactory$$1(owner);
      return new OutletView(_environment, renderer, owner, template);
    }

    appendTo(selector) {
      var target;

      if (this._environment.hasDOM) {
        target = typeof selector === 'string' ? document.querySelector(selector) : selector;
      } else {
        target = selector;
      }

      (0, _runloop.schedule)('render', this.renderer, 'appendOutletView', this, target);
    }

    rerender() {
      /**/
    }

    setOutletState(state) {
      this.ref.update(state);
    }

    destroy() {
      /**/
    }

  }

  _exports.OutletView = OutletView;

  function setupApplicationRegistry(registry) {
    registry.injection('renderer', 'env', '-environment:main'); // because we are using injections we can't use instantiate false
    // we need to use bind() to copy the function so factory for
    // association won't leak

    registry.register('service:-dom-builder', {
      create({
        bootOptions
      }) {
        var {
          _renderMode
        } = bootOptions;

        switch (_renderMode) {
          case 'serialize':
            return _node.serializeBuilder.bind(null);

          case 'rehydrate':
            return _runtime2.rehydrationBuilder.bind(null);

          default:
            return _runtime2.clientBuilder.bind(null);
        }
      }

    });
    registry.injection('service:-dom-builder', 'bootOptions', '-environment:main');
    registry.injection('renderer', 'builder', 'service:-dom-builder');
    registry.register((0, _container.privatize)`template:-root`, RootTemplate);
    registry.injection('renderer', 'rootTemplate', (0, _container.privatize)`template:-root`);
    registry.register('renderer:-dom', InteractiveRenderer);
    registry.register('renderer:-inert', InertRenderer);
    registry.injection('renderer', 'document', 'service:-document');
  }

  function setupEngineRegistry(registry) {
    registry.optionsForType('template', {
      instantiate: false
    });
    registry.register('view:-outlet', OutletView);
    registry.register('template:-outlet', OutletTemplate);
    registry.injection('view:-outlet', 'template', 'template:-outlet');
    registry.register((0, _container.privatize)`template:components/-default`, ComponentTemplate);
    registry.optionsForType('helper', {
      instantiate: false
    });
    registry.register('helper:loc', loc$1);
    registry.register('component:-text-field', TextField);
    registry.register('component:-checkbox', Checkbox);
    registry.register('component:link-to', LinkComponent);
    registry.register('component:input', Input);
    registry.register('template:components/input', InputTemplate);
    registry.register('component:textarea', TextArea);

    if (!_environment2.ENV._TEMPLATE_ONLY_GLIMMER_COMPONENTS) {
      registry.register((0, _container.privatize)`component:-default`, Component);
    }
  }

  function setComponentManager(stringOrFunction, obj) {
    var factory;

    if (_deprecatedFeatures.COMPONENT_MANAGER_STRING_LOOKUP && typeof stringOrFunction === 'string') {
      (true && !(false) && (0, _debug.deprecate)('Passing the name of the component manager to "setupComponentManager" is deprecated. Please pass a function that produces an instance of the manager.', false, {
        id: 'deprecate-string-based-component-manager',
        until: '4.0.0',
        url: 'https://emberjs.com/deprecations/v3.x/#toc_component-manager-string-lookup'
      }));

      factory = function (owner) {
        return owner.lookup(`component-manager:${stringOrFunction}`);
      };
    } else {
      factory = stringOrFunction;
    }

    return setManager({
      factory,
      internal: false,
      type: 'component'
    }, obj);
  }

  function getComponentManager(obj) {
    var wrapper = getManager(obj);

    if (wrapper && !wrapper.internal && wrapper.type === 'component') {
      return wrapper.factory;
    } else {
      return undefined;
    }
  }
  /**
    [Glimmer](https://github.com/tildeio/glimmer) is a templating engine used by Ember.js that is compatible with a subset of the [Handlebars](http://handlebarsjs.com/) syntax.
  
    ### Showing a property
  
    Templates manage the flow of an application's UI, and display state (through
    the DOM) to a user. For example, given a component with the property "name",
    that component's template can use the name in several ways:
  
    ```app/components/person-profile.js
    import Component from '@ember/component';
  
    export default Component.extend({
      name: 'Jill'
    });
    ```
  
    ```app/components/person-profile.hbs
    {{this.name}}
    <div>{{this.name}}</div>
    <span data-name={{this.name}}></span>
    ```
  
    Any time the "name" property on the component changes, the DOM will be
    updated.
  
    Properties can be chained as well:
  
    ```handlebars
    {{@aUserModel.name}}
    <div>{{@listOfUsers.firstObject.name}}</div>
    ```
  
    ### Using Ember helpers
  
    When content is passed in mustaches `{{}}`, Ember will first try to find a helper
    or component with that name. For example, the `if` helper:
  
    ```app/components/person-profile.hbs
    {{if this.name "I have a name" "I have no name"}}
    <span data-has-name={{if this.name true}}></span>
    ```
  
    The returned value is placed where the `{{}}` is called. The above style is
    called "inline". A second style of helper usage is called "block". For example:
  
    ```handlebars
    {{#if this.name}}
      I have a name
    {{else}}
      I have no name
    {{/if}}
    ```
  
    The block form of helpers allows you to control how the UI is created based
    on the values of properties.
    A third form of helper is called "nested". For example here the concat
    helper will add " Doe" to a displayed name if the person has no last name:
  
    ```handlebars
    <span data-name={{concat this.firstName (
      if this.lastName (concat " " this.lastName) "Doe"
    )}}></span>
    ```
  
    Ember's built-in helpers are described under the [Ember.Templates.helpers](/ember/release/classes/Ember.Templates.helpers)
    namespace. Documentation on creating custom helpers can be found under
    [helper](/ember/release/functions/@ember%2Fcomponent%2Fhelper/helper) (or
    under [Helper](/ember/release/classes/Helper) if a helper requires access to
    dependency injection).
  
    ### Invoking a Component
  
    Ember components represent state to the UI of an application. Further
    reading on components can be found under [Component](/ember/release/classes/Component).
  
    @module @ember/component
    @main @ember/component
    @public
   */

});